@use "styles/utilities";

.wrapper {
	display: flex;
	justify-content: center;
	background-color: utilities.$global-card-background-primary;
	min-height: 50px;
	position: sticky;
	top: 0;
	z-index: 10;
}

.toolbar {
	height: fit-content;
	background-color: utilities.$global-card-background-primary;
	padding: 0 utilities.$spacing3x;
	white-space: nowrap;
	overflow-x: auto;
	flex-wrap: nowrap;
	z-index: 10;
	justify-content: space-between;
	max-width: 1600px;
	@include utilities.lg-down {
		width: 100vw;
	}
}

.toolbarShadow {
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
	transition: box-shadow 0.2s ease-in-out;
}

.step {
	display: flex;
	align-items: center;
	min-height: 50px;
	height: 100%;
	border-bottom: 3px solid transparent; // Do not move object when hovering
	padding-right: utilities.$spacing1x;
	padding-left: utilities.$spacing1x;
	&:hover {
		color: #027ab6;
		cursor: pointer;
	}
}

.selected {
	color: #027ab6;
	border-bottom: 3px solid #027ab6;
	cursor: pointer;
	font-weight: 500;
}
