@use "styles/utilities";

.priceContainer {
	width: 130px;
	max-width: 130px;
	min-width: 130px;
	flex-wrap: nowrap;
	gap: utilities.$spacing1x;
	justify-content: flex-end;
	@include utilities.sm-down {
		justify-content: flex-start;
	}
	h6:nth-child(2) {
		font-size: 14px;
		color: utilities.$grey700;
	}
}

.totalCostContainer {
	align-items: center;
	justify-content: space-between;
	height: 64px;
	border-top: 1px solid utilities.$grey300;
	border-bottom: 1px solid utilities.$grey300;
}
