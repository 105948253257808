@use "styles/utilities";

.dialogTitle {
	margin-top: utilities.$spacing1x;
	position: relative;
}

.closeButton {
	position: absolute;
	right: 5px;
	top: 0px;
	color: utilities.$text-accent;
}

.dialogPaper {
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
	padding-bottom: 24px;
}

.dialogContent {
	padding-right: utilities.$spacing3x;
	padding-left: utilities.$spacing3x;
	scrollbar-width: thin;
}

.incident {
	background-color: #ffebba;
	padding: utilities.$spacing3x;
}

.incidentInfoLine {
	padding-top: utilities.$spacing2x;
}

.noStartupCost {
	text-decoration: line-through;
	margin-right: utilities.$spacing3x;
}

.ticketID {
	padding-left: 2px;
}

.consultancyRateInfo {
	padding: utilities.$spacing3x;
}

.alertInfo {
	background-color: #f9f9f9;
	padding: utilities.$spacing2x;
}

.icon {
	margin-top: 1px;
	color: utilities.$yellowPrimary;
}

.alignText {
	margin-top: 7px;
}

.dialogActions {
	justify-content: flex-start;
	// space between buttons
	> * {
		margin-right: 24px;
	}
	padding: utilities.$spacing3x utilities.$spacing3x 0;
}

.dialogActionButton {
	width: 160px;
	border-radius: 3px;
}
