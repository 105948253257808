@use "styles/utilities";

.legendContainer {
	padding: utilities.$spacing2x;
}

.alignWithMinWidth {
	& > div {
		min-width: 120px;
		@include utilities.sm-down {
			min-width: 0px;
		}
	}
}
