@use "styles/utilities";

.dialogActionBtn {
	color: white;
	background-color: rgb(35, 72, 99);
	font-size: 14px;
	width: 110px;
}

.dialogActionBtn:hover {
	background-color: rgb(180, 199, 231);
	box-shadow: none;
}

.noDialogContentSpacing {
	margin-top: 0px;
	padding-left: 0px;
	padding-right: 0px;
	padding-bottom: 0px;
}

.dialogPaper {
	width: 900px;
	padding: utilities.$spacing4x;
	background-color: utilities.$global-card-background-secondary;
	@extend .noDialogContentSpacing;
}

.titleContainer {
	display: flex;
	justify-content: center;
	background-color: utilities.$global-card-background-secondary;
}

.actionsContainer {
	margin-left: 14px;
	display: flex;
	justify-content: left;
	background-color: utilities.$global-card-background-secondary;
}

.padding {
	padding: 0 0 10px 0;
}
