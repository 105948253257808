@use "styles/utilities";

.red {
	color: utilities.$error;
}

.noDevicesCell {
	width: 100%;
	border: 0;
}

.activityInfo {
	cursor: default;
	display: flex;
	justify-content: center;
}

.activityText {
	font-size: inherit;
}

.tableHead {
	@include utilities.responsiveTableHeader();
}

.tableContainer {
	width: 100%;
	background-color: utilities.$global-card-background-primary;
	@include utilities.responsiveTableContainer();
}
