@use "styles/utilities";

.container {
	height: 100%;
	border: 0 1px 1px 1px solid utilities.$border-color;
	border-radius: 4px;
}

.titleContainer {
	border-radius: 4px 4px 0 0;
	background-color: utilities.$brownTint3;
	padding: utilities.$spacing2x;
}

.fontWeight500 {
	font-weight: 500;
}

.link {
	display: flex;
	align-items: flex-end;
	margin: utilities.$spacing1x 0;
}
