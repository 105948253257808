@use "styles/utilities";

.userInfoContainer {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	row-gap: 16px;
}

.userInfoElement {
	display: flex;
	direction: column;
	height: 60px;
}

.topSection {
	display: flex;
	flex-direction: column;
	gap: utilities.$spacing2x;
	margin: utilities.$spacing4x 0 utilities.$spacing2x 0;
}

.editIcon {
	font-size: 14px;
}

.input {
	padding-right: utilities.$spacing3x;
	width: 100%;
	height: 48px;
}

.autcompleteInput {
	padding-right: utilities.$spacing3x;
	margin-bottom: -40px;
}

.userNameContainer {
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding: utilities.$spacing1x 0 utilities.$spacing1x 0;
}

.errorText {
	:global(.MuiFormHelperText-root) {
		margin-left: 0px;
		width: max-content;
		height: 100%;
	}
}

.emailContainer {
	display: flex;
	flex-direction: row;
}

.errorMessageText {
	margin-top: utilities.$spacing1x;
	color: utilities.$error;
}
