@use "styles/utilities";

.container {
	margin-bottom: 150px;
	max-width: utilities.$contentMaxWidth;
}

.topSectionContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: utilities.$spacing4x;
	gap: utilities.$spacing3x;
}

.topSearchBar {
	justify-content: space-between;
	flex-wrap: nowrap;
	z-index: 100;
	min-height: fit-content;
	@include utilities.md-down {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		background-color: utilities.$table-head-primary;
	}
	position: sticky;
	top: 0;
	background-color: #fff;
	padding: utilities.$spacing3x;
}

.searchFieldSection {
	width: 400px;
	@include utilities.md-down {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.filters {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: utilities.$spacing2x;
	@include utilities.md-down {
		align-items: center;
		flex-direction: column;
		width: 100%;
	}
}

.filtersAndButtonContainer {
	display: flex;
	flex-grow: 1;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	@include utilities.md-down {
		flex-direction: column;
	}
}

.inputRoot {
	padding: 2px 4px;
	display: flex;
	align-items: center;
	border: 1px solid utilities.$border-color;
	border-radius: 4px;
	background-color: utilities.$global-card-background-primary;
	width: 400px;
	@include utilities.md-down {
		width: 262px;
		margin-right: utilities.$spacing2x;
	}
}

.input {
	width: 100%;
}

.downloadIcon {
	color: utilities.$link;
	font-size: 18px;
	margin-top: 4px;
}

.exportButton {
	margin-left: auto;
	@include utilities.md-down {
		display: none;
	}
}

.exportButtonSmallScreen {
	display: none;
	white-space: nowrap;
	@include utilities.md-down {
		display: flex;
	}
}
