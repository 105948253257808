@use "styles/utilities";

.sectionTitle {
	color: utilities.$text-primary;
	background-color: utilities.$global-card-background-secondary;
	padding: utilities.$spacing2x;
	height: 62px;
	display: flex;
	align-items: center;
}

.tableHead {
	background-color: utilities.$table-header-background;
	@include utilities.responsiveTableHeader();
}

.skeleton {
	height: 60px;
}

.skeletonContainer {
	box-shadow: 0 2px 10px -4px rgb(0 0 0 / 14%);
}

.tableContainer {
	position: relative;
	background-color: utilities.$global-card-background-primary;
	@include utilities.responsiveTableContainer();
}

.rowStyle {
	height: 7vh;
}

.costTypeCell {
	text-align: end;
}

.paddedCell {
	padding-left: 2vw;
}

.verticalAlignTop {
	vertical-align: top;
}

.multipleSubsStyle {
	display: flex;
	flex-direction: column;
	gap: utilities.$spacing3x;
}

.multipleSubsTermDurationStyle {
	@extend .multipleSubsStyle;
	gap: utilities.$spacing2x;
}

.inlineTextRight {
	margin-left: utilities.$spacing1x;
	margin-right: utilities.$spacing3x;
}

.priceAndBillingCycleContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-end;
	height: 24px;
}

.billingCycleLabel {
	padding: 4px utilities.$spacing1x;
	border-radius: 4px;
	background-color: utilities.$blueDark;
	color: white;
	font-weight: 500;
	margin-right: utilities.$spacing3x;
	white-space: nowrap;
}

.discountAmount {
	color: utilities.$text-accent;
	text-decoration: line-through;
	display: block;
}

.totalQuantity {
	text-align: end;
}

.discountLabel {
	color: rgba(69, 186, 120, 1);
	width: 80px;
	border-radius: 4px;
	text-align: end;
	font-weight: 500;
}

.discountLabelContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-end;
}

.hide {
	display: none;
}
