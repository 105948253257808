@use "styles/utilities";

.endIcon {
	font-size: 15px;
	margin-left: 25px;
}

.blurred {
	overflow: hidden;
	pointer-events: none;
	filter: blur(1px) grayscale(100%);
	background: rgba(122, 122, 122, 0.2);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: absolute;
	top: 80px;
	right: 0;
	left: 0;
	bottom: 0;
}
