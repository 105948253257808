@use "styles/utilities";

.textField {
	div {
		background: utilities.$global-card-background-primary;
	}
	font-size: 15px;
	min-height: 50px;
}

.dropDownContainer {
	cursor: pointer;
	&:hover {
		background: utilities.$global-card-background-secondary;
	}
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 utilities.$spacing3x 0 0;
}

.dropDownItem {
	font-size: 12px;
}

.dropDownItemEnd {
	font-size: 12px;
	color: utilities.$text-accent;
	min-width: 50px;
}

.singleDropDown {
	cursor: pointer;
	font-size: 12px;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-right: 8px;
	padding-left: 16px;
	display: block;

	&:hover {
		background: utilities.$global-card-background-secondary;
	}
}

.selectedRow {
	background: utilities.$global-card-background-secondary;
}

.unchecked {
	color: #909090;
	margin-right: 8px;
}

.checked {
	margin-right: 8px;
	color: utilities.$yellowTint1;
}

.userNameContainer {
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding: utilities.$spacing1x 0 utilities.$spacing1x 0;
	align-items: flex-start;
	flex-grow: 1;
}

.tagContainer {
	display: flex;
	flex-direction: row;
	gap: 6px;
	margin-left: 8px;
	svg {
		color: utilities.$text-accent;
		font-size: 23px;
	}
}

.viewMode {
	pointer-events: none;
	opacity: 0.5;
}

.dropdownStyle {
	min-width: 350px;
}
