@use "styles/utilities";

.tableSectionContainer {
	margin-top: utilities.$spacing2x;
}

.noLicensesAvailableContainer {
	display: flex;
	flex-direction: column;
	gap: utilities.$spacing2x;
	padding: utilities.$spacing3x;
}
