@use "styles/utilities";

.textSectionContainer {
	display: flex;
	flex-direction: column;
	gap: utilities.$spacing2x;
}

.textContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.standardWidthTextContainer {
	@extend .textContainer;
	padding-bottom: 4px;
	width: 70%;
}

.assignedLicensesContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 70%;
	color: utilities.$link;
	cursor: pointer;
	&:hover {
		p {
			text-decoration: underline;
		}
	}
}

.viewUsersLink {
	color: utilities.$link;
	width: 25%;
	vertical-align: bottom;
	margin-top: 5px;
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}

.titleContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-bottom: 4px;
	border-bottom: 1px solid utilities.$border-color;
	width: 70%;
}
.perYearText {
	padding-top: 3px;
	margin-left: 4px;
	color: utilities.$text-accent;
}

.card {
	width: 100%;
	padding: utilities.$spacing3x;
	gap: utilities.$spacing2x;
	display: flex;
	flex-direction: column;
	align-items: space-between;
	justify-content: space-between;
	height: 100%;
}

.buttonsContainer {
	display: flex;
	flex-direction: row;
	gap: utilities.$spacing2x;
}

.scheduledActionsContainer {
	position: relative;
}

.hidden {
	visibility: hidden;
}

.iconAndAssignedLicenses {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: utilities.$spacing2x;
	width: 30%;
}
