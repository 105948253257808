@use "styles/utilities";

.scheduledActionsPillContainer {
	background-color: utilities.$greenTint1;
	display: flex;
	align-items: center;
	&:hover {
		cursor: pointer;
	}
}

.large {
	position: absolute;
	@extend .scheduledActionsPillContainer;
	top: utilities.$spacing3x;
	right: utilities.$spacing3x;
	padding: 2px;
	padding-left: 8px;
	padding-right: 10px;
	border-radius: 16px;
	gap: utilities.$spacing1x;
}

.small {
	@extend .scheduledActionsPillContainer;
	padding: 2px;
	border-radius: 8px;
	padding: 0px 4px;
	max-width: fit-content;
	margin: 0px utilities.$spacing2x;
	gap: utilities.$spacing1x;
}

.hidden {
	display: none;
}
