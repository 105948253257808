@use "styles/utilities";

.iframe {
	border: 0;
	height: 95vh;
	width: 100%;
	margin-top: 45px;
	@include utilities.sm-up {
		width: utilities.$drawerWidth;
	}
}

.sectionTitle {
	height: 62px;
	margin-bottom: utilities.$spacing3x;
	display: flex;
	align-items: center;
	justify-content: center;
	color: utilities.$text-primary;
	background-color: utilities.$global-background-secondary;
}

.iframeModeSectionTitle {
	padding-left: 15px;
	font-size: 22px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.toolbar {
	min-height: 56px;
	@include utilities.sm-up {
		min-height: 64px;
	}
}

.content {
	flex-grow: 1;
	padding: 0 utilities.$spacing3x;
	@include utilities.sm-down {
		padding: 0;
	}
}

.main {
	background-color: utilities.$global-background-primary;
}

.contentContainer {
	flex-grow: 1;
	@include utilities.lg-down {
		padding-bottom: 200px;
	}
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.contentIFrameMode {
	flex-grow: 1;
	padding: 0;
}

.notAllowed {
	margin-top: 100px;
	width: 100%;
	height: 360px;
	overflow: hidden;
}

.globalLoading {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1000;
	@include utilities.lg-up {
		margin-left: 125px;
	}
}

.standardTitleTextContainer {
	display: flex;
	flex-grow: 1;
	margin-left: utilities.$spacing3x;
	margin-right: utilities.$spacing3x;
	justify-content: space-between;
}

.centralizedTitleTextContainer {
	@extend .standardTitleTextContainer;
	@include utilities.standardMaxWidth();
}

.centralizeHeader {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.centerContent {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
