@use "styles/utilities";

.infoIcon {
	color: utilities.$info;
	cursor: pointer;
}

.optionalDetails {
	height: 100%;
}

.formTextInput {
	font-size: 16px;
	font-weight: 400;
}

.errorText {
	:global(.MuiFormHelperText-root) {
		margin-left: 0px;
		height: 100%;
	}
}

.shippingDetails {
	height: fit-content;
}

.hidden {
	display: none;
}

.disabled {
	pointer-events: none;
	background-color: utilities.$greyTint2;
	opacity: 0.7;
}
