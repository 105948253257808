$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;

@mixin xs-up {
	@media (min-width: #{$xs}) {
		@content;
	}
}

@mixin xs-down {
	@media (max-width: #{$xs}) {
		@content;
	}
}

@mixin sm-up {
	@media (min-width: #{$sm}) {
		@content;
	}
}

@mixin sm-down {
	@media (max-width: #{$sm}) {
		@content;
	}
}

@mixin md-up {
	@media (min-width: #{$md}) {
		@content;
	}
}

@mixin md-down {
	@media (max-width: #{$md}) {
		@content;
	}
}

@mixin lg-up {
	@media (min-width: #{$lg}) {
		@content;
	}
}

@mixin lg-down {
	@media (max-width: #{$lg}) {
		@content;
	}
}

@mixin xl-up {
	@media (min-width: #{$xl}) {
		@content;
	}
}

@mixin xl-down {
	@media (max-width: #{$xl}) {
		@content;
	}
}

// Allows to export variables to be used in TS/JS files
:export {
	xs: $xs;
	sm: $sm;
	md: $md;
	lg: $lg;
	xl: $xl;
}
