@use "styles/utilities";

.wrapper {
	@include utilities.centralizedWithMaxWidth();
}

.tableWrapper {
	width: 50%;
	max-width: 50%;
	@include utilities.lg-down {
		width: 100%;
		max-width: 100%;
	}
}
