@use "styles/utilities";

.wrapper {
	margin-top: utilities.$spacing4x;
	margin-bottom: 150px;
	@include utilities.centralizedWithMaxWidth(1100px);
}

.dividerMargin {
	margin-top: 6px;
}

.container {
	flex-wrap: nowrap;
	@include utilities.md-down {
		flex-wrap: wrap;
	}
}

.skeleton {
	margin-bottom: utilities.$spacing3x;
	height: 44px;
}

.tabsContainer {
	margin-top: utilities.$spacing3x;
	margin-bottom: utilities.$spacing2x;
}

.tab {
	display: flex;
	min-width: fit-content;
	flex-grow: 1;
	color: utilities.$grey500 !important;
	width: 300px;
}

.selectedTab {
	color: utilities.$blueDark !important;
}
