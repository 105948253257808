@use "styles/utilities";

.drawer {
	@include utilities.md-down {
		width: 100vw;
	}
	// Medium screens: Overlap with faded background
	@include utilities.md-up {
		width: 708px;
		.MuiBackdrop-root {
			background-color: rgba(0, 0, 0, 0.5);
		}
	}

	@include utilities.lg-up {
		width: 708px;
	}
}

.drawerContent {
	padding: utilities.$spacing3x;
	gap: utilities.$spacing3x;
	width: 75%;
}

.drawerTitle {
	margin-bottom: utilities.$spacing1x;
	background-color: utilities.$yellowTint4;
	padding: utilities.$spacing2x utilities.$spacing3x;
}

.closeButton {
	position: absolute;
	top: utilities.$spacing1x;
	right: utilities.$spacing3x;
}

.roleDescription {
	color: utilities.$text-accent;
	display: inline;
}

.accessSection {
	margin-bottom: utilities.$spacing2x;
	svg {
		margin-right: utilities.$spacing1x;
		color: utilities.$grey200;
	}
}

.labelContainer {
	min-width: 150px;
}

.descriptionContainer {
	width: 100%;
	margin-left: utilities.$spacing4x;
}
