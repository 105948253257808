@use "styles/utilities";

.productContainer {
	width: 100%;
	background-color: white;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: utilities.$spacing3x;
	flex-wrap: nowrap;
	padding: utilities.$spacing3x;
	margin-bottom: 4px;
	border-radius: 2px;
}

.listItem {
	list-style-type: disc;
}

.list {
	padding-left: 17.5px;
}

.products {
	height: 100%;
	max-width: 2150px;
}

.productImage {
	width: 137px;
}

.productImageContainer {
	width: 250px;
	height: 160px;
}

.productDescription {
	color: #555;
	overflow-wrap: break-word;
	margin-bottom: utilities.$spacing1x;
	@include utilities.sm-down {
		margin-bottom: 0;
	}
}

.descriptionContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: utilities.$spacing1x;
	padding-right: utilities.$spacing4x;
}

.priceContainer {
	width: fit-content;
	gap: utilities.$spacing2x;
	@include utilities.sm-down {
		flex-direction: row;
		width: 100%;
		min-width: 100%;
		justify-content: space-between;
		padding-top: utilities.$spacing1x;
		gap: utilities.$spacing1x;
		padding-right: utilities.$spacing4x;
	}
}

.imageAndDescriptionContainer {
	flex-wrap: nowrap;
	gap: utilities.$spacing3x;
	@include utilities.sm-down {
		flex-direction: column;
		justify-content: space-between;
		gap: 0;
		& > * {
			word-break: break-word;
		}
	}
}

.availabilityContainer {
	flex-wrap: nowrap;
}

.skeleton {
	width: 100%;
	max-width: 100%;
	min-width: 100%;
	height: 180px;
	margin-left: utilities.$spacing2x;
}

.externalLinkContainer {
	display: flex;
	margin-top: utilities.$spacing1x;
	@include utilities.sm-down {
		justify-content: flex-start;
	}
	p,
	svg {
		color: utilities.$text-accent;
	}
}
