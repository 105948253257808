@use "styles/utilities";

.dropdown {
	position: absolute;
	top: utilities.$spacing3x;
	right: -100px;
	z-index: 2000;
	width: 160px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	padding: utilities.$spacing1x utilities.$spacing3x;
	border-radius: 4px;
	cursor: pointer;
	text-align: center;

	background-color: rgba(utilities.$grey500, 0.6);
	border: 2px solid black;

	transition: right 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;

	p {
		font-weight: 600;
	}

	&:hover {
		background-color: utilities.$grey500;
		border: 2px solid utilities.$grey500;
		right: 2px;
		p {
			color: #fff;
		}
	}
}

.switchingContainer {
	display: flex;
	flex-direction: column;
	gap: utilities.$spacing2x;
}

.dialogContent {
	min-height: 800px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.entitledTenantInfoText {
	color: #fff;
}
