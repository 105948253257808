@use "styles/utilities";

.container {
	display: flex;
	flex-direction: column;
	gap: utilities.$spacing2x;
	min-height: 1000px;
}

.productToSwapContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: utilities.$spacing2x;
	gap: utilities.$spacing2x;
	border: 1px solid utilities.$border-color;
	border-radius: 4px;
}

.similarProductsHeader {
	margin-top: utilities.$spacing3x;
}

.newProductLessExpensive {
	color: utilities.$success;
}

.newProductMoreExpensive {
	color: utilities.$error;
}

.productsList {
	display: flex;
	flex-direction: column;
}

.itemSwapIcon {
	padding-top: 3px;
	color: utilities.$primary;
}

.listItem {
	border-bottom: 1px solid utilities.$border-color;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: utilities.$spacing1x;
	padding: utilities.$spacing4x;
}

.imageContainer {
	width: 12%;
	@include utilities.md-down {
		width: 30%;
	}
}

.infoContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 48%;
	@include utilities.md-down {
		align-items: center;
		text-align: center;
		width: 60%;
	}
}

.priceContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 20%;
	@include utilities.md-down {
		align-items: flex-start;
		width: 50%;
	}
}

.swapButtonContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	@include utilities.md-down {
		align-items: flex-start;
		width: 45%;
	}
}

.noProductsContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: utilities.$spacing2x;
	padding: utilities.$spacing4x;
	border-radius: 4px;
}
