@use "styles/utilities";

.dialogContent {
	background-color: utilities.$global-card-background-primary;
	width: 100%;
	height: 100%;
	padding: utilities.$spacing3x;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.input {
	margin-bottom: utilities.$spacing3x;
	width: 300px;
}

.inputLabel {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: utilities.$spacing1x;
}
