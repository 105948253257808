@use "styles/utilities";

.container {
	cursor: pointer;

	&:hover {
		border-radius: 4px;
		background-color: utilities.$global-card-background-secondary;
		transition-duration: 300ms;
	}
}

.icon {
	width: utilities.$spacing1x;
	height: utilities.$spacing1x;
	display: inline-block;
	border-radius: 50%;
	margin-right: utilities.$spacing1x;
}

.chartAndLegendContainer {
	padding: utilities.$spacing3x;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.legendContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
}

.valueText {
	font-weight: 600;
	margin-right: utilities.$spacing1x;
}

.percentageText {
	font-weight: 600;
	color: rgba(utilities.$text-primary, 0.87);
}

.inlineAlign {
	display: flex;
	justify-content: space-between;
}

.valueColorBlock {
	width: utilities.$spacing2x;
	height: 15px;
	display: inline-block;
	margin-right: utilities.$spacing1x;
}

.strikethrough {
	text-decoration: line-through;
}

.underline {
	border-bottom: solid 1px utilities.$greyTint1;
}