@use "styles/utilities";

.noneSelected {
	display: flex;
	flex-grow: 1;
	flex-direction: row;
	justify-content: flex-start;
	margin-top: utilities.$spacing1x;
	p {
		opacity: 0.5;
		font-size: 14px;
	}
}

.usersContainer {
	min-height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: utilities.$spacing2x;
	margin: utilities.$spacing1x 0;
	margin-bottom: utilities.$spacing3x;
}

.licensesContainer {
	min-height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: utilities.$spacing2x;
	margin: utilities.$spacing1x 0;
}

.noSelectedUser {
	opacity: 0.5;
	p {
		font-size: 14px;
	}
	align-items: center;
	gap: utilities.$spacing1x;
}

.licenseIcon {
	font-size: 26px;
	color: utilities.$greyTint3;
	margin-right: utilities.$spacing1x;
	margin-top: 3px;
}

.licenseInfoContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.groupLabel {
	background-color: utilities.$blueLicenseGroup;
	padding: 6px 12px;
	border-radius: 4px;
	margin-left: utilities.$spacing1x;
}

.assignedLicenseRow {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: utilities.$spacing1x;
}

.userIcon {
	font-size: 26px;
	color: utilities.$greyTint1;
	margin-right: utilities.$spacing1x;
}

.smallUserIcon {
	font-size: 18px;
	color: utilities.$greyTint1;
	margin-right: utilities.$spacing1x;
	margin-top: 2px;
}

.hovered {
	transition: background-color 1s ease-in-out;
	svg {
		color: utilities.$blueTint1;
	}
}

.alreadyAssignedContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.assignedLicenseRow:hover .alreadyAssignedContainer {
	svg {
		color: utilities.$blueDark;
	}
}

.userNameContainer {
	display: flex;
	flex-direction: column;
	gap: 4px;
}
