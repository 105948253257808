@use "styles/utilities";

.tooltip {
	border-radius: 4px;
	margin-top: utilities.$spacing1x;
	padding: utilities.$spacing1x utilities.$spacing2x;
	min-width: fit-content;
	& > ul {
		list-style: none;
		padding: 2px 4px;
		margin: 0;
	}
	background-color: rgba(28, 28, 28, 0.9);
}

.title {
	font-size: 14px;
	font-weight: 600;
	color: utilities.$text-secondary;
}

.description {
	font-size: 13px;
	color: utilities.$text-secondary;
}
