@use "styles/utilities";

.headerContainer {
	@include utilities.lg-down {
		position: relative;
	}
}

.closeButton {
	position: absolute;
	right: 0px;
	top: 0px;
	color: utilities.$text-accent;
	@include utilities.lg-up {
		display: none;
	}
}
