@use "styles/utilities";

.workspaceTitle {
	display: flex;
	justify-content: center;
	align-items: center;
}

.titleContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.container {
	@include utilities.centralizedWithMaxWidth();
}

.captionWrapper {
	margin-top: -7px;
}

.checkbox {
	color: utilities.$yellowTint1;
}

.checkboxChecked {
	color: #909090;
}

.hardwareIconWrapper {
	border-radius: 4px;
	padding: 2px 4px 2px 4px;
	max-width: max-content;
	position: absolute;
	top: utilities.$spacing3x;
	right: utilities.$spacing3x;
}

.hardwareIcon {
	transform: scale(0.2);
}

.cardAndInputContainer {
	max-width: 1000px;
	width: 100%;

	@include utilities.md-down {
		max-width: 100%;
	}
}

.tableWrapper {
	margin-bottom: utilities.$contentViewMarginBottom;
	margin-top: utilities.$spacing3x;
	margin-left: utilities.$spacing3x;
	@include utilities.lg-down {
		@include utilities.responsiveTableContainer();
	}
}
