@use "styles/utilities";

.option {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	cursor: pointer;
	&:hover {
		background: utilities.$global-card-background-secondary;
	}
	padding: 4px utilities.$spacing2x;
	align-items: center;
}

.addIcon {
	color: utilities.$greyTint3;
	margin-top: 5px;
	margin-left: -16px;
	margin-right: utilities.$spacing2x;
}

.disabledOption {
	opacity: 0.5;
	cursor: default;
	&:hover {
		background: none;
	}
}

.dropdownStyle {
	min-width: 350px;
}

.disabled {
	opacity: 0.5;
	filter: grayscale(1);
}

.addLocationButton {
	display: flex;
	padding-left: utilities.$spacing3x;
	white-space: nowrap;
	margin-top: utilities.$spacing2x;
	color: utilities.$link;
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}
