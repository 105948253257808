@use "styles/utilities";

.licenseNotInUseDescription {
	display: inline;
	padding-left: 6px;
	color: utilities.$text-accent;
	font-weight: 400;
}

.licenseNotInUseContainer {
	border-bottom: 1px solid utilities.$border-color;
	padding-right: utilities.$spacing3x;
	width: fit-content;
}
