@use "styles/utilities";

.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: utilities.$spacing3x;
	background-color: utilities.$global-card-background-primary;
	border-radius: 4px;
}

.documentsContainer {
	display: flex;
	width: 50%;
}

.documentsList {
	display: flex;
	flex-direction: column;
	gap: utilities.$spacing1x;
	margin-top: utilities.$spacing2x;
}

.divider {
	min-width: 300px;
	margin-top: 2px;
}

.inputRoot {
	padding: 2px 4px;
	display: flex;
	align-items: center;
	border: 1px solid utilities.$border-color;
	border-radius: 4px;
	background-color: utilities.$global-card-background-primary;
	width: 400px;
	@include utilities.md-down {
		width: 262px;
		margin-right: utilities.$spacing2x;
	}
}

.searchAndFilterContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: utilities.$spacing2x;
	margin: utilities.$spacing3x 0;
}

.tableContainer {
	border: transparent;
}

.tableRow {
	height: 75px;
}

.defaultLabel {
	padding: 6px 11px 6px 11px;
	max-width: fit-content;
	border-radius: 8px;
	font-weight: 500;
	background-color: rgba(174, 174, 174, 0.16);
	color: utilities.$text-primary;
}
