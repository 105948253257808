@use "styles/utilities";

.recentlyModifiedHeaderContainer {
	display: flex;
	flex-direction: row;
	background-color: utilities.$blueTint6;
	align-items: center;
	width: 100%;
	margin-top: utilities.$spacing1x;
	p {
		color: utilities.$blueDark;
	}
}
