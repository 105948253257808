@use "styles/utilities";

.wrapper {
	justify-content: center;
}

.containerBase {
	@include utilities.md-down {
		width: 100% !important;
		padding-left: 0px;
	}
}

.onboarding {
	padding-left: 8%;
	@extend .containerBase;
	margin-top: 110px;
	width: 50%;
}
