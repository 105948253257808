@use "styles/utilities";

.menuWrapper {
	display: none;
	@include utilities.lg-down {
		display: block;
		position: fixed;
		bottom: 70px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1201; // z-index of the drawer list is 1200, so we need to be above that
	}
	@include utilities.sm-down {
		bottom: 30px;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.menuButtonsWrapper {
	position: relative;
	opacity: 0;
	animation: fadeIn 0.3s ease-in forwards;
	background-color: rgba(89, 89, 89, 0.78);
	padding: utilities.$spacing3x utilities.$spacing4x;
	border-radius: 4px;
	width: 360px;
	justify-content: space-between;
	display: flex;
	pointer-events: auto;

	@include utilities.sm-down {
		padding: utilities.$spacing2x utilities.$spacing3x;
		width: 250px;
	}
}

.menuButtonBroilerplate {
	height: 60px;
	&:hover {
		background-color: #ffdd7c !important;
	}
}

.homeButton {
	@extend .menuButtonBroilerplate;
	width: 70px;
	padding: 0 !important;
	min-width: 0 !important;
}

.menuButton {
	@extend .menuButtonBroilerplate;
	width: 200px;
	padding: 0 !important;
	@include utilities.sm-down {
		width: 110px;
	}
}
