@use "styles/utilities";

.baseDialogPaper {
	:global(.MuiPaper-root.MuiDialog-paper) {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
		max-width: 1200px;
	}
}

.fullscreenDialogForSmallerScreens {
	:global(.MuiPaper-root.MuiDialog-paper) {
		@include utilities.sm-down {
			min-width: 100vw !important;
			max-width: 100vw !important;
			height: 100vh !important;
			min-height: 100vh !important;
			margin: 0;
		}
	}
}

@mixin flexDialogContentForSmallScreens() {
	@include utilities.sm-down {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
	}
}

.expandedHeight {
	min-height: 70vh;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	@include utilities.sm-down {
		height: 100vh !important;
		min-height: 100vh !important;
	}
}

.smallDialogPaper {
	min-width: 250px;
	max-width: 450px;
}

.mediumDialogPaper {
	@include flexDialogContentForSmallScreens();
	min-width: 600px;
	max-width: 600px;
	@include utilities.sm-down {
		min-width: 0px;
	}
}

.largeDialogPaper {
	@include flexDialogContentForSmallScreens();
	min-width: 1200px;
	max-width: 1200px;
	@include utilities.xl-down {
		min-width: utilities.$lg;
	}
	@include utilities.lg-down {
		min-width: 0;
	}
}

.xtraLargeDialogPaper {
	@include flexDialogContentForSmallScreens();
	min-width: 1200px;
	max-width: 1200px;
}

.smallDialogContent {
	margin-top: 10px;
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom: 0px;
	font-family: utilities.$global-font-family;
	font-style: utilities.$small-font-style;
	font-weight: utilities.$small-font-weight;
	font-size: utilities.$small-font-size;
	line-height: utilities.$small-line-height;
}

@mixin spacingForSmallScreens() {
	@include utilities.sm-down {
		margin-top: utilities.$spacing1x;
		padding: utilities.$spacing1x;
	}
}

@mixin topBorderForSmallScreens() {
	@include utilities.md-down {
		box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.1);
	}
}

.smallDialogContentSpacing {
	margin-top: 10px;
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom: 10px;
	@include spacingForSmallScreens();
}

.mediumDialogContent {
	font-family: utilities.$global-font-family;
	font-style: utilities.$medium-font-style;
	font-weight: utilities.$medium-font-weight;
	font-size: utilities.$medium-font-size;
	line-height: utilities.$medium-line-height;
}

.mediumDialogContentSpacing {
	margin-top: 24px;
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom: 24px;
	@include spacingForSmallScreens();
}

.largeDialogContent {
	font-family: utilities.$global-font-family;
	font-style: utilities.$large-font-style;
	font-weight: utilities.$large-font-weight;
	font-size: utilities.$large-font-size;
	line-height: utilities.$large-line-height;
}

.largeDialogContentSpacing {
	margin-top: 24px;
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom: 24px;
	@include spacingForSmallScreens();
}

.noDialogContentSpacing {
	margin-top: 0px;
	padding-left: 0px;
	padding-right: 0px;
	padding-bottom: 0px;
}

.smallDialogActions {
	padding-top: 15px;
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom: 24px;
	@include spacingForSmallScreens();
	@include topBorderForSmallScreens();
}

.mediumDialogActions {
	padding-top: 32px;
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom: 38px;
	@include spacingForSmallScreens();
	@include topBorderForSmallScreens();
}

.largeDialogActions {
	padding-top: 32px;
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom: 38px;
	@include spacingForSmallScreens();
	@include topBorderForSmallScreens();
}

.dialogTitle {
	font-family: utilities.$global-font-family;
	font-style: utilities.$dialog-title-font-style;
	font-weight: utilities.$dialog-title-font-weight;
	font-size: utilities.$dialog-title-font-size;
	line-height: utilities.$dialog-title-line-height;
	padding: 24px 50px 24px 24px;
	margin-top: 0;
	background-color: utilities.$global-card-background-secondary;
	display: flex;
	align-items: center;
}

.closeButton {
	position: absolute;
	right: 5px;
	top: 5px;
	color: utilities.$text-accent;
}

.loadingDialog {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(5px);
}

.loadingDialogContent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 24px;
	width: 440px;
}

.loadingDialogTitle {
	display: flex;
	justify-content: center;
	font-weight: 500;
	margin-bottom: 48px;
}

.loadingStepContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: utilities.$spacing3x;
}

.loadingStep {
	display: flex;
	align-items: center;
	gap: utilities.$spacing1x;
}

.loadingDialogActions {
	display: flex;
	justify-content: center;
	padding: 24px;
}
