@use "styles/utilities";

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: utilities.$spacing2x;
}

.entitlement {
	display: flex;
	flex-direction: row;
	gap: utilities.$spacing1x;
	border: 1px solid utilities.$border-color;
	justify-content: space-between;
	gap: utilities.$spacing2x;
}

.entitlementPart {
	display: flex;
	flex-direction: column;
	max-width: 80%;
	padding: utilities.$spacing3x utilities.$spacing2x;
}

.row {
	display: flex;
	flex-direction: row;
	gap: utilities.$spacing3x;
}

.inputRoot {
	padding: 2px 4px;
	display: flex;
	align-items: center;
	border: 1px solid utilities.$border-color;
	border-radius: 4px;
	background-color: utilities.$global-card-background-primary;
}

.iconButton {
	padding: 10px;
}

.name {
	width: 250px;
}

.input {
	flex: 1;
	color: utilities.$text-accent;
	border: 1px solid utilities.$border-color-heavy;
	padding: utilities.$spacing1x utilities.$spacing2x;
	border-radius: 4px;
	margin-bottom: utilities.$spacing2x;
	max-height: 50px;
}

.header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: utilities.$spacing1x utilities.$spacing3x;
	gap: utilities.$spacing2x;
	border-bottom: 1px solid utilities.$border-color;
}

.copyableText:hover {
	background-color: rgba(0, 0, 0, 0.03);
	cursor: pointer;
}

.text {
	padding: 4px;
	border-radius: 4px;
}

.explanation {
	padding-left: 4px;
}

.right {
	width: 10%;
}

.hide {
	display: none;
}

.booleanContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 4px;
	justify-content: center;
}

.loadingContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
