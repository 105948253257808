$global-font-family: "Barlow";

$small-font-style: normal;
$small-font-weight: 400;
$small-font-size: 14px;
$small-line-height: 26.5px;

$medium-font-style: normal;
$medium-font-weight: 400;
$medium-font-size: 16px;
$medium-line-height: 26.5px;

$large-font-style: normal;
$large-font-weight: 400;
$large-font-size: 16px;
$large-line-height: 26.5px;

$dialog-title-font-style: normal;
$dialog-title-font-weight: 400;
$dialog-title-font-size: 23px;
$dialog-title-line-height: 32px;
