@use "styles/utilities";

.totalCard {
	@include utilities.xl-down {
		width: 100%;
		max-width: 800px;
		margin: auto;
	}
	@include utilities.xl-up {
		width: 30%;
		max-width: 600px;
	}
	border-radius: 8px;
	padding: utilities.$spacing3x;
	background-color: utilities.$global-card-background-primary;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	min-height: 165px;
}

.scheduledActionsCard {
	@include utilities.xl-down {
		width: 100%;
		max-width: 800px;
		margin: auto;
	}
	@include utilities.xl-up {
		margin-left: auto;
		width: 23.4%;
	}
	border-radius: 8px;
	padding: utilities.$spacing3x;
	background-color: utilities.$global-card-background-primary;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	&:hover {
		box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.12);
		cursor: pointer;
	}
	min-height: 165px;
}

.chartContainer {
	@include utilities.xl-down {
		width: 100%;
		max-width: 800px;
		margin: auto;
	}
	@include utilities.xl-up {
		width: 23.4%;
	}
	border-radius: 8px;
	padding: utilities.$spacing3x;
	background-color: utilities.$global-card-background-primary;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	min-height: 165px;
}

.recommendationsContainer {
	display: flex;
	flex-direction: row;
	gap: utilities.$spacing3x;
	justify-content: space-between;
	margin-bottom: utilities.$spacing4x;
	max-width: 1600px;
	@include utilities.xl-down {
		justify-content: center;
	}
}

.divider {
	border-top: 2px solid utilities.$border-color;
	border-radius: 4px;
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;
	@include utilities.xl-down {
		max-width: 800px;
	}
}
