@use "styles/utilities";

.cardsContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.insightCardContainer {
	@include utilities.md-up {
		width: 50%;
	}

	@include utilities.md-down {
		width: 100%;
	}
}

.totalSavingsContainer {
	@include utilities.md-up {
		width: 50%;
	}

	@include utilities.md-down {
		width: 100%;
	}
}
