@use "styles/utilities";

.upgradeContainer {
	display: flex;
	direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.upgradeButton {
	margin-top: utilities.$spacing2x;
	justify-content: center;
	display: flex;
}

// LicenseOptimizationDetails.module.scss
.generateReportContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: white;
	padding: 50px 200px;
	max-width: fit-content;
	border-radius: 8px;
	gap: utilities.$spacing2x;

	@include utilities.md-down {
		padding: 16px 24px;
	}
}

.externalLinkIcon {
	color: utilities.$text-primary;
}

.primaryButtonContent {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: utilities.$spacing1x;
}
