@use "styles/utilities";

.root {
	& > * {
		border-bottom: unset;
	}
}

.cell {
	padding-bottom: 0;
	padding-top: 0;
}

.infoIcon {
	color: utilities.$info;
	cursor: pointer;
}

.riskType {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 230px;
}
