@use "styles/utilities";

.header {
	background-color: utilities.$table-head-primary;
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 50px;
	border-radius: 4px 4px 0 0;
}

.row {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: utilities.$spacing2x 0;
}

.headerText {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: left;
	width: 33%;
	padding-left: utilities.$spacing3x;
}

.approverType {
	@extend .headerText;
	width: 20%;
}

.selectApprovers {
	@extend .headerText;
	width: 40%;
}

.approverFor {
	@extend .headerText;
	width: 30%;
}

.divider {
	background-color: utilities.$greyTint4;
	margin: 0 2px;
}
