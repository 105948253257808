@use "styles/utilities";

.termLabel {
	padding: 6px 11px 6px 11px;
	border-radius: 8px;
	font-weight: 500;
	white-space: nowrap;
	width: 70px;
	text-align: center;
}

.annualTermDurationLabel {
	@extend .termLabel;
	background-color: rgba(2, 122, 182, 0.16);
	color: #027ab6;
}

.monthlyTermDurationLabel {
	@extend .termLabel;
	background-color: rgba(254, 245, 230, 1);
	color: #faa61a;
}

.unknownTermLabel {
	@extend .termLabel;
	background-color: rgba(174, 174, 174, 0.16);
	color: utilities.$text-primary;
}
