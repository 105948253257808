@use "styles/utilities";

.drawerWrapper {
	z-index: utilities.$rightDrawerZIndex;
	position: fixed;
}

.drawer {
	@include utilities.md-down {
		width: 100vw;
	}
	@include utilities.md-up {
		min-width: 900px;
		max-width: 1000px;
		animation: slideInRight 0.5s forwards;
	}
	animation: slideInRight 0.2s forwards;
	@keyframes slideInRight {
		0% {
			transform: translateX(100%);
		}
		100% {
			transform: translateX(0);
		}
	}
}

.invisible {
	opacity: 0 !important;
}

.drawerHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: utilities.$spacing3x;
	height: 10vh;
}

.drawerContent {
	background-color: "white";
	display: flex;
	flex-direction: column;
	align-content: flex-start;
	height: 80vh;
	overflow-y: auto;
}

.drawerStepper {
	position: absolute;
	bottom: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 10vh;
}
