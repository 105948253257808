@use "styles/utilities";

.portalRoleContainer {
	width: 70%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 130px;
}

.userNameContainer {
	display: flex;
	flex-direction: column;
	gap: 4px;
	flex-grow: 1;
}

.tagContainer {
	display: flex;
	flex-direction: row;
	gap: 6px;
}

.icon {
	color: utilities.$text-accent;
	@include utilities.sm-down {
		display: none;
	}
}

.selectedRow {
	background: utilities.$global-card-background-secondary;
}

.unchecked {
	color: #909090;
	margin-right: 8px;
}

.checked {
	margin-right: 8px;
	color: utilities.$yellowTint1;
}

.option {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	cursor: pointer;
	&:hover {
		background: utilities.$global-card-background-secondary;
	}
	padding: utilities.$spacing1x 0 utilities.$spacing1x 0;
}

.disabledOption {
	opacity: 0.5;
	cursor: default;
	&:hover {
		background: none;
	}
}

.accessLink {
	color: utilities.$link;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}

.loading {
	height: 126px;
	width: 70%;
	border-radius: 8px;
	margin-bottom: utilities.$spacing3x;
}

.noAccess {
	filter: grayscale(100%);
	opacity: 0.5;
}

.noPointerEvents {
	pointer-events: none;
}

.popper {
	background-color: rgba(utilities.$yellowPrimary, 0.5);
	color: utilities.$text-primary;
	padding: utilities.$spacing1x utilities.$spacing2x;
}

.dropdownStyle {
	min-width: 350px;
}

.disabled {
	opacity: 0.5;
	filter: grayscale(100%);
}
