@use "styles/utilities";

.container {
	justify-content: center;
	flex-wrap: nowrap;
	gap: utilities.$spacing2x;
	@include utilities.lg-down {
		flex-direction: column;
		width: 100% !important;
		padding-left: 0px;
		gap: utilities.$spacing4x;
		& > div {
			width: 100%;
			max-width: 100%;
			min-width: unset;
		}
	}
}

.shippingDetailsContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}
