@use "styles/utilities";

.wrapper {
	padding: utilities.$spacing3x;
	@include utilities.centralizedWithMaxWidth();
}

.topSectionContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
}

.inputRoot {
	padding: 2px 4px;
	display: flex;
	align-items: center;
	border: 1px solid utilities.$border-color-heavy;
	border-radius: 4px;
	background-color: utilities.$global-card-background-primary;
}

.inputContainer {
	max-width: utilities.$maxWidthMediumCostTypeCard; // Keep the same width as the card
	@include utilities.md-down {
		width: 100%;
	}
}

.iconButton {
	padding: 10px;
}

.input {
	flex: 1;
}

.office365LicenseCard {
	margin-bottom: utilities.$spacing3x;
	width: utilities.$maxWidthMediumCostTypeCard;
	@include utilities.md-down {
		width: 100%;
	}
}

.breadcrumb {
	cursor: pointer;
}

.breadcrumb:hover {
	text-decoration: underline;
}

.detailedLicenseViewContainer {
	height: 100%;
}

.slideInRight {
	animation: slideInRight 0.5s forwards;
	@keyframes slideInRight {
		0% {
			transform: translateX(100%);
			opacity: 0;
		}
		100% {
			transform: translateX(0);
			opacity: 1;
		}
	}
}

.slideInLeft {
	animation: slideInLeft 0.5s forwards;
	@keyframes slideInLeft {
		0% {
			transform: translateX(-100%);
			opacity: 0;
		}
		100% {
			transform: translateX(0);
			opacity: 1;
		}
	}
}
