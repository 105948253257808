@use "styles/utilities";

.collaborationStructureCreatorContainer {
	display: flex;
	align-items: flex-start;
}

.collaborationStructureCreatorContentContainer {
	align-items: flex-start;
	@include utilities.md-down {
		@include utilities.fullScreenWidthStandardContenviewPadding();
		max-height: 60vh;
		& > div {
			width: utilities.$xl;
			flex-basis: utilities.$xl;
			min-width: utilities.$xl;
		}
		margin-bottom: utilities.$spacing2x;
		overflow: auto;
	}
}

.cardHeader {
	color: utilities.$text-primary;
}

.titleContainer {
	min-width: 200px;
}

.titleSectionContainer {
	background-color: utilities.$yellowTint4;
	padding: utilities.$spacing2x;
	border-radius: 2px;
	height: 100px;
}

.headerSectionContainer {
	padding: utilities.$spacing2x;
	height: 100px;
	@include utilities.lg-down {
		height: fit-content;
		padding-left: 0;
		& > *:first-child {
			width: 100%;
			flex-basis: 100%;
			max-width: 100%;
		}
	}

	@include utilities.md-down {
		padding-right: 0;
	}
}

.headerSectionWrapper {
	gap: utilities.$spacing2x;
	@include utilities.lg-down {
		flex-direction: column;
		justify-content: space-around;
	}
}

.titleSectionElement {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: utilities.$text-primary;
}

.buttonContainer {
	padding-top: 10px;
}
