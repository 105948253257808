@use "styles/utilities";

.wrapper {
	@include utilities.md-down {
		padding: 0 utilities.$spacing1x;
	}
}

.container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: utilities.$spacing3x;
	gap: utilities.$spacing4x;
	height: 700px;
}

.activeBreadcrumb {
	color: utilities.$yellowPrimary;
	font-weight: bold;
}

.breadcrumbs {
	@include utilities.md-down {
		padding: 0 utilities.$spacing3x;
	}
}

.firstBreadcrumb {
	color: utilities.$text-accent;
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}

.topSectionContainer {
	display: flex;
	flex-direction: row;
	gap: utilities.$spacing3x;
}

.licenseHistoryContainer {
	background-color: utilities.$global-card-background-primary;
	border-radius: 8px;
	padding: utilities.$spacing3x;
	width: 38%;
	border-radius: 8px;
	box-shadow: -3px 0px 13px 0px rgba(0, 0, 0, 0.09);
	overflow-y: scroll;
	min-width: 380px;
	align-self: center;
	@include utilities.md-up {
		height: calc(100% - utilities.$spacing3x);
	}
	@include utilities.md-down {
		order: 2;
		overflow-y: visible;
	}
	@include utilities.sm-down {
		margin: 0 utilities.$spacing3x;
		width: 100%;
	}
}

.cardsAndAdjustmentsContainer {
	display: flex;
	flex-direction: column;
	width: 58%;
	min-width: 450px;
}

.cardsContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.adjustmentsContainer {
	background-color: utilities.$global-card-background-primary;
	border-radius: 8px;
	padding: utilities.$spacing3x;
	margin-bottom: utilities.$spacing3x;
	width: 100%;
	height: 500px;
}

.savingsIcon {
	border-radius: 8px;
	padding: 4px;
	margin-right: 10px;
	font-size: 28px;
	background-color: utilities.$yellowTint4;
}

.calendarIcon {
	border-radius: 8px;
	padding: 4px;
	margin-right: 10px;
	font-size: 28px;
	background-color: utilities.$greenTint1;
}
