@use "styles/utilities";

.productContainer {
	padding-top: utilities.$spacing1x;
	flex-direction: column;
	animation-name: foldDown;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
	visibility: hidden; /* Initially hide the item */
}

.container {
	flex-direction: column;
	background-color: utilities.$global-card-background-primary;
	padding: utilities.$spacing4x utilities.$spacing2x;
	border-top: 2px solid utilities.$border-color-light;
	.productContainer:last-child {
		.divider {
			display: none;
		}
	}
}

.priceContainer {
	max-width: 150px;
	min-width: 150px;
	justify-content: flex-end;
	flex-wrap: nowrap;
	gap: utilities.$spacing1x;
	padding-right: utilities.$spacing3x;
	@include utilities.sm-down {
		justify-content: flex-start;
	}
	h6:nth-child(2) {
		font-size: 14px;
		color: utilities.$grey700;
	}
}

.stockContainer {
	max-width: 160px;
	min-width: 160px;
	display: flex;
	justify-content: flex-end;
	gap: utilities.$spacing1x;
	flex-direction: column;
	@include utilities.sm-down {
		width: 60%;
		max-width: 60%;
		min-width: 60%;
	}
	.outOfStockContainer {
		flex-direction: column;
		width: fit-content;
		align-items: center;
		gap: utilities.$spacing1x;
	}
}

.stockDescriptionContainer {
	gap: utilities.$spacing1x;
	align-items: center;
}

.productElement {
	flex-wrap: nowrap;
	justify-content: space-between;
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	align-items: center;
	gap: utilities.$spacing1x;
	padding-bottom: utilities.$spacing1x;
	@include utilities.sm-down {
		flex-wrap: wrap;
	}
}

.descriptionContainer {
	gap: utilities.$spacing1x;
}

.expectedDateText {
	color: utilities.$grey700;
}

.productLink {
	width: fit-content;
}

@keyframes foldDown {
	from {
		transform: scaleY(0);
		transform-origin: top;
		visibility: visible;
	}
	to {
		transform: scaleY(1);
		transform-origin: top;
		visibility: visible;
	}
}
