@use "styles/utilities";

.skeleton {
	transform: none;
}

.sliderContainer {
	gap: utilities.$spacing2x;
	padding-bottom: utilities.$spacing2x;
	padding-top: utilities.$spacing1x;
	padding-left: utilities.$spacing1x;
	padding-right: utilities.$spacing2x;
	justify-content: center;
}

.sliderTextContainer {
	justify-content: space-between;
	flex-wrap: nowrap;
	flex-direction: row;
	width: 120px;

	.sliderTextTo {
		width: 40px;
	}
}

.slider {
	color: #8c8888;
	height: 3px;
	padding: 13px 0;
	:global(.MuiSlider-thumb) {
		height: 27px;
		width: 27px;
		background-color: utilities.$secondary-button-background-color;
		&:nth-child(even) {
			svg {
				$rotate180deg: 180deg;
				-webkit-transform: rotate($rotate180deg);
				-moz-transform: rotate($rotate180deg);
				-ms-transform: rotate($rotate180deg);
				-o-transform: rotate($rotate180deg);
				transform: rotate($rotate180deg);
			}
		}
	}
	:global(.MuiSlider-track) {
		height: 1px;
	}
	:global(.MuiSlider-rail) {
		color: #d8d8d8;
		opacity: 1;
		height: 3px;
	}
}

.skeleton {
	transform: none;
	width: 100%;
}
