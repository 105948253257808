@use "styles/utilities";

.flag {
	width: 32px;
	height: 32px;
	margin-left: -6px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: auto;
	margin-bottom: auto;
	font-size: 1.1rem;
}

.coloredBackground {
	border-radius: 50%;
	background-color: #f5f5f5;
}

.autcompleteInput {
	margin-bottom: -40px;
}
