@use "styles/utilities";

.textField {
	width: 150px;
	margin-left: utilities.$spacing3x;
	@include utilities.sm-down {
		width: 100%;
	}
	background-color: utilities.$global-card-background-primary;
}

.mailField {
	min-width: 400px;
	@include utilities.sm-down {
		min-width: calc(100vw - 2 * utilities.$spacing3x);
	}
	background-color: utilities.$global-card-background-primary;
}

.inputText {
	font-size: 16px;
}

.radioBtn {
	padding: 5px;
	& svg {
		width: 0.9em;
		height: 0.9em;
	}
}

.container {
	@include utilities.md-down {
		flex-direction: column;
		flex-wrap: nowrap;
		gap: utilities.$spacing2x;
	}
}

.radioContainer {
	flex-wrap: nowrap;
	@include utilities.lg-down {
		& > *:first-child {
			margin-left: 0 !important;
			padding-left: 0 !important;
		}
	}

	@include utilities.sm-down {
		& > label {
			margin-left: 0 !important;
		}
	}
}

.inputLabel {
	background-color: rgba(255, 255, 255, 0);
}
