@use "styles/utilities";

.red {
	color: utilities.$error;
	font-weight: 600;
}

.green {
	color: utilities.$success;
	font-weight: 600;
}

.yellow {
	color: #e6a732;
	font-weight: 600;
}
