@use "styles/utilities";

$cardBreaksAt: 1613px;
$useFullWidthFrom: 776px;

.deviceCardsContainer {
	display: flex;
	direction: row;
	justify-content: flex-start;
	gap: utilities.$spacing2x;
	@media (max-width: $cardBreaksAt) {
		justify-content: center;
	}
}

.totalDevices {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: utilities.$greyTint4;
	width: 45%;
}

.totalDevicesSection {
	box-shadow: 0px 4px 6.2px 0px #0000000f;
	width: 349px;
	height: 130px;
	border-radius: 4px;
	@media (max-width: $cardBreaksAt) {
		width: 350px;
	}
	@media (max-width: $useFullWidthFrom) {
		width: 450px;
	}
}

.deviceStatusSection {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 349px;
	height: 130px;
	background-color: #fff;
	box-shadow: 0px 4px 6.2px 0px #0000000f;
	border-radius: 4px;
	@media (max-width: $cardBreaksAt) {
		width: 350px;
	}

	@media (max-width: $useFullWidthFrom) {
		width: 450px;
	}
}

.intuneDevicesSection {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 575px;
	height: 130px;
	background-color: #fff;
	box-shadow: 0px 4px 6.2px 0px #0000000f;
	border-radius: 4px;
	@media (max-width: $cardBreaksAt) {
		order: 5;
		width: 716px;
	}

	@media (max-width: $useFullWidthFrom) {
		width: 450px;
	}
}

.deviceCardTitle {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex: 1;
	width: 100%;
	padding-top: utilities.$spacing2x;
	position: relative;
}

.deviceCardContentContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	flex: 1;
	padding-bottom: utilities.$spacing1x;
}

.deviceCardContent {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: utilities.$spacing1x;
	&:hover {
		cursor: pointer;
		background-color: utilities.$greyTint1;
		border-radius: 4px;
	}
	@include utilities.md-down {
		text-align: center;
		p {
			font-size: 12px;
		}
	}
}

.deviceCardDescription {
	color: utilities.$greyTint3;
}

.entraDevices {
	width: 55%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #fff;
}

.divider {
	height: 70%;
	opacity: 0.5;
}

.intuneCardTitle {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: fit-content;
}

.red {
	color: utilities.$error;
	font-weight: 500;
}

.green {
	color: utilities.$success;
	font-weight: 500;
}

.yellow {
	color: #e6a732;
	font-weight: 500;
}

.logoAndTitle {
	display: flex;
	align-items: center;
}

.logo {
	width: 30px;
	height: 30px;
	padding: 4px;
	border-radius: 4px;
	background-color: #efefef;
	margin-right: 4px;
}

.deviceIcon {
	border-radius: 4px;
	background-color: #efefef;
	margin-right: 4px;
	color: utilities.$text-accent;
	width: 30px;
	height: 30px;
	padding: 4px;
}
