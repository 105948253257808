@use "styles/utilities";

.container {
	display: flex;
	flex-direction: row;
	gap: utilities.$spacing3x;
	background-color: #fff;
	box-shadow: 0px 4px 6.2px 0px #e5ebf7;
	border-radius: 8px;
	padding: utilities.$spacing3x;
	min-height: 170px;
	min-height: fit-content;
	@include utilities.md-down {
		height: auto;
	}
	overflow: hidden;
}

.divider {
	@include utilities.xl-down {
		display: none;
	}
}

.companyInfo {
	display: flex;
	flex-direction: row;
	width: 38%;
	position: relative;
	margin: utilities.$spacing1x 0;
	@include utilities.xl-down {
		border-bottom: 1px solid utilities.$border-color;
		padding-bottom: utilities.$spacing4x;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
}

.companyInfoProducts {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	min-width: 250px;
	width: 25%;
	@include utilities.xl-down {
		width: 46%;
	}
	@include utilities.sm-down {
		padding-bottom: utilities.$spacing4x;
		border-bottom: 1px solid utilities.$border-color;
		width: 100%;
	}
}

.companyInfoContacts {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-width: 250px;
	width: 25%;
	@include utilities.xl-down {
		justify-content: center;
		width: 46%;
	}
	@include utilities.sm-down {
		width: 100%;
	}
}

.locationInfo {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.hide {
	display: none;
}

.url {
	color: utilities.$link;
	cursor: pointer;
}

.productStatusLabel {
	color: utilities.$text-secondary;
	background-color: utilities.$gray;
	padding: 2px 8px;
	border-radius: 12px;
	font-size: 12px;
	font-weight: 600;
}

.active {
	@extend .productStatusLabel;
	color: utilities.$text-secondary;
	background-color: utilities.$success;
}

.productInfo {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.activeProduct {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	white-space: nowrap;
	max-width: 200px;
	@include utilities.md-down {
		max-width: 190px;
	}
}

.inactiveProduct {
	@extend .activeProduct;
	p {
		color: utilities.$grey600;
	}
	svg {
		filter: grayscale(100%);
		opacity: 0.5;
	}
}

.productIcon {
	svg {
		width: 32px;
		height: 32px;
	}
}

.contactType {
	min-width: 80px;
}

.contacts {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-width: 280px;
}

.contactInfo {
	display: flex;
	flex-direction: row;
	margin-top: 22px;
}

.contactName {
	color: utilities.$text-accent;
}

.logo {
	width: 100%;
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.logoContainer {
	width: 40%;
	max-width: 300px;
	border: 1px solid utilities.$border-color;
	padding: 0 utilities.$spacing1x;
	border-radius: 8px;
	overflow: hidden;
}

.details {
	width: 60%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 0 utilities.$spacing2x;
	gap: 4px;
}

.urlInfo {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
}

.languageIcon {
	width: 18px;
	height: 18px;
	color: utilities.$text-accent;
}
