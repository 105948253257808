@use "styles/utilities";

.wrapper {
	@include utilities.centralizedWithMaxWidth();
	padding: utilities.$spacing3x;
}

.topSectionContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	flex-wrap: nowrap;
	@include utilities.lg-down {
		flex-wrap: wrap;
	}
}

.dateRangePickerContainer:hover {
	cursor: pointer;
}

.inputRoot {
	padding: 2px 4px;
	display: flex;
	align-items: center;
	border: 1px solid utilities.$border-color;
	border-radius: 4px;
	background-color: utilities.$global-card-background-primary;
}

.iconButton {
	padding: 10px;
}

.input {
	flex: 1;
}

.cardAndInputContainer {
	max-width: utilities.$maxWidthMediumCostTypeCard;
}

.inputContainer {
	width: 100%;
	justify-content: space-between;
	gap: 6px;
	flex-wrap: nowrap;
	@include utilities.sm-down {
		flex-wrap: wrap;
	}
}
