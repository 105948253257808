@use "styles/utilities";

.wrapper {
	padding: utilities.$spacing3x;
	@include utilities.centralizedWithMaxWidth();
}

.topSectionContainer {
	display: flex;
	flex-direction: row;
}

.updateIntervalContainer {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.cardContainer {
	width: utilities.$maxWidthMediumCostTypeCard;
}
