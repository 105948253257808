@use "styles/utilities";

.checkboxContainer {
	// Remove the default margin
	margin-right: -20px;
}

.agreeToTermsContainer {
	display: flex;
	align-items: center;
	padding-bottom: utilities.$spacing3x;
}

.disabled {
	opacity: 0.5;
}

.termsAndConditionsText {
	display: flex;
	align-items: center;
	gap: 2px;
}

.hidden {
	display: none;
}
