@use "styles/utilities";

.titleContainer {
	background-color: white;
	border: 1px solid utilities.$border-color;
	border-radius: 8px 8px 0px 0px;
	border-bottom: 1px solid utilities.$border-color;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.licenseTextContainer {
	padding: 5px 0px;
	display: flex;
	justify-content: flex-end;
	color: utilities.$text-primary;
	text-align: right;
}

.skeleton {
	margin-left: utilities.$spacing2x;
	width: 75px;
	vertical-align: center;
}

.infoIcon {
	&:hover {
		cursor: pointer;
	}
	color: utilities.$text-accent;
}

.removeableLicenses {
	gap: utilities.$spacing1x;
}

.tooTipTitle {
	color: white;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: utilities.$spacing1x;
}

.tooltipDescription {
	color: white;
	font-size: 14px;
	padding-bottom: utilities.$spacing2x;
}
