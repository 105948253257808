@use "styles/utilities";

.addToCartButton {
	min-width: 160px;
	max-height: 50px;
	@include utilities.sm-down {
		padding: utilities.$spacing1x utilities.$spacing2x;
		min-width: fit-content;
		max-width: fit-content;
		width: fit-content;
		min-height: fit-content;
		max-height: fit-content;
	}
}
