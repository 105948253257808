@use "styles/utilities";

.cartContainer {
	flex-direction: column;
}

.cartItemsContainer {
	direction: column;
	padding-bottom: 148px;
}

.cartItemsCountContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: utilities.$spacing3x utilities.$spacing2x;
}

.emptyCartButton {
	border-radius: 4px;
	gap: 4px;
}

.cartTotalsContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: absolute;
	bottom: 0;
	background-color: rgba(234, 234, 234, 0.7);
	backdrop-filter: blur(7px);
	padding: utilities.$spacing3x utilities.$spacing3x;
	width: 100%;
	z-index: 100;
}
