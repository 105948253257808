@use "styles/utilities";

.closedBorder {
	border: 1px solid utilities.$border-color-light !important;
}

.bundleNameContainer {
	width: 20%;

	@include utilities.md-down {
		width: 300px;
	}

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.skeleton {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	min-height: 150px;
	margin-bottom: -50px;
	margin-top: -50px;
}

.editBundleButtonContent {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 5px;
	flex-wrap: nowrap;
}

.bundlesSettingsWrapper {
	justify-content: center;
	margin-bottom: 80px;
}

.createNewBundleButtonContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.bundlesSettingsContainer {
	flex-wrap: nowrap;
	flex-direction: column;
}

.bundleContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: utilities.$spacing4x;
	background-color: utilities.$global-card-background-primary;
	border: 1px solid transparent;
	border-radius: 4px;
	gap: utilities.$spacing3x;
	margin-top: utilities.$spacing4x;
	hr {
		display: none;
	}
	&:hover {
		cursor: pointer;
	}

	@include utilities.md-down {
		gap: utilities.$spacing1x;
	}
}

.bundleContainerSkeleton {
	@extend .bundleContainer;
	background-color: transparent;
	&:hover {
		cursor: default;
	}
}

.bundleLists {
	flex-wrap: none;
	flex-direction: column;
}

.totalBundleCost {
	border: none;
	text-align: center;
	align-items: center;

	@include utilities.md-down {
		display: none;
	}
}

.editContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	flex-grow: 1;
	@include utilities.md-down {
		justify-content: space-around;
		width: 100%;
	}

	button {
		min-width: none;
		max-width: fit-content;
		width: fit-content;
	}
	.editButtonContainer {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 5px;
		flex-wrap: nowrap;
		text-decoration: underline;
	}
	svg {
		margin-top: 2px;
		font-size: 16px;
		color: #505050;
	}
	p {
		color: #505050;
		font-family: Barlow;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
	}
}

html {
	@media screen and (min-width: utilities.$lg) {
		overflow-y: scroll;
	}
}

.titleContainer {
	justify-content: space-between;
	padding-bottom: utilities.$spacing4x;
}

.deleteButtonContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
	flex-wrap: nowrap;
	svg {
		font-size: 16px;
		color: utilities.$error;
	}
	p {
		font-family: Barlow;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		color: utilities.$error;
	}
	&:hover {
		cursor: pointer;
		text-decoration: underline;
		text-decoration-color: utilities.$error;
	}
}

.openIconContainer {
	width: 5%;
}

.openIcon {
	color: utilities.$text-primary;
	&:hover {
		cursor: pointer;
	}
}

.iconCount {
	color: utilities.$text-primary;
	white-space: nowrap;
}

.iconsContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: utilities.$spacing2x;
	color: utilities.$text-primary;
	width: 30%;
	overflow: hidden;
	@include utilities.xl-down {
		display: none;
	}
}

.iconContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 6px;
}

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
