@use "styles/utilities";

.inputRoot {
	padding: 2px 4px;
	display: flex;
	align-items: center;
	border: 1px solid utilities.$border-color;
	border-radius: 4px;
	background-color: utilities.$global-card-background-primary;
}

.iconButton {
	padding: 10px;
}

.input {
	flex: 1;
}

.loading {
	margin-right: utilities.$spacing2x;
}

.inputContainer {
	width: 100%;
	margin-top: utilities.$spacing2x;
	justify-content: space-between;
	gap: utilities.$spacing1x;
	flex-wrap: nowrap;
	@include utilities.sm-down {
		flex-wrap: wrap;
		flex-direction: column;
	}
}
