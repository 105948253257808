@use "styles/utilities";

.tableContainer {
	border: 1px solid #c5c5c5;
	border-radius: 4px;
	margin-top: 10px;
	max-height: 300px;
	height: 500px;
	overflow-y: auto;
}

.tableHead {
	background-color: utilities.$table-head-primary;
}

.noItemsCell {
	border-bottom: none;
}
