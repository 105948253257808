@use "styles/utilities";

.ingressContainer {
	background-color: #ffebba;
	padding-left: utilities.$spacing3x;
}

.backdrop {
	background-color: #fff;
	z-index: 1000;
}

.loadingIcon {
	padding-top: 3px;
	padding-bottom: 2px;
	padding-right: 2px;
	padding-left: 3px;
}
