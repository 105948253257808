@use "../common.module";
@use "styles/utilities";

.paperContainer {
	height: calc(common.$updatesCardHeight + 4px);
	border-radius: 0;
	box-shadow: none;
	@include utilities.md-down {
		height: fit-content;
	}
}

.listContainer {
	@include utilities.md-down {
		li:last-child {
			border-bottom: none;
		}
	}
}
