@use "styles/utilities";

.container {
	display: flex;
	flex-direction: row;
	margin-top: 110px;
	gap: utilities.$spacing3x;
	justify-content: space-around;
	@include utilities.md-down {
		flex-direction: column;
	}
}

.addLicenseGroup {
	width: 65%;
	@include utilities.md-down {
		width: 100%;
	}
}

.addAccessGroup {
	width: 25%;
	@include utilities.md-down {
		width: 100%;
	}
}
