@use "styles/utilities";

.wrapper {
	background: white;
	width: 100%;
}

.sticky {
	position: -webkit-sticky; /* Safari & IE */
	position: sticky;
	top: 0;
}

.formGroup {
	height: fit-content;
}

.container {
	padding: utilities.$spacing2x utilities.$spacing2x utilities.$spacing1x utilities.$spacing2x;
	border-top: 1px solid utilities.$border-color-light;
	border-left: 1px solid utilities.$border-color-light;
	border-right: 1px solid utilities.$border-color-light;
	min-width: 200px;
	width: 100%;
	overflow-y: auto;
	margin-bottom: utilities.$spacing4x;
}

.groupDivider {
	margin-bottom: 15px;
}

.skeleton {
	transform: none;
	width: 100%;
}
