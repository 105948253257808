@use "styles/utilities";

$bundledetailWidth: 400px;
$maxWidthForOpenBundleDetails: 1700px;

.wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.container {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
}

.header {
	height: 120px;
	background-color: #fbfbfb;
	z-index: 1;
	position: fixed;
}

.shadow {
	border-radius: 4px;
	box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.1);
}

.recommendedHeaderOnboarding {
	align-items: center;
}

.recommendedHeaderHardwareTab {
	min-height: 2em;
}

.inputText {
	font-size: 16px;
	font-weight: 400;
}

.inputTextHeader {
	display: none;
}

.formGroup {
	background-color: utilities.$global-card-background-primary;
	height: fit-content;
	min-width: fit-content;
	width: 20%;

	@include utilities.md-down {
		display: none;
	}
}

.products {
	height: fit-content;
	@include utilities.md-down {
		width: 100%;
	}
}

.myBundle {
	align-items: center;
	width: fit-content;
	cursor: pointer;
	align-items: center;
	display: flex;
	svg {
		font-size: 30px;
	}
}

.badge {
	background-color: utilities.$primary-button-background-color;
}

.skeleton {
	transform: none;
}

.showMoreProductsWrapper {
	width: 1100px;
}

.editBundleContentWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: nowrap;
	.productsWrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		flex-grow: 1;
		@media (max-width: $maxWidthForOpenBundleDetails) {
			margin-right: 0;
		}
	}
}

.loaderRef {
	height: 10px;
	width: 100%;
}

.noItemsMessageContainer {
	padding-left: utilities.$spacing2x;
}

.searchBannerContainer {
	position: -webkit-sticky; /* Safari & IE */
	position: sticky;
	top: 0;
	z-index: 20;

	padding-left: utilities.$spacing3x;
	@include utilities.md-down {
		padding-left: 0;
	}
}
