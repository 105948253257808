@use "styles/utilities";

.dialogContainer {
	height: 100%;
	padding: 0;
}

.dialogGridContainer {
	height: 100%;
	justify-content: center;
}

.appBarTop {
	height: 110px;
}

.appBarTopContainer {
	height: 100%;
	@include utilities.md-down {
		width: 100%;
		max-width: 100%;
		flex-basis: 100%;
		flex-wrap: nowrap;
		justify-content: space-between;
	}
}

.appBarBottom {
	height: fit-content;

	:global(.MuiGrid-root) {
		position: fixed;
		bottom: 0px;
		background: #ffffff;
		box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.1);
	}
}

.stepLabel {
	display: flex;
	flex-direction: column;
	padding-top: 20px;
}

.disabledStepLabel {
	display: flex;
	flex-direction: column;
	padding-top: 40px;
	width: 110px;
}

.disabled {
	text-decoration: line-through;
}

.button {
	width: 200px;
	height: 60px;
	margin: 20px 0 20px 50px;
	color: utilities.$text-primary;
	border: 0px -4px 16px rgba(0, 0, 0, 0.1);
}

.nextButton {
	width: 200px;
	height: 60px;
	margin: 20px 50px 20px 0;
	background-color: utilities.$primary-button-background-color;
	color: utilities.$text-primary;
}

.nextButton:hover {
	background-color: utilities.$primary-button-background-color;
	color: utilities.$text-primary;
}

.loader {
	width: 410px;
}

.bigSuccess {
	font-size: 135px;
}

.smallSuccess {
	font-size: 36px;
}

.pending {
	font-size: 32px;
}

.logo {
	width: auto;
	height: 50px;
}

.cursor {
	cursor: pointer;
}

.hideForSmallerScreens {
	@include utilities.md-down {
		display: none;
	}
}
.hideForMediumScreens {
	@include utilities.lg-down {
		display: none;
	}
}

.showForSmallScreens {
	display: none;
	@include utilities.md-down {
		display: block;
	}
}

.dialogTitle {
	@include utilities.md-down {
		width: 100%;
		max-width: 100%;
		flex-basis: 100%;
		flex-wrap: nowrap;
		white-space: nowrap;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		padding-left: utilities.$spacing3x;
		gap: utilities.$spacing1x;
		h2 {
			padding-left: 0px;
			padding-right: 0px;
		}
	}
}

.innerContainer {
	& > div {
		height: calc(100% + 200px);
	}
}

.dialogContent {
	overflow: scroll;
}

.buttonContainer {
	justify-content: space-between;
	flex-wrap: nowrap;
	@include utilities.sm-down {
		button {
			margin-left: 10px;
			margin-right: 10px;
			padding: 0px;
			min-width: 160px;
			width: 160px;
		}
	}
}

.hardwarePageContainer {
	max-width: utilities.$xl;
}
