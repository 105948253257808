@use "styles/utilities";

.userRow {
	border-bottom: 1px solid utilities.$border-color-light;
	padding: utilities.$spacing1x 0 utilities.$spacing1x utilities.$spacing1x;
}

.tableHeadRow {
	th {
		font-size: 16px;
	}
}

.removalCell {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 6px;
}

.infoIcon {
	font-size: 18px;
	color: utilities.$text-accent;
}

.totalSavingsContainer {
	justify-content: space-between;
	padding: utilities.$spacing2x;
	border-bottom: 1px solid utilities.$border-color;
}

.potentialSavings {
	background-color: utilities.$yellowTint4;
	border-radius: 4px;
	padding: 2px 12px;
	font-weight: 500;
	max-height: 34px;
}

.bottomTableSectionContainer {
	border-bottom: 1px solid utilities.$border-color;
}

.tableHead {
	@include utilities.responsiveTableHeader();
	background-color: utilities.$table-head-primary !important;
	position: sticky;
	top: 0;
	z-index: 1;
}

.tableContainer {
	border: transparent;
	max-height: 400px;
	overflow: auto;
	@include utilities.responsiveTableContainer();
}

.userDetailsTableContainer {
	border: transparent;
	max-height: 500px;
	overflow: auto;
	@include utilities.responsiveTableContainer();
}

.table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0 utilities.$spacing2x;
}

.tableRow {
	height: 7vh;
	border: none;
}

.emailInputContainer {
	display: flex;
	height: 100%;
	align-items: flex-end;
}

.emailInput {
	width: 50%;
}
