@use "styles/utilities";

.title {
	display: flex;
	justify-content: space-between;
}

.lockedTitle {
	@extend .title;
	position: relative;
	z-index: 1;
	filter: blur(1.5px);
}

.textContainer {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: utilities.$spacing1x;
}

.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: utilities.$global-card-background-primary;
	border-radius: 8px;
	padding: utilities.$spacing3x;
	width: 48%;
	height: 150px;
	overflow: hidden;
}

.lockedContainer {
	@extend .container;
	position: relative;
	overflow: visible !important;
	&:hover {
		cursor: pointer;
		.lockIcon {
			cursor: pointer;
			color: utilities.$text-primary;
			transform: scale(1.05);
			transition-duration: 1000ms;
		}
	}
}

.blurredOverlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	backdrop-filter: blur(3px);
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 8px;
	z-index: 1;
}

.selectable {
	cursor: pointer;
	&:hover {
		box-shadow: 0px 4px 10px utilities.$border-color-heavy;
		transition-duration: 300ms;
	}
}

.link {
	margin-top: utilities.$spacing1x;
	p {
		text-decoration: underline;
		color: utilities.$text-accent;
	}
}

.lockIcon {
	position: absolute;
	top: -5px;
	right: -5px;
	color: utilities.$text-accent;
	z-index: 2;
}

.lockedText {
	z-index: 3;
	position: absolute;
	top: 50%;
	transform: translate(20%, -50%);
	width: 70%;
}
