@use "styles/utilities";

.card {
	// Define your card styles here
	margin: 10px;
	width: 380px;
	height: 245px;
	background-color: utilities.$global-card-background-primary;
	border: 1px solid utilities.$border-color;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
}

.cardHover {
	&:hover {
		box-shadow: 0px 4px 10px utilities.$border-color-heavy;
		transition-duration: 300ms;
		cursor: pointer;
	}
}

.titleContainer {
	display: flex;
	border-bottom: 1px solid utilities.$border-color;
	gap: 12px;
	padding: utilities.$spacing2x utilities.$spacing3x;
	align-items: center;
}

.title {
	font-size: 1.2rem;
	font-weight: bold;
}

.licenseInfo {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
	padding: utilities.$spacing3x;
}

.manageLink {
	display: flex;
	align-items: center;

	svg {
		margin-left: 0.5rem;
	}
}
