@use "styles/utilities";

.smallButton {
	padding-left: utilities.$small-button-padding-x;
	padding-right: utilities.$small-button-padding-x;
	padding-top: utilities.$small-button-padding-y;
	padding-bottom: utilities.$small-button-padding-y;
	min-width: 90px;
	width: fit-content;
	height: 48px;
}

.smallButtonText {
	font-family: utilities.$global-font-family;
	font-style: utilities.$small-button-font-style;
	font-weight: utilities.$small-button-font-weight;
	font-size: utilities.$small-button-font-size;
	line-height: utilities.$small-button-line-height;
}

.mediumButton {
	padding-left: utilities.$medium-button-padding-x;
	padding-right: utilities.$medium-button-padding-x;
	padding-top: utilities.$medium-button-padding-y;
	padding-bottom: utilities.$medium-button-padding-y;
	min-width: 90px;
	width: fit-content;
	height: 52px;
}

.mediumButtonText {
	font-family: utilities.$global-font-family;
	font-style: utilities.$medium-button-font-style;
	font-weight: utilities.$medium-button-font-weight;
	font-size: utilities.$medium-button-font-size;
	line-height: utilities.$medium-button-line-height;
}

.largeButton {
	padding-top: utilities.$large-button-padding-y;
	padding-bottom: utilities.$large-button-padding-y;
	max-height: 60px;
	min-height: 60px;
	min-width: 200px;
	max-width: 200px;
}

.largeButtonText {
	font-family: utilities.$global-font-family;
	font-style: utilities.$large-button-font-style;
	font-weight: utilities.$large-button-font-weight;
	font-size: utilities.$large-button-font-size;
	line-height: utilities.$large-button-line-height;
}

.allPrimaryButtonColor {
	background-color: utilities.$primary-button-background-color;
	color: utilities.$text-primary;
}

.allPrimaryButtonColor:hover {
	background-color: utilities.$primary-button-hover-background-color;
	color: utilities.$text-primary;
	box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.12);
}

.allSecondaryButtonColor {
	background-color: utilities.$secondary-button-background-color;
	color: utilities.$text-primary;
}

.allSecondaryButtonColor:hover {
	background-color: utilities.$secondary-button-hover-background-color;
	color: utilities.$text-primary;
}

.allDeleteButtonColor {
	background-color: utilities.$delete-button-background-color;
	color: utilities.$delete-button-text-color;
}

.allDeleteButtonColor:hover {
	background-color: utilities.$delete-button-hover-background-color;
	color: utilities.$delete-button-text-color;
}

.arrowIcon {
	color: utilities.$text-accent;
	position: absolute;
	right: 12px;
}

.allOpenDrawerPrimaryButtonColor {
	position: relative;
	color: utilities.$text-primary;
	background-color: utilities.$yellowPrimary;
	border: transparent;
	overflow: hidden;
	min-width: 200px;
	display: flex;
	justify-content: space-between;
	&:hover {
		border: transparent;
		background-color: utilities.$primary-button-hover-background-color;
		box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.12);
	}

	&:hover .arrowIcon {
		color: white;
		transform: translateX(3px);
		transition: 0.2s ease-in-out;
	}
}

.allOpenDrawerButtonColor {
	position: relative;
	color: utilities.$text-primary;
	border: 1px solid utilities.$border-color;
	overflow: hidden;
	min-width: 200px;
	display: flex;
	justify-content: space-between;
	&:hover {
		border: 1px solid utilities.$primary-button-hover-background-color;
		background-color: utilities.$primary-button-hover-background-color;
		box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.12);
	}

	&:hover .arrowIcon {
		color: white;
		transform: translateX(3px);
		transition: 0.2s ease-in-out;
	}
}

.allOpenDrawerButtonColor::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	z-index: -1;
}

.allOpenDrawerButtonColor:hover::before {
	width: 100%;
}

.icon {
	margin-right: utilities.$spacing1x;
}

.hidden {
	visibility: hidden;
}
