@use "styles/utilities";

.errorBoundaryHeader {
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	text-align: center;
	padding: utilities.$spacing1x;
	background-color: utilities.$global-background-primary;
}

.errorBoundaryContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.errorBoundaryContent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: utilities.$spacing1x;
}
