@use "styles/utilities";

.tableHead {
	@include utilities.responsiveTableHeader();
	background-color: utilities.$table-head-primary !important;
	position: sticky;
	top: 0;
	z-index: 1;
}

.tableContainer {
	border: transparent;
	max-height: 400px;
	overflow: auto;
	background-color: utilities.$global-card-background-primary;
	@include utilities.responsiveTableContainer();
}

.row {
	height: 4vh;
	border-bottom: 2px dotted utilities.$border-color;
	background-color: transparent !important;
	td {
		background-color: transparent !important;
	}
	&:last-child {
		border-bottom: none;
	}
}

.lastRow {
	height: 4vh;
	border-top: 2px dotted utilities.$border-color-heavy;
	border-bottom: 2px dotted utilities.$border-color-heavy;
	background-color: transparent !important;
	td {
		background-color: transparent !important;
	}
}

.priceContainer {
	overflow: hidden;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: utilities.$spacing1x;
}

.priceSummary {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: utilities.$spacing1x;
}

.hide {
	display: none;
}

.totalCell {
	column-span: 1;
}
