@use "styles/utilities";

.noBorderTableRow {
	td {
		border: none !important;
	}
}

.removalContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 6px;
}

.historyIcon {
	margin-top: 1px;
	font-size: 16px;
	color: utilities.$success;
}

.lowPriorityIcon {
	margin-top: 1px;
	font-size: 16px;
}
