@use "styles/utilities";

.inputTextHeader {
	display: none;
}

.dropdown {
	height: 56px;
	min-width: 150px;
}
