@use "styles/utilities";

.drawer {
	padding: utilities.$spacing3x;
	display: flex;
	flex-direction: column;
	gap: utilities.$spacing2x;
	width: 700px;
	@include utilities.md-down {
		width: 100%;
	}
}

.licensedUsersDrawerWrapper {
	z-index: utilities.$rightDrawerZIndex;
	position: fixed;
}

.content {
	width: 90%;
	display: flex;
	flex-direction: column;
}

.titleContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: utilities.$spacing1x;
	margin-bottom: utilities.$spacing3x;
}

.closeButtonContainer {
	position: absolute;
	top: 8px;
	right: 8px;
}

.leftColumn {
	width: 45%;
	padding-left: utilities.$spacing2x;
}

.rightColumn {
	width: 50%;
}

.tableHeader {
	display: flex;
	flex-direction: row;
	background-color: utilities.$table-header-background;
	padding: utilities.$spacing2x 0;
	border-radius: 4px;
}

.tableContent {
	display: flex;
	flex-direction: column;
	margin-top: utilities.$spacing2x;
	gap: utilities.$spacing2x;
}

.tableRow {
	border-bottom: 1px solid utilities.$border-color-light;
	display: flex;
	flex-direction: row;
	padding-bottom: utilities.$spacing1x;
}

.mainLicensename {
	width: 100%;
}
