@use "styles/utilities";

.wrapper {
	width: 100%;
	height: 100%;
	border-radius: 4px;
	justify-content: center;
}

.hardwarePageContainer {
	// Really struggling with the carousel in the SelectHardwareView to adhere to it's max width.
	// This causes everything else in the page to stop caring about said max width.
	// Thus, this "hack" below will ensure that the page always looks OK.
	max-width: 85vw;
	@include utilities.xl-down {
		max-width: 75vw;
	}
	@include utilities.lg-down {
		max-width: 90vw;
	}
	@include utilities.sm-down {
		max-width: 100vw;
	}
}

.productsListViewContainer {
	max-width: utilities.$xl;
}

.header {
	height: 120px;
	background-color: #fbfbfb;
	z-index: 1;
	position: fixed;
}

.shadow {
	border-radius: 4px;
	box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.1);
}

.sticky {
	position: -webkit-sticky; /* Safari & IE */
	position: sticky;
	top: 0;
}

.recommendedHeaderOnboarding {
	align-items: center;
}

.recommendedHeaderHardwareTab {
	min-height: 2em;
}

.inputText {
	font-size: 16px;
	font-weight: 400;
}

.inputTextHeader {
	display: none;
}

.formGroup {
	background-color: #ffffff;
	height: fit-content;
	min-width: fit-content;
	width: 20%;

	@include utilities.md-down {
		display: none;
	}
}

.products {
	height: fit-content;
	max-width: 1400px;
	width: 80%;
	@include utilities.md-down {
		width: 100%;
	}
}

.availability {
	width: 250px;
}

.search {
	display: flex;
	height: 60px;
}

.searchWrapper {
	width: 320px;
}

.settingsIcon {
	transition: transform 0.5s ease-in-out;

	// Rotate 30 degrees when parent is hovered
	.settingsButton:hover & {
		transform: rotate(30deg);
	}
}

.settingsButton {
	margin-bottom: 8px;
}

.skeleton {
	transform: none;
}

.showMoreProductsWrapper {
	width: 1100px;
}

.productsWrapper {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
}

.hardwareListContainer {
	padding-right: utilities.$spacing3x;
	width: 100%;
	@include utilities.sm-down {
		padding-right: utilities.$spacing1x;
	}
}

.onBoardingOffset {
	padding-top: 8px;
	margin-bottom: -100px;
}

.loaderRef {
	height: 10px;
	width: 100%;
}

.noItemsMessageContainer {
	padding-left: utilities.$spacing3x;
}

.dividerContainer {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-radius: 4px;
	align-items: flex-end;
	margin-bottom: utilities.$spacing2x;
}

.title {
	margin-bottom: utilities.$spacing2x;
}

.dividerContainer::after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 4px;
	background-color: utilities.$table-header-background;
	border-radius: 4px;
}

.bundleContainer {
	max-width: calc(100vw - utilities.$drawerWidth - utilities.$spacing3x);
	flex-wrap: nowrap;
	@include utilities.xl-down {
		padding-left: 0;
	}
	@include utilities.lg-down {
		max-width: 100vw;
	}
}

.searchBannerContainer {
	position: -webkit-sticky; /* Safari & IE */
	position: sticky;
	top: 0;
	z-index: 20;

	padding-left: utilities.$spacing3x;
	@include utilities.md-down {
		padding-left: 0;
	}
}
