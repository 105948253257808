@use "styles/utilities";

.contentContainer {
	display: flex;
	flex-direction: column;
	min-height: 600px;
}

.expandAllButtonContainer {
	display: flex;
	justify-content: flex-end;
}

.inputRoot {
	padding: 2px 4px;
	display: flex;
	align-items: center;
	border: 1px solid utilities.$border-color-heavy;
	border-radius: 4px;
	background-color: utilities.$global-background-primary;
	height: 50px;
}

.iconButton {
	padding: 10px;
}

.input {
	flex: 1;
}

.dropdown {
	display: flex;
	align-items: center;
	height: 50px;
	font-size: 14px;
	font-weight: 400;
}

.dropdownSku {
	display: flex;
	align-items: center;
	width: 200px;
	height: 50px;
	font-size: 14px;
	font-weight: 400;
}

.inputText {
	font-size: 16px;
	font-weight: 400;
}

.loading {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 400px;
	width: 100%;
}
