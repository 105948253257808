@use "styles/utilities";

.priceText {
	min-width: 100px;
}

.potentialSavings {
	margin-top: utilities.$spacing1x;
	background-color: utilities.$yellowTint4;
	border-radius: 4px;
	padding: 6px 14px;
	font-weight: 500;
	width: fit-content;
}

.requestWalkthroughContainer {
	@include utilities.md-up {
		width: 75%;
	}
	@include utilities.md-down {
		width: 95%;
	}
}

.skybertContainer {
	align-items: center;
	vertical-align: center;
}
