@use "styles/utilities";

.wrapper {
	@include utilities.centralizedWithMaxWidth();
}

.detailsWrapper {
	padding: utilities.$spacing3x 0;
	@include utilities.centralizedWithMaxWidth(1000px);
}

.titleSectionContainer {
	width: 100%;
	padding-top: utilities.$spacing3x;
	display: flex;
	justify-content: center;
}

.titleSection {
	padding-top: utilities.$spacing3x;
	gap: utilities.$spacing2x;
	// styles below to keep title in line with cards
	justify-content: flex-start !important;
	@media (max-width: 838px) {
		padding: utilities.$spacing3x;
	}
	@media (max-width: 1497px) {
		width: 808px;
	}
	width: 1224px;
}

.cardsContainer {
	display: flex;
	justify-content: center;
	gap: utilities.$spacing3x;
}
