@use "styles/utilities";

.tableHead {
	background-color: utilities.$table-header-background;
	@include utilities.responsiveTableHeader();
}

.tableContainer {
	margin-bottom: 100px;
	border: none;

	min-width: 0 !important;
	@include utilities.responsiveTableContainer();
}

.rowStyle {
	height: 7vh;
	background-color: utilities.$global-card-background-primary;
}

.linkIcon {
	margin-top: 3px;
	font-size: inherit;
}

.linkText {
	vertical-align: text-bottom;
	margin-right: 4px;
	font-size: 16px;
	color: utilities.$link;
}

.costTypeCell {
	text-align: end;
}

.ticketCell {
	max-width: 400px;
	overflow: hidden;
}

.inlineTextRight {
	margin-left: utilities.$spacing1x;
	margin-right: utilities.$spacing3x;
}

.footerCell {
	align-items: center;
	flex-direction: row;
	display: flex;
}

.tableFooter {
	position: fixed;
	left: utilities.$drawerWidth + utilities.$spacing3x;
	right: utilities.$spacing3x;
	bottom: 0;
	background-color: #fcfcfc;
	border: 1.6px solid utilities.$border-color;
	box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.1);
}

.hidden {
	display: none;
}

.strikethrough {
	text-decoration: line-through;
}
