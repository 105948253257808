@use "styles/utilities";

.chartContainer {
	width: 350px;
	height: 250px;
}

.labelsContainer {
	padding: utilities.$spacing2x;
	padding-left: utilities.$spacing4x;
	@include utilities.md-down {
		padding-left: 0;
	}
}

.labelContainer {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
}

.blueIcon {
	color: utilities.$blueTint1;
	margin-right: utilities.$spacing2x;
}

.yellowIcon {
	color: utilities.$yellowDark;
	margin-right: utilities.$spacing2x;
}

.iconAndTextContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	vertical-align: center;
}

.priceText {
	min-width: 100px;
}
