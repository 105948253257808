@use "styles/utilities";

.title {
	border: 1px solid utilities.$border-color;
	border-radius: 8px;
	background-color: utilities.$global-card-background-primary;
}

.sectionTitle {
	color: utilities.$text-primary;
	background-color: utilities.$global-card-background-secondary;
	padding: utilities.$spacing2x;
	height: 62px;
	display: flex;
	align-items: center;
}

.tableHead {
	background-color: utilities.$table-header-background;
}

.progress {
	height: 14px;
	border-radius: 1px;
	width: 70%;
	color: #00608a;
	background-color: rgba(54, 172, 228, 0.3);
}

.alignHorizontal {
	display: flex;
	align-items: center;
	gap: utilities.$spacing3x;
}

.skeleton {
	height: 60px;
}

.skeletonContainer {
	box-shadow: 0 2px 10px -4px rgb(0 0 0 / 14%);
}

.tableContainer {
	background-color: utilities.$global-card-background-primary;
}

.rowStyle {
	height: 7vh;
}

.costTypeCell {
	text-align: end;
}

.paddedCell {
	padding-left: 2vw;
}

.verticalAlignTop {
	vertical-align: top;
}

.expiresSoon {
	color: "red";
	background-color: "red";
}

.inlineTextRight {
	margin-left: utilities.$spacing1x;
	margin-right: utilities.$spacing3x;
}
