@use "styles/utilities";

.wrapper {
	margin-bottom: 50px;
	max-width: utilities.$contentMaxWidth;
}

.extraMargin {
	margin-bottom: 150px;
}

.buttonContainer {
	text-align: right;
	@include utilities.md-down {
		margin-top: utilities.$spacing2x;
	}
}

.topSearchBar {
	justify-content: space-between;
	flex-wrap: nowrap;
	z-index: 100;
	min-height: fit-content;
	@include utilities.md-down {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		background-color: utilities.$table-head-primary;
	}
	position: sticky;
	top: 0;
	background-color: #fff;
	padding: utilities.$spacing3x;
}

.toolbarShadow {
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
	transition: box-shadow 0.2s ease-in-out;
}

.searchFieldContainer {
	width: 392px;
	@include utilities.md-down {
		width: 100%;
		display: flex;
		justify-content: center;
	}
}

.filters {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: utilities.$spacing2x;
	@include utilities.md-down {
		align-items: center;
		flex-direction: column;
		width: 100%;
	}
}

.filtersAndButtonContainer {
	display: flex;
	flex-grow: 1;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	@include utilities.md-down {
		flex-direction: column;
	}
}

.tableWrapper {
	@include utilities.md-down {
		padding-top: utilities.$spacing2x !important;
	}
}

.downloadIcon {
	color: utilities.$link;
	font-size: 18px;
	margin-top: 4px;
}

.exportButton {
	margin-right: utilities.$spacing2x;
	@include utilities.md-up {
		margin-left: auto;
	}
}

.buttons {
	display: flex;
	flex-direction: row;
	@include utilities.md-down {
		margin-top: utilities.$spacing2x;
		gap: utilities.$spacing2x;
	}
}
