@use "styles/utilities";
@use "sass:color";

.dialogTitle {
	position: relative;
}

.dialogContent {
	height: 500px;
}

.subTitle {
	color: utilities.$text-accent;
	margin-bottom: 16px;
}

.riskLevelHigh {
	background: utilities.$error-background;
	width: 72px;
	height: 25px;
}

.riskLevelMedium {
	background: utilities.$warning-background;
	width: 72px;
	height: 25px;
}

.riskLevelNone {
	background: utilities.$success-background;
	width: 72px;
	height: 25px;
}

.closeButton {
	position: absolute;
	right: 0;
	top: utilities.$spacing1x;
	color: utilities.$text-accent;
}
