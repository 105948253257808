@use "styles/utilities";

.textField {
	div {
		background: utilities.$global-card-background-primary;
	}
	font-size: 15px;
	min-height: 50px;
}

.unchecked {
	color: #909090;
	margin-right: 8px;
}

.checked {
	cursor: default !important;
	margin-right: 8px;
	color: utilities.$yellowTint1;
}

.selected {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	gap: utilities.$spacing1x;
}

.selectedSection {
	min-height: 90px;
	margin-top: utilities.$spacing1x;
}

.noneSelected {
	display: flex;
	flex-grow: 1;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	p {
		opacity: 0.5;
		font-size: 14px;
	}
}

.dropDownItem {
	font-size: 12px;
	cursor: pointer;
}

.dropDownOption {
	width: 100%;
	&:hover {
		background: utilities.$global-card-background-secondary;
	}
	display: flex;
	flex-direction: row;
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.groupDropdownItem {
	cursor: pointer;
	display: flex;
	flex-direction: row;
	padding-bottom: 8px;
}

.groupDropdownItemText {
	margin-top: 12px;
	display: flex;
	flex-direction: column;
	width: fit-content;
	max-width: 85%;
}

.licensesInGroupText {
	margin-top: 3px;
	color: utilities.$grey400;
}

.selectLicenseContainer {
	gap: utilities.$spacing1x;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.loading {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	min-height: 50px;
}

.conflictingLicenseAssigments {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: utilities.$spacing2x;
	border: 1px solid utilities.$border-color-heavy;
	padding: 12px 16px;
	border-radius: 4px;
	width: fit-content;
}

.warningIcon {
	background-color: utilities.$yellowTint4;
	color: utilities.$yellowDark;
	padding: 4px;
	border-radius: 4px;
	font-size: 30px;
}

.tooltiptext {
	color: #fff;
}
