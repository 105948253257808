@use "styles/utilities";

.tableRowHover {
	cursor: pointer;
}

.tableRow {
	height: 57px;
}

.noItemsCell {
	border-bottom: none;
}

.tableHead {
	@include utilities.responsiveTableHeader();
}

.tableContainer {
	@include utilities.responsiveTableContainer();
	@include utilities.lg-down {
		width: calc(100vw - 2 * utilities.$spacing3x);
	}
	background-color: utilities.$global-card-background-primary;
}

.chipContainer {
	color: utilities.$primary;
	background-color: transparent;
	border: 1px solid utilities.$secondary;
	padding: 4px 8px;
	align-items: center;
	border-radius: 8px;
	font-weight: 500;
	font-size: 13px;
	max-width: fit-content;
}

.contentTop {
	display: flex;
	align-items: flex-start;
	border: 1px solid red;
}

.licenseGrid {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	gap: 8px;
}

.cellTopAlign {
	vertical-align: top;
}
