@use "styles/utilities";

.checkboxContainer {
	border: 1px solid utilities.$border-color;
	padding: utilities.$spacing1x;
	margin-left: 0;
	width: 100%;
	border-radius: 4;
}

.noAvailableLicenses {
	color: utilities.$error;
	font-weight: 500;
}

.tableCellName {
	width: 200px;
}

.otherCellheader {
	width: 120px;
}

.commitmentCell {
	width: 250px;
}

.infoIcon {
	color: utilities.$info;
}

.tableCell {
	vertical-align: top;
	padding-top: 25px;
}

.infoIcon {
	color: utilities.$info;
	cursor: pointer;
}

.tableContainer {
	@include utilities.responsiveTableContainer();
}

.tableHead {
	@include utilities.responsiveTableHeader();
}
