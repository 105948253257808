@use "styles/utilities";

$updatesCardHeight: 80vh;

.greenStatus {
	border-color: utilities.$success !important;
}

.redStatus {
	border-color: utilities.$error !important;
}

.orangeStatus {
	border-color: utilities.$warning !important;
}

.greenTextStatus {
	color: utilities.$success;
}

.redTextStatus {
	color: utilities.$error;
}
