@use "styles/utilities";

.cardSize {
	height: 420px;
	min-width: 420px;
	width: 420px;
	@include utilities.sm-down {
		min-width: 100%;
		width: 100%;
	}
}

.card {
	@extend .cardSize;
	position: relative;
	overflow: hidden;
	border-radius: 4px;
	border: 1px solid #dfdfdf;
	background-color: utilities.$global-card-background-primary;

	.ribbonContainer {
		position: absolute;
		top: 20px;
		right: -70px;
		display: flex;
		width: 200px;
		transform: rotate(-135deg);
		padding: 9px 16px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		background: rgba(0, 96, 138, 0.21);
	}

	.ribbonText {
		rotate: 180deg;
		margin-left: 12px;
	}
}

.container {
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	padding: utilities.$spacing3x;
}

.addToCartButton {
	width: 55%;
}

.buttonContainer {
	justify-content: space-between;
	gap: utilities.$spacing3x;
}

.topItemsContainer {
	flex-direction: column;
}

$description-text-color: utilities.$greyTint3;

.greyTextColor {
	color: $description-text-color;
}

.headerContainer {
	max-width: 80%;
}

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.bundleName {
	margin-bottom: 4px;
	display: block;
	@extend .truncate;
}

.bundleDescription {
	display: block;
	margin-bottom: 4px;
	min-height: 14px;
	@extend .truncate;
	@extend .greyTextColor;
}

.bundleListItem {
	margin-left: -18px;
	list-style-type: none;
	display: flex;
	align-items: center;
	gap: utilities.$spacing2x;
	padding: utilities.$spacing1x 0;
}

.bundleItemsContainer {
	flex-direction: column;
	ul {
		padding-left: 18px;
		margin-top: 0;
		margin-bottom: utilities.$spacing1x;
		li {
			&::marker {
				// TODO: Make smaller to comply with design
				color: $description-text-color;
				//font-size: 50%;
			}
		}
	}
}

.buttonContent {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.editButtonContainer {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 10px;
	flex-wrap: nowrap;
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.productInfo {
	flex-direction: column;
}

.outOfStockText {
	color: utilities.$error !important;
}

.priceContainer {
	padding-top: utilities.$spacing1x;
	justify-content: flex-end;

	.priceWrapper {
		justify-content: flex-end;
		align-items: flex-end;
		gap: utilities.$spacing1x;
		flex-wrap: nowrap;
		// .priceText {
		// }
		.currencyText {
			padding-bottom: 4px;
		}
	}
}

.bottomItemsContainer {
	gap: utilities.$spacing3x;
	position: absolute;
	bottom: utilities.$spacing3x;
	left: utilities.$spacing3x;
	width: 88%;
}

.availabilityIcon {
	width: 16px;
}

.stockSkeleton {
	height: 16px;
	width: 50%;
}

.skeleton {
	@extend .cardSize;
}

// Add new bundle card styles
.addNewCard {
	@extend .cardSize;
	position: relative;
	overflow: hidden;
	border-radius: 4px;
	background-color: utilities.$global-card-background-primary;
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23DFDFDFFF' stroke-width='4' stroke-dasharray='14%2c14' stroke-dashoffset='100' stroke-linecap='square'/%3e%3c/svg%3e");
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	&:hover {
		cursor: pointer;
		background-image: none;
		background-color: #fafafa;
		transition-delay: 0.2s;
		transition: background-color 0.5s, background-image 0.5s, box-shadow 0.5s;
	}
}

.addNewText {
	color: rgba(111, 111, 111, 1);
	margin-top: utilities.$spacing1x;
}

.makeBiggerOnHover {
	transform: scale(1.05);
	transition-duration: 0.5s;
}

.infoContainer {
	display: flex;
	margin-top: 4px;
	align-items: center;
	z-index: 5;
	cursor: default;
}

.infoIcon {
	color: utilities.$text-accent;
	font-size: 16px;
	margin-right: 6px;
}

.tooltipList {
	margin-left: -26px;
}

.hidden {
	display: none;
}
