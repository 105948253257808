@use "styles/utilities";

.linkText {
	vertical-align: text-bottom;
	margin-right: 4px;
	&:hover {
		text-decoration: underline;
	}
}

.progress {
	margin-left: 4px;
	max-width: 115px;
}
