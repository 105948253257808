@use "styles/utilities";

.costChartDashboard {
	width: 90%;
	height: 300px;
	padding-left: utilities.$spacing3x;
	padding-right: utilities.$spacing3x;
}

.center {
	display: flex;
	justify-content: center;
}

.valueColorBlock {
	width: utilities.$spacing2x;
	height: 15px;
	display: inline-block;
	margin-right: utilities.$spacing1x;
}

.legendContainer {
	padding: utilities.$spacing3x;
}

.currentPeriodCost {
	background-color: utilities.$yellowPrimary;
}

.currentPeriodForecast {
	background-color: utilities.$yellowTint4;
}

.previousPeriod {
	background-color: utilities.$blueDark;
}

.inlineAlign {
	display: flex;
	justify-content: space-between;
}
