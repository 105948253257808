@use "styles/utilities";

.container {
	height: 100%;
	border: 0 1px 1px 1px solid utilities.$border-color;
	border-radius: 4px;
}

.titleContainer {
	border-radius: 4px 4px 0 0;
	background-color: utilities.$brownTint3;
	padding: utilities.$spacing2x;
}

.numberIndicatorContainer {
	display: flex;
	align-items: center;
}

.statsContainer {
	display: flex;
	padding-top: utilities.$spacing3x;
}

.valueColorBlock {
	width: utilities.$spacing2x;
	height: 15px;
	display: inline-block;
	margin-right: utilities.$spacing1x;
}

.labelTextStyle {
	font-size: 12px;
	font-weight: 400;
}

.compliant {
	background-color: utilities.$blueTint2;
}

.nonCompliant {
	background-color: utilities.$yellowTint2;
}

.notAvailable {
	background-color: utilities.$brownTint1;
}

.numberIndicatorContainer {
	display: flex;
	align-items: center;
}

.chartAndLegendContainer {
	padding: utilities.$spacing2x utilities.$spacing3x 0 utilities.$spacing3x;
}

.numberIndicator {
	font-weight: 500;
}

.fontWeight500 {
	font-weight: 500;
}
