@use "styles/utilities";

.disabledLabel {
	color: utilities.$grey200;
}

.tooltip {
	padding: 0;
	margin-left: utilities.$spacing1x;
	:global(.MuiSvgIcon-root) {
		font-size: 16px;
	}
}
