@use "styles/utilities";

.text {
	display: flex;
	justify-content: center;
	margin-top: 16px;
	font-weight: 500;
	margin-right: 210px;
}

.environmentBanner {
	height: 50px;
	position: absolute;
	z-index: 1300;
	max-width: 500px;
	top: 0;
	right: 0;
	color: #fff;
	margin-top: 150px;
	transform: rotate(45deg) translate(50%, -50%);
	transform-origin: top right;
	@include utilities.sm-down {
		display: none;
	}
}

.demoBanner {
	@extend .environmentBanner;
	width: 120%;
	background-color: #28a745;
}

.testBanner {
	@extend .environmentBanner;
	width: 110%;
	background-color: utilities.$error;
}

.hide {
	display: none;
}
