@use "styles/utilities";

.shoppingCartIcon {
	font-size: 14px;
}

.infoIcon {
	font-size: 14px;
	color: #fff;
}

.newSubscriptionText {
	color: #fff;
	font-weight: 500;
}

$currentMarginLeft: 60px;

.noneSelected {
	p {
		opacity: 0.5;
		font-size: 14px;
	}
	min-height: 50px;
	margin-left: $currentMarginLeft;
}

.hidden {
	visibility: hidden;
}

.marginLeft {
	margin-left: $currentMarginLeft;
}
