@use "styles/utilities";

.signInLabel {
	padding: 10px;
	border-radius: 8px;
	font-weight: 500;
	white-space: nowrap;
	text-align: center;
	width: 150px;
}

.disabledOrNeverSignedIn {
	@extend .signInLabel;
	background-color: rgba(174, 174, 174, 0.16);
	color: utilities.$text-primary;
}

.inactive {
	@extend .signInLabel;
	background-color: rgba(192, 57, 43, 0.12);
	color: #c0392b;
	font-weight: 500;
}

.active {
	@extend .signInLabel;
	background-color: rgba(39, 174, 96, 0.12);
	color: #27ae60;
}

.hidden {
	display: none;
}
