@use "styles/utilities";

.buttonContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	@include utilities.md-down {
		margin-left: 8px;
		gap: utilities.$spacing2x;
	}
}

.warningIcon {
	color: utilities.$yellowDark;
	background-color: utilities.$yellowTint4;
	height: 32px;
	width: 32px;
	border-radius: 8px;
	padding: 6px;
}

.warningButton {
	width: 380px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: #fff;
	&:hover {
		background-color: #fff !important;
	}
}

.leftButtonContent {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	width: 75%;
}

.skeleton {
	width: 20px;
	height: 24px;
	margin-bottom: 1px;
}

.rightButtonContent {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	width: 25%;
}

.arrowIcon {
	margin-left: 4px;
}

.assignButton {
	svg {
		margin-top: 2px;
	}
}
