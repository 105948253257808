@use "styles/utilities";

.tableHead {
	@include utilities.responsiveTableHeader();
}

.tableContainer {
	background-color: utilities.$global-card-background-primary;
	@include utilities.responsiveTableContainer();
}

.groupLabel {
	background-color: utilities.$blueLicenseGroup;
	padding: 6px 12px;
	border-radius: 4px;
	min-width: fit-content;
	white-space: nowrap;
}

.errorState {
	color: utilities.$error;
}
