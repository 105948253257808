@forward "../common.module";

.container {
	margin: 12px;
	padding: 10px 15px;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	1% {
		opacity: 0;
		visibility: visible;
	}
	100% {
		opacity: 1;
		visibility: visible;
	}
}

.serviceStatusItem {
	visibility: hidden;
	animation-name: fadeIn;
	animation-duration: 0.5s;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
}

.serviceStatusItem:nth-child(2) {
	animation-delay: 0.1s;
}

.serviceStatusItem:nth-child(3) {
	animation-delay: 0.2s;
}

.serviceStatusItem:nth-child(4) {
	animation-delay: 0.3s;
}

.serviceStatusItem:nth-child(5) {
	animation-delay: 0.4s;
}
