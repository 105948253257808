@use "styles/utilities";

.allProducts {
	width: 100%;
	margin-left: utilities.$spacing3x;
	@include utilities.md-down {
		margin-left: 0;
		padding-right: 0;
	}
}

.skeleton {
	transform: none;
}
