@use "styles/utilities";

.selectedItemChip {
	color: utilities.$greyTint4;
	background-color: utilities.$greyTint2;
	border-radius: 4px;
	width: fit-content;
	height: fit-content;
	padding: 4px 4px 4px 12px;
	display: flex;
	align-items: center;
	gap: 4px;
}

.closeIcon {
	font-size: 12px;
	color: utilities.$text-accent;
}

.groupStyle {
	background-color: utilities.$blueLicenseGroup;
}
