@use "styles/utilities";

.totalSavings {
	display: inline;
	margin-left: utilities.$spacing2x;
	font-weight: 600;
	text-align: start;
	overflow: hidden;
}

.subText {
	min-height: 24px;
}

.calendarIcon {
	border-radius: 8px;
	padding: 6px;
	font-size: 45px;
	background-color: utilities.$greenTint1;
}
