@use "styles/utilities";

.clickable {
	cursor: pointer;
}
.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: utilities.$spacing3x;
	background-color: utilities.$table-header-background;
	max-height: 60px;
}

.cartItemQuantity {
	& > :global(.MuiInputBase-root) {
		height: 30px;
		width: 50px;
		font-size: 16px;
	}

	:global(.MuiInputBase-input) {
		text-align: center;
	}
}

.priceContainer {
	width: 100%;
}

.summaryCartContainer {
	height: 100%;
	border-radius: 4px;
	background-color: utilities.$global-card-background-primary;
	flex-wrap: nowrap;
}

.cartProductsContainer {
	padding-left: utilities.$spacing3x;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: scroll;
	scrollbar-width: none;

	@include utilities.md-up {
		max-height: 500px;
	}
}

.summaryCartContent {
	padding-left: utilities.$spacing3x;
	padding-right: utilities.$spacing3x;
}

.discountText {
	background-color: #00608a45;
	border-radius: 4px;
	padding: 4px 4px;
}

.subtotalText {
	padding-right: 4px;
}

.shippingCostText {
	padding-right: 4px;
}

.freeShippingText {
	color: utilities.$success;
	font-weight: 500;
	padding-right: 4px;
}
