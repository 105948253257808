@use "styles/utilities";

@mixin linkstyle {
	color: utilities.$link;
	&:hover {
		cursor: pointer;
	}
}

@mixin linkContentstyle {
	@include linkstyle;
	display: flex;
	gap: 6px;
	align-items: center;
}

.externalLinkContent {
	@include linkContentstyle;
	.externalLinkIcon {
		font-size: 15px;
		margin-top: 3px;
	}
}

.externalLinkContentPrefixedExternalLinkIcon {
	@include linkContentstyle;
	.externalLinkIcon {
		order: -1;
		font-size: 15px;
	}
}

.internalLinkContent {
	@include linkContentstyle;
	.externalLinkIcon {
		display: none;
	}
}

.linkText,
.linkContentContainer {
	@include linkstyle;
}
