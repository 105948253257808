@use "styles/utilities";

.listItemText {
	font-size: 14px;
	font-weight: 400;
	color: #555555;
	text-transform: none;
	margin-left: 32px;
}

.listItemWithIconText {
	margin-left: 7px;
}

.listItemHighlightedText {
	color: utilities.$text-primary;
}

.listItemRoot {
	width: 92%;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	height: 37px;
	white-space: nowrap;
	& > :global(.MuiListItemIcon-root) {
		min-width: 33px;
	}
}

.listItemHighlighted {
	background-color: #faecc0 !important;
	color: utilities.$text-primary;

	&:hover {
		background-color: utilities.$accent;
	}

	& > :global(.MuiListItemIcon-root) {
		color: utilities.$text-primary;
	}
}

.popper {
	background-color: rgba(utilities.$yellowPrimary, 0.5);
	color: utilities.$text-primary;
}

.notificationCircle {
	margin-left: 15px;
	background-color: utilities.$yellowPrimary;
	border-radius: 50%;
	width: 22px;
	height: 22px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
}

.newLabel {
	color: utilities.$green;
	font-size: 20px;
	font-weight: 600;
	border-radius: 4px;
	padding: 2px 5px;
	font-size: 14px;
}

.icon {
	svg {
		fill: utilities.$grey200;
	}
}

.highlightedIcon {
	svg {
		fill: utilities.$yellowPrimary;
	}
}
