.listSubHeaderContainer {
	display: flex;
	align-items: center;
	background-color: transparent; /* Set background to transparent */
}

.subHeaderText {
	font-size: 12px;
	font-weight: 500;
	color: #8f8f8f;
	letter-spacing: 0.2px;
}
