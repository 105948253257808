@use "styles/utilities";

.hidden {
	display: none;
}

.red {
	color: utilities.$error;
}
.green {
	color: utilities.$success;
}

.noDevicesCell {
	width: 100%;
	border: 0;
}

.activityText {
	font-size: inherit;
}

.deviceCard {
	padding: utilities.$spacing3x;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
	margin: utilities.$spacing2x 0 utilities.$spacing2x utilities.$spacing2x !important;
	border: 1px solid utilities.$border-color;
	border-radius: 4px;
	width: 100%;
}

.summary {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 95%;
	gap: utilities.$spacing1x;
}

.statusContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: utilities.$spacing1x;
	white-space: nowrap;
}

.osIcon {
	transform: scale(1.2);
}

.greenLabel {
	background-color: utilities.$success;
	color: utilities.$text-secondary;
	padding: 6px 12px;
	border-radius: 4px;
	font-weight: 500;
}

.redLabel {
	background-color: utilities.$error;
	color: utilities.$text-secondary;
	padding: 6px 12px;
	border-radius: 4px;
	font-weight: 500;
}

.yellowLabel {
	background-color: #e6a732;
	color: #fff;
	padding: 6px 12px;
	border-radius: 4px;
	font-weight: 500;
}

.deviceNameContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	svg {
		color: utilities.$text-accent;
	}
}

.skeletonDeviceCard {
	@extend .deviceCard;
	margin-top: -150px;
}

.deviceInfo {
	display: flex;
	flex-direction: column;
	width: 90%;
	margin-top: utilities.$spacing3x;
}

.deviceInfoRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: utilities.$spacing2x;
}

.detailSection {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 50%;
	gap: 2px;
}

.activityInfo {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
}
