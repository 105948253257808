@use "styles/utilities";

.exMvaMargins {
	margin-top: 2px;
	color: utilities.$text-accent;
	margin-left: 4px;
	@include utilities.sm-down {
		margin-top: 0;
	}
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;
}

.discountedPrice {
	text-decoration: line-through;
	font-size: 14px;
	margin-left: -2px;
}

.discountCircle {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background-color: utilities.$discountRed;
	display: flex;
	justify-content: center;
	align-items: center;
	@include utilities.sm-down {
		right: -40px;
	}
}

.discountText {
	color: utilities.$text-secondary;
	font-size: 14px;
	font-weight: 700;
}
