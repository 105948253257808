@use "styles/utilities";

.container {
	width: 100%;
	display: flex;
	justify-content: center;
	gap: utilities.$spacing3x;
}

.titleContainer {
	padding-top: utilities.$spacing3x;
	width: 530px;
	height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.numberContainer {
	border-radius: 50%;
	width: 20px;
	height: 20px;
	margin-right: utilities.$spacing2x;
	background-color: utilities.$accent;
	display: flex;
	justify-content: center;
	align-items: center;
	p {
		font-size: 14px;
		color: #fff;
		font-weight: 600;
	}
}

.purchaseSectionNumberContainer {
	@extend .numberContainer;
	margin-left: utilities.$spacing3x;
}

.leftSideSpacer {
	width: 15%;
	@include utilities.xl-down {
		display: none;
	}
}

.mainContentContainer {
	width: 530px;
	@include utilities.lg-down {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 0 utilities.$spacing1x;
	}
}

.mainContent {
	gap: utilities.$spacing1x;
	max-width: 530px;
}

.summaryContainer {
	max-width: 530px;
	// Sorry about this value, it's the current width where the summary container drops down beneath the main content container
	@media screen and (min-width: 1319px) {
		margin-top: calc(utilities.$spacing1x + 100px);
	}
	@include utilities.lg-down {
		width: 100%;
		display: flex;
		justify-content: center;
	}
}

.summary {
	border: 1px solid utilities.$border-color-light;
	padding: utilities.$spacing3x;
	border-radius: 4px;
	background-color: #fbfbfb;
	width: 500px;
	@include utilities.md-down {
		max-width: 530px;
	}
}

.stickyContainer {
	position: sticky;
	top: utilities.$spacing3x;
}

.activeBreadcrumb {
	color: utilities.$yellowPrimary;
	font-weight: bold;
}

.breadcrumbs {
	padding: utilities.$spacing1x 0;
}

.firstBreadcrumb {
	color: utilities.$text-accent;
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}

.sectionHeaderAndContentContainer {
	background-color: utilities.$global-card-background-primary;
	border-radius: 4px;
	padding: utilities.$spacing3x;
	display: flex;
	flex-direction: row;
}

.purchaseSectionHeaderAndContentContainer {
	@extend .sectionHeaderAndContentContainer;
	padding: utilities.$spacing3x 0 !important;
}

.actionsContainer {
	@include utilities.xl-down {
		margin-bottom: utilities.$spacing4x;
	}
	@include utilities.xl-up {
		margin-bottom: 100px;
	}
}

.purchaseLicenseSummaryContainer {
	display: flex;
	flex-direction: column;
	margin-top: utilities.$spacing3x;
	margin-bottom: utilities.$spacing3x;
}

.purchaseLicenseSummaryItem {
	gap: 4px;
}

.emailInput {
	background-color: #fff;
	width: 60%;
}
