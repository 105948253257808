@use "styles/utilities";

.purchaseLicenseCard {
	padding: 0 0 32px 0;
	display: flex;
	flex-direction: row;
	padding-left: 60px;
	border-bottom: 1px solid utilities.$border-color;
	width: 100%;
	&:last-child {
		border-bottom: none;
	}
}

.marginRight {
	margin-right: 32px;
}

.commitmentItem {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: utilities.$spacing1x;
	border: 1px solid utilities.$border-color-light;
	border-radius: 4px;
	padding: utilities.$spacing3x 38px;
	&:hover {
		cursor: pointer;
	}
	@include utilities.sm-down {
		padding: utilities.$spacing2x;
	}
}

.selectedItem {
	background-color: utilities.$greyTint4;
	border: 1px solid utilities.$border-color-heavy;
}

.notAvailable {
	pointer-events: none;
	display: none;
}

.newSubscriptionLabel {
	position: absolute;
	top: -12px;
	left: -8px;
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 4px;
	padding: 4px 8px;
	border-radius: 4px;
	background-color: utilities.$grey200;
}

.selectedLabel {
	background-color: utilities.$yellowDark !important;
}

.hidden {
	visibility: hidden;
}

.licensesToBePurchasedInfo {
	background-color: utilities.$greyTint2;
	padding: 4px 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	margin: utilities.$spacing1x 0;
	width: fit-content;
}

.commitmentDurationSelector {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-top: utilities.$spacing2x;
	@include utilities.sm-down {
		gap: utilities.$spacing2x;
	}
}
