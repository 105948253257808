@use "styles/utilities";

.infoIcon {
	color: utilities.$info;
	cursor: pointer;
}

.tooltip {
	:global(.MuiSvgIcon-root) {
		font-size: 16px;
	}
}

.contentContainer {
	display: flex;
	flex-direction: row;
	background-color: utilities.$global-card-background-primary;
	padding: utilities.$spacing3x;
	border-radius: 4px;
	margin-bottom: utilities.$spacing3x;
}

.field {
	width: 70%;
	margin-top: utilities.$spacing3x;
}

.footer {
	position: fixed;
	bottom: 0;
	height: 100px;
	background-color: utilities.$global-card-background-primary;
	margin-left: calc(-1 * utilities.$spacing3x);
	max-width: 1100px;
	display: flex;
	align-items: center;
	padding: utilities.$spacing3x;
	box-shadow: 0px -5px 6px 0px #00000012;
}
