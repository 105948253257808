@use "styles/utilities";

.container {
	@include utilities.centralizedWithMaxWidth();
}

.wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.mfaChart {
	width: 100%;
	min-width: 250px;
	max-width: 400px;
	height: 38vh;
	@include utilities.md-down {
		width: 70vw;
	}
}

.signInChart {
	height: 24vh;
}

.deviceChart {
	height: 22vh;
	width: inherit;
}

.paper {
	height: 100%;
}

.clickable {
	cursor: pointer;

	&:hover {
		box-shadow: 0px 4px 10px utilities.$border-color-heavy;
		transition-duration: 300ms;
	}
}

.riskySignIns {
	@include utilities.xl-down {
		order: -1;
		@include utilities.fullScreenFlex(utilities.$spacing2x);
	}
}

.cards {
	@include utilities.xl-down {
		@include utilities.fullScreenFlex(utilities.$spacing2x);
	}
}

.mfaStatisticsWrapper {
	@include utilities.md-down {
		@include utilities.fullScreenFlex(utilities.$spacing2x);
	}
}

.signInsDeviceStatisticsWrapper {
	flex-wrap: nowrap;
	@include utilities.md-down {
		flex-direction: column;
		@include utilities.fullScreenFlex(utilities.$spacing2x);
	}
}

.hide {
	visibility: hidden;
}
