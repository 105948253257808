@use "styles/utilities";

.receiptHeader {
	width: fit-content;
}

.receiptCartContainer {
	max-width: 700px;
}

.receiptUserContainer {
	max-width: 700px;
	border: 1px solid #eeeeee;
	border-radius: 8px;
}

.onboardedText {
	width: 130px;
}

.errorContainer {
	background: #ffeeef;
	border-radius: 4px;
}
