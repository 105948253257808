@use "styles/utilities";

.infoIcon {
	position: absolute;
	right: utilities.$spacing2x;
	top: utilities.$spacing2x;
	font-size: large;
	color: utilities.$text-accent;
}

.hoverTitle {
	font-weight: 600;
	font-size: 14px;
	margin-bottom: -8px;
}

.hoverList {
	margin-left: -16px;
	margin-top: 0px;
}
