@use "styles/utilities";
@use "./common.module";

.container {
	margin-top: 20px;
	box-shadow: none;
	background-color: utilities.$global-background-primary;
}

.wrapper {
	@include utilities.centralizedWithMaxWidth();
}

.updatesCardListContainer {
	overflow-y: auto;
	height: calc(common.$updatesCardHeight - 164px);
	@include utilities.md-down {
		height: fit-content !important;
		li:last-child {
			border-bottom: none;
		}
	}
}

.serviceStatusTitle {
	margin-top: 8px;
}

.linkFooterContainer {
	background-color: utilities.$global-card-background-primary;
	display: flex;
	justify-content: center;
	padding-bottom: utilities.$spacing2x;
	overflow: hidden;
	white-space: nowrap;
}

.linkIcon {
	font-size: inherit;
}

.linkText {
	vertical-align: text-bottom;
	margin-left: 4px;
}

.noIncidentsContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: utilities.$success;
}

.updatesCardContainer {
	overflow: hidden;
	@include utilities.md-down {
		max-width: 100%;
	}

	@include utilities.md-down {
		& p {
			word-break: break-word;
		}
	}
}

.gridStatusesContainer {
	@include utilities.md-down {
		max-width: 100%;
	}
}

.dashboardContainer {
	flex-wrap: nowrap;
	@include utilities.md-down {
		flex-direction: column;
		max-width: 600px;
	}
}

.securityIncidentSkeletonWrapper {
	max-width: 80vw;
	overflow: hidden;
	@include utilities.md-down {
		max-height: 150px;
	}
}
