@use "styles/utilities";

.formContainer {
	padding: utilities.$spacing4x;
	border-radius: 4px;
	background: white;
	width: 700px;
	@include utilities.sm-down {
		width: 100%;
	}
	@include utilities.lg-up {
		width: 50%;
		padding-right: 100px;
	}
	@include utilities.lg-down {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: utilities.$spacing4x;
	}
}

.orderSummaryContainer {
	position: relative;
	width: 50%;
	min-height: 500px;
	padding: 0 utilities.$spacing3x;
	@include utilities.lg-down {
		margin-left: auto;
		margin-right: auto;
		width: 700px;
		padding: 0;
	}
	@include utilities.sm-down {
		width: 100%;
	}
}

.createBundleFromCartButton {
	position: absolute;
	bottom: utilities.$spacing4x;
	right: utilities.$spacing3x + utilities.$spacing3x;
	&:hover {
		.heartIcon {
			color: utilities.$error;
			animation: fadeincolor 0.2s ease-in-out;
		}
	}
}

@keyframes fadeincolor {
	0% {
		color: utilities.$text-accent;
	}
	100% {
		color: utilities.$error;
	}
}

.heartIcon {
	&:hover {
		color: utilities.$error;
	}
}

.inputContainer {
	display: flex;
	flex-direction: row;
	width: 50%;
	&:nth-child(2) {
		padding-left: utilities.$spacing3x;
	}
	&:last-child {
		width: 100%;
	}
}

.header {
	border-bottom: 1px solid utilities.$border-color-heavy;
	padding-bottom: utilities.$spacing1x;
	margin-bottom: utilities.$spacing2x;
	align-items: center;
	gap: utilities.$spacing2x;
}

.submitDialog {
	height: auto;
	padding-top: 300px;
}

.breadcrumb {
	cursor: pointer;
}

.breadcrumb:hover {
	text-decoration: underline;
}

.alert {
	width: 400px;
	height: 80px;
}

.approverMargin {
	margin-bottom: 15px;
}

.fixedHeight {
	margin-top: utilities.$spacing2x;
	height: 70px;
	display: flex;
	align-items: center;
	width: 100%;
}

.hidden {
	height: 20px;
	visibility: hidden;
}

.approverContainer {
	width: 100%;
}

.noApproverStyle {
	color: utilities.$error;
}

.approverSkeleton {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: utilities.$spacing1x;
	margin-bottom: -8px;
	margin-top: -8px;
}

.orderFailedContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	gap: utilities.$spacing2x;
	max-width: 640px;
	margin: 0 auto;
	text-align: center;
	h2 {
		font-weight: 500;
	}
}
