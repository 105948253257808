.formTextInput {
	font-size: 16px;
	font-weight: 400;
}

.input {
	height: 75px;
}

.select {
	padding-top: 10px;
	padding-left: 10px;
}

.errorText {
	:global(.MuiFormHelperText-root) {
		margin-left: 0px;
		width: max-content;
		height: 100%;
	}
}

.tooltip {
	padding: 3px;

	:global(.MuiSvgIcon-root) {
		font-size: 16px;
	}
}
