@use "styles/utilities";

.checkbox:hover {
	background-color: transparent !important;
}

.checkbox {
	color: #909090;
}

.checkboxChecked {
	color: utilities.$yellowTint1;
}
