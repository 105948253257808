@use "styles/utilities";

.content {
	@include utilities.xl-up {
		width: 48%;
	}
	@include utilities.xl-down {
		width: 100%;
		max-width: 800px;
	}
	box-shadow: 0px 4px 7px 0px #0000000d;
	border-radius: 8px;
}
