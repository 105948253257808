@use "styles/utilities";

.avatarSectionContainer {
	padding: utilities.$spacing3x;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: utilities.$spacing1x;
	height: 80px;
	cursor: pointer;
	overflow: hidden;
}

.ironstoneLogo {
	width: 24px;
	height: auto;
}

.toolTip {
	max-width: 430px;
	padding: 10px;
	margin: 0;
}

.toolTipText {
	cursor: pointer;
}

.userContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex-grow: 1;
	padding-left: utilities.$spacing1x;
	padding-top: 2px;
	position: relative;
	gap: 5px;
}

.name {
	font-weight: 500;
	color: utilities.$text-accent;
}

.userContainer::before {
	content: "";
	position: absolute;
	left: 0;
	top: 3px;
	bottom: 0px;
	border-left: 1px solid utilities.$border-color-heavy;
	width: 0;
}

.profileDrawerList {
	z-index: 1300;
	background-color: white;
	height: 140px;
	position: absolute;
	padding-top: utilities.$spacing3x;
	gap: utilities.$spacing1x;
	border-bottom: 2px solid utilities.$yellowPrimary;
	width: 100%;
}
