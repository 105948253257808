@use "styles/utilities";

.select {
	width: 90px;
	max-height: 35px;
}

.refundableCount {
	color: utilities.$success;
	display: flex;
}

.menuItem {
	gap: 8px;
}
