@use "styles/utilities";

.workspaceTitle {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-left: utilities.$spacing2x;
}

.container {
	padding-right: 3 * utilities.$spacing2x;
}

.paper {
	display: flex;
	align-items: center;
	border: 1px solid utilities.$border-color;
	border-radius: 3px;
	margin-top: 10px;
	margin-bottom: 10px;
	height: 100px;
	background-color: utilities.$global-card-background-primary;
}
