@use "styles/utilities";

.downloadIcon {
	font-size: inherit;
	display: inline;
	vertical-align: sub;
	color: utilities.$link;
}

.downloadIconWrapper {
	padding: 0 1px 0px 0;
}

.exportToExcelContainer {
	flex-direction: column;
	position: relative;
}

.exportToExcelTitle {
	font-size: 14px;
	font-weight: 500;
	color: utilities.$text-primary;
}

.exportToExcel {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border: 1px solid utilities.$border-color;
	border-radius: 3px;
	padding: utilities.$spacing2x;
	&:hover {
		cursor: pointer;
	}
	gap: 8px;
}

.exportToExcelDialog {
	position: absolute;
	flex-direction: row;
	padding: utilities.$spacing2x;
	top: 100%;
	left: 0;
	right: 0;
	z-index: 10;
	background-color: utilities.$global-card-background-primary;
	width: 1000;
	border: 1px solid utilities.$border-color;
}

.downloadField {
	display: flex;
	flex-direction: row;
	align-items: center;
	.downloadButtonText {
		padding-bottom: 1px;
	}
	&:hover {
		.downloadButtonText {
			text-decoration: underline;
		}
	}
}
