@forward "../common.module";
@use "styles/utilities";

.container {
	margin: 12px;
	padding: 0;
}

.incidentDateContainer {
	padding-top: 0 !important;
}

.listItem {
	box-sizing: content-box;
	padding: 0;
}

.subtitle {
	color: utilities.$text-accent;
}

.linkIcon {
	font-size: inherit;
}

.linkText {
	vertical-align: text-bottom;
	margin-left: 4px;
}

.button {
	border-radius: 3px;
	height: 40px;
	margin-right: 24px;
}

.buttonsContainer {
	padding-top: 24px !important;
	padding-bottom: 12px;
}
