@use "styles/utilities";

.padding {
	padding: utilities.$spacing2x;
	@include utilities.sm-down {
		padding: utilities.$spacing1x;
	}
}

.indicatorContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.clickable {
	cursor: pointer;
	&:hover {
		border-radius: 4px;
		background-color: utilities.$global-background-secondary;
		transition-duration: 300ms;
	}
}

.legendText {
	font-size: 14px;
}

.legendSubText {
	font-size: 14px;
	font-weight: 500;
	margin-top: -2px;
}

$legendIconSize: utilities.$spacing3x;
$spaceBetweenIconAndLegendText: 3px;

.legendIcon {
	width: $legendIconSize;
	height: $legendIconSize;
}

.standardLegend {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	gap: $spaceBetweenIconAndLegendText;
}

.componentLegend {
	width: 100%;
	display: flex;
}

.legendTextContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.mainLabelContainer {
	display: flex;
	height: $legendIconSize;
	align-items: center;
}

.mainLabelSkeleton {
	height: 18px;
	width: 40px;
}

.subLabelSkeleton {
	height: 18px;
	width: 20px;
}
