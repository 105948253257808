@use "styles/utilities";

.assignToUserContainer {
	gap: utilities.$spacing1x;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.selectedSection {
	min-height: 90px;
	margin-top: utilities.$spacing1x;
}

.selected {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	gap: utilities.$spacing1x;
}

.noneSelected {
	display: flex;
	flex-grow: 1;
	flex-direction: row;
	justify-content: flex-start;
	margin-top: utilities.$spacing1x;
	p {
		opacity: 0.5;
		font-size: 14px;
	}
}
