@use "styles/utilities";

.tableContainer {
	margin-bottom: 100px;
	@include utilities.responsiveTableContainer();
}

.tableHeader {
	background-color: utilities.$table-header-background;
	@include utilities.responsiveTableHeader();
}

.rowStyle {
	height: 7vh;
	background-color: utilities.$global-card-background-primary;
}

.linkIcon {
	margin-right: 4px;
}

.costTypeCell {
	text-align: end;
	padding-right: 50px;
}
