@use "styles/utilities";

.rowStyle {
	display: flex;
	justify-content: flex-start;
	padding-top: utilities.$spacing1x;
}

.footerCell {
	align-items: center;
	flex-direction: column;
	display: flex;
	background-color: utilities.$yellowTint4;
	padding: 14px utilities.$spacing2x;
	border-radius: 4px;
	gap: 4px;
}

.filteredResultsTitle {
	display: flex;
	align-items: center;
	width: fit-content;
	gap: 6px;
	margin-right: utilities.$spacing2x;
}

.summaryContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: utilities.$spacing4x;
}

.padding {
	max-width: utilities.$maxWidthWithPadding !important;
}

.tableFooter {
	z-index: 100;
	position: fixed;
	left: utilities.$drawerWidth + utilities.$spacing3x;
	right: utilities.$spacing3x;
	bottom: -100px;
	margin-left: auto;
	margin-right: auto;
	max-width: utilities.$contentMaxWidth;
	background-color: #fcfcfc;
	border: 1.6px solid utilities.$border-color;
	box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.1);
	padding: 0 0 0 utilities.$spacing3x;
	&.visible {
		bottom: 0;
	}
	transition: bottom 0.3s ease; // Add transition for smooth movement
	@include utilities.lg-down {
		left: utilities.$spacing3x;
	}

	@include utilities.md-down {
		left: 0;
		right: 0;
		overflow-x: auto;
	}
}

.buttonCell {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-grow: 1;
	margin-right: utilities.$spacing2x;
	gap: utilities.$spacing2x;
}

.downloadIcon {
	color: utilities.$link;
	font-size: 18px;
	margin-top: 4px;
}
