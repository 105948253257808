@use "styles/utilities";

.container {
	display: flex;
	align-items: center;

	& > svg {
		color: inherit;
		border-radius: 50%;
	}

	& > span:last-child {
		margin-left: 4px;
	}
}

.increaseTrend {
	color: utilities.$error;
	& > svg {
		// do note that rgba() funcion that accepts hex value
		// exists only as a global function inside SCSS.
		background-color: rgba(utilities.$error, 0.05);
	}
}

.decreaseTrend {
	color: utilities.$success;
	& > svg {
		background-color: rgba(utilities.$success, 0.05);
	}
}

.zeroTrend {
	display: none;
}
