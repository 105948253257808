@use "styles/utilities";

.stockDescriptionContainer {
	gap: utilities.$spacing1x;
	align-items: center;
	width: fit-content;
}

.expectedDateText {
	color: utilities.$grey700;
	white-space: nowrap;
}

.availabilityIcon {
	border-radius: 50%;
	width: 14px;
	height: 14px;
}

.absoluteContainer {
	position: absolute;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.inStock {
	color: utilities.$success;
}

.outOfStock {
	color: utilities.$error;
}

.orderedOnRequest {
	color: utilities.$blueDark;
}
