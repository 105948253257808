@use "styles/utilities";

.paper {
	padding-top: utilities.$spacing4x;
}
.middleColumn {
	background-color: #ebebeb63;
	padding: 30px 50px;
}
.sideBarPanel {
	max-width: 340px;
	padding: 30px;
}

.sideBarPanelText {
	font-size: 14px;
	padding-bottom: 20px;
}
.selectedOption {
	font-size: 14px;
}

.navBox {
	float: right;
}

.tabsRoot {
	border-right: 1px;
	border-color: "transparent";
	min-width: 220px;
	max-width: 300px;
	border: "none";
}

.selectedTab {
	background-color: #ebebeb63;
	color: #333333;
}

.tabIndicator {
	background-color: #ebebeb63;
}

.MuiTabs-indicator {
	display: none;
}

.tabsRoot.Mui-selected {
	background-color: red;
}

.formRow {
	margin-bottom: 30px;
}

.formLabel {
	font-size: 14px;
}

.boldLabel {
	font-weight: bold;
	font-size: 14px;
	display: inline;
}

.sectionRow {
	margin-bottom: 20px;
	margin-top: 20px;
}

.marginBottom20 {
	margin-bottom: 20px;
}

.workSpaceDetailsWrapper {
	margin-top: 20px;
}

.helpText {
	font-style: italic;
	color: grey;
	font-size: 12px;
}

.formLabelWrapper {
	width: 75px;
	margin-right: 50px;
	display: inline-block;
	font-size: 14px;
}

.formLabelWrapperWide {
	max-width: 400px;
	margin-right: 50px;
	margin-bottom: 10px;
	display: inline-block;
	font-size: 14px;
}

.navIcon {
	position: absolute;
	left: 5px;
	font-size: 2em;
}

.navTab {
	border: none;
	padding: utilities.$spacing2x;
	color: #333333;
}

.tabText {
	font-size: 0.85em;
	text-align: left;
	width: 100px;
}

.asterisk {
	color: #e52626;
	font-size: 16px;
	padding-left: 6px;
}

.submitBtn,
.nextBtn {
	margin-left: 25px;
	margin-top: 30px;
}

.dropDown {
	max-width: 300px;
	height: 30px;
	padding: 2px;
}

.paddedInput {
	font-size: 14px;
	padding: 0;
	padding: 15px 10px;
}

.input {
	font-size: 14px;
	max-width: 300px;
	padding: 0;
}

.multiLineInput {
	max-width: 300px;
	font-size: 14px;
	padding: 0;
	line-height: 20px;
}

.dropdownListBox {
	font-size: 14px;
}

.divider {
	margin-top: 15px;
}

.steps {
	font-size: 18px;
	width: 125px;
}
