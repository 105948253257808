@use "styles/utilities";

.header {
	height: 90px;
	top: 90px;

	@include utilities.md-down {
		height: fit-content;
		padding-bottom: utilities.$spacing1x;
	}
}

.headerContainer {
	padding: utilities.$spacing2x;
	align-items: center;
	background-color: white;
	justify-content: space-between;
	flex-wrap: nowrap;
	flex-direction: row;
	width: 100%;
	gap: utilities.$spacing2x;
	padding: 0 40px 0 utilities.$spacing3x;
	@include utilities.md-down {
		flex-direction: column;
		gap: utilities.$spacing2x;
		padding: utilities.$spacing2x;
		margin: 0 utilities.$spacing1x;
	}
	box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
}

.inputText {
	font-size: 16px;
}

.inputTextHeader {
	display: none;
}

.availability {
	width: 250px;
}

.search {
	display: flex;
	height: 60px;
	box-shadow: none;
	border: 1px solid utilities.$border-color-heavy;
	border-radius: 5px;
}

.searchWrapper {
	max-width: 550px;
	width: 100%;
	@include utilities.md-down {
		margin-left: 0;
		max-width: 100%;
	}
}

.badge {
	background-color: utilities.$primary-button-background-color;
}

.showForMediumScreens {
	display: none;
	@include utilities.md-down {
		display: flex;
	}
}

.filterAndsortContainer {
	gap: utilities.$spacing2x;
	flex-wrap: nowrap;
	flex-grow: 1;
	justify-content: space-between;
	@include utilities.md-down {
		justify-content: space-between;
		width: 100%;
	}
}

.filterButton {
	padding: 0 !important;
	min-width: fit-content;
	width: fit-content;
}

.onBoardingOffsett {
	top: 105px;
}
