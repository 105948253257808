@use "styles/utilities";

.awaitingyourreply {
	background-color: utilities.$awaitingReplyColor;
}

.beingprocessed {
	background-color: utilities.$beingProcessedColor;
}

.ticketresolved {
	background-color: utilities.$ticketResolvedColor;
}

.container {
	$size: 12px;

	display: inline-block;
	position: relative;
	height: $size;
	width: $size;
	border-radius: 50%;
	vertical-align: baseline;
}
