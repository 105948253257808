.dropDown {
	width: 300px;
	min-height: 30px;
}

.dropDownInput {
	font-size: 14px;
	padding: 0;
}

.input {
	font-size: 14px;
}

.dropdownListBox {
	font-size: 14px;
}
