@use "styles/utilities";

.container {
	border-bottom: 1px solid utilities.$border-color;
	display: flex;
	justify-content: center;
}

.listContainer {
	display: flex;
}

.listItem {
	justify-content: center;
	padding: utilities.$spacing1x 0;
}
