@use "styles/utilities";

.closeButton {
	position: absolute;
	right: 5px;
	top: 5px;
	color: utilities.$text-accent;
}

.description {
	min-height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.loading {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;
	gap: utilities.$spacing1x;
}
