@use "styles/utilities";

.root {
	display: flex;
	min-height: 100%;
}

.background {
	background-color: utilities.$global-background-primary;
	width: 100%;
	min-height: 100vh; // background should fill complete viewport
}

html {
	background-color: utilities.$global-background-primary;
}
