@use "styles/utilities";

.container {
	// margin: 0 utilities.$spacing3x utilities.$spacing3x 0;
	border-bottom: 2px solid utilities.$yellowDark;
	display: flex;
}

.updateIntervalContainer {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.icon {
	margin-right: 5px;
	color: utilities.$brownDark;
}
