@use "styles/utilities";

.container {
	display: flex;
	flex-direction: row;
	position: relative;
	align-items: flex-start;
	border-radius: 8px;
	border: 1px solid #eaeaea;
	background-color: utilities.$global-card-background-primary;
	height: 185px;
	overflow: hidden;

	@include utilities.md-down {
		padding: 12px;
	}
}

.selectable {
	cursor: pointer;
	&:hover {
		box-shadow: 0px 4px 10px utilities.$border-color-heavy;
		transition-duration: 300ms;
	}
}

.fontWeight400 {
	font-weight: 400;
}

.fontWeight600 {
	font-weight: 600;
}

.iconWrapperMedium {
	border-radius: 4px;
	padding: 2px 4px 2px 4px;
	max-width: max-content;
	align-self: center;
}

.iconWrapper {
	border-radius: 4px;
	padding: 4px 8px 4px 8px;
	max-width: max-content;
}

.verticalAlign {
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
}

.bigIcon {
	transform: scale(2);
	height: 59px;
	width: 73px;
}

.navigation {
	padding-top: utilities.$spacing1x;
	@include utilities.md-down {
		padding: 0;
	}

	color: #2d88f2;
	cursor: pointer;
}

.smallExpandableIcon {
	width: 6px;
}

.bigExpandableIcon {
	width: 20px;
}

.secondaryTitle {
	color: utilities.$text-accent;
	font-family: utilities.$global-font-family;
	vertical-align: top;
}

.marginBottom {
	margin-bottom: utilities.$spacing1x;
}

.periodLabel {
	position: absolute;
	font-size: 12px;
	top: utilities.$spacing3x;
	right: utilities.$spacing3x;
	padding: utilities.$spacing1x utilities.$spacing2x;
	border-radius: 8px;
	background-color: utilities.$global-card-background-secondary;
	width: 140px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.periodLabelIcon {
	margin-right: utilities.$spacing2x;
	width: 20px;
	display: inline-block;
	vertical-align: middle;
}

.costTrend {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}

.dummySpacingElement {
	height: 1em;
}

.containerSmall {
	@extend .container;
	.bigIcon,
	.periodLabel,
	.activeDiscountContainer,
	.iconWrapperMedium {
		display: none;
	}
}

.iconRegularBorder {
	border: 1px solid utilities.$greyTint1;
}

.iconAlertedBorder {
	border: 2px solid utilities.$yellowTint1;
}

.discountAmount {
	color: utilities.$text-accent;
	text-decoration: line-through;
	display: block;
}
