@use "styles/utilities";

.chartStyles {
	position: relative;
}

.tall {
	height: 500px;
}

.short {
	height: 200px;
}

.legends {
	height: fit-content;
	justify-content: flex-start;
}
