@use "styles/utilities";

.activeDiscountContainer {
	background-color: rgba(69, 186, 120, 0.12);
	display: flex;
	flex-direction: column;
	padding: 12px 10px;
	gap: 4px;
	border-radius: 4px;
	width: 140px;
	position: absolute;
	top: 72px;
	right: utilities.$spacing3x;
}

.hidden {
	display: none;
}
