@use "styles/utilities";

.error {
	min-height: 24px;
}

.quantityContainer {
	border-bottom: 1px solid utilities.$border-color-light;
	width: 190px;
	padding-bottom: 4px;
	margin-top: 8px;
}

.hide {
	visibility: hidden;
}

.quantityAdjusterContainer {
	margin-left: utilities.$spacing2x;
}

.commitmentDurationSelector {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-top: utilities.$spacing2x;
	margin-left: utilities.$spacing2x;
}

.subHeaderContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: utilities.$spacing1x;
}

.numberContainer {
	border-radius: 50%;
	width: 20px;
	height: 20px;
	background-color: utilities.$accent;
	display: flex;
	justify-content: center;
	align-items: center;
	p {
		font-size: 14px;
		color: #fff;
		font-weight: 600;
	}
}

.emailInputContainer {
	width: 50%;
}

.errorContainer {
	min-height: 24px;
}

.severalSubsWarning {
	min-height: 24px;
	margin-left: utilities.$spacing2x;
	margin-top: 6px;
}
