@use "styles/utilities";

.statsContainer {
	cursor: pointer;
	width: auto;

	&:hover {
		border-radius: 4px;
		background-color: utilities.$global-card-background-secondary;
		transition-duration: 300ms;
	}
}

.valueColorBlock {
	height: 15px;
	display: inline-block;
}

.labelTextStyle {
	font-size: 14px;
	font-weight: 400;
}

.numberIndicator {
	font-weight: 500;
	display: flex;
	justify-content: center;
}

.strikethrough {
	text-decoration: line-through;
}
