@use "styles/utilities";

.tableRow {
	background-color: utilities.$global-card-background-primary;
}

.table {
	border-collapse: separate;
	border-spacing: 0 utilities.$spacing1x;
}

.endDateAndNotRenewed {
	color: utilities.$error;
}

.eightyPercent {
	width: 80%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.newSubscriptionText {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
