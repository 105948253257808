@use "styles/utilities";

.summaryWrapper {
	flex-wrap: nowrap;
	justify-content: center;
	padding-left: utilities.$spacing2x;
	padding-right: utilities.$spacing2x;
	margin-top: 110px;

	@include utilities.lg-down {
		flex-direction: column;
	}
}

.userDetails {
	margin-right: utilities.$spacing4x;
	@include utilities.lg-down {
		margin-right: 0px;
	}
}

.summaryCartWrapper {
	padding-left: 0 !important;
	@include utilities.lg-down {
		margin-top: 40px;
		margin-bottom: 110px;
		width: 100%;
		max-width: 100%;
	}
}

.summaryCart {
	@include utilities.lg-down {
		width: 100%;
		max-width: 100%;
	}
}
