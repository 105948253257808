@use "styles/utilities";

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.yellowInfoContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: utilities.$spacing2x;
	background-color: utilities.$warning-background;
	border-radius: 4px;
	margin-top: 16px;
	padding: utilities.$spacing3x;

	max-width: 800px;
	margin-bottom: 50px;
	@include utilities.md-down {
		max-width: 100%;
		margin-bottom: utilities.$spacing1x;
	}
}

.infoContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.costSavingInfoContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 16px;
	padding: utilities.$spacing2x;
}

.borderedContainer {
	border: 1px solid #d1d5db;
	border-radius: 4px;
	padding: utilities.$spacing2x;
	margin-top: 16px;
	max-width: 500px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.savingsContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.savingsItem {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 30%;
	@include utilities.md-down {
		width: 100%;
	}
}

.chartContainer {
	margin: utilities.$spacing3x;
	width: 500px;
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	@include utilities.lg-down {
		width: 300px;
		height: 300px;
	}
}

.yugeGreenSavingsNumber {
	font-weight: 500;
	font-size: 2rem;
	color: utilities.$success;
	text-align: center;
}

.blueIcon {
	color: utilities.$blueDark;
	margin-right: 4px;
}

.greenIcon {
	color: utilities.$green;
	margin-right: 4px;
}

.skeleton {
	width: 250px;
	height: 80px;
	margin-top: -14px;
	margin-bottom: -18px;
}
