@use "sass:color";
@use "styles/utilities";

$snackbarWidth: 300px;

.snackbar {
	position: fixed;
	width: $snackbarWidth;
	.progressBar {
		border-radius: 4px 0 0 0;
		position: absolute;
		top: 0;
		left: 0;
		height: 5px;
		background-color: rgba(255, 255, 255, 0.4); // Light color for visibility
		transition: width 0.06s linear;
	}
}

.snackbarText {
	color: utilities.$text-secondary;
}

.showButton {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 1000;
}

// Define background colors for different types of snackbars
.info {
	@extend .snackbar;
	background-color: utilities.$blueDark;
	.progressBar {
		background-color: color.mix(utilities.$blueDark, #fff, 30%);
	}
}

.success {
	@extend .snackbar;
	background-color: utilities.$success;
	.progressBar {
		background-color: color.mix(utilities.$success, #fff, 30%);
	}
}

.warning {
	@extend .snackbar;
	background-color: utilities.$warning;
	.progressBar {
		background-color: color.mix(utilities.$warning, #fff, 30%);
	}
}

.error {
	@extend .snackbar;
	background-color: utilities.$error;
	.progressBar {
		background-color: color.mix(utilities.$error, #fff, 30%);
	}
}

.snackbarBackground {
	width: $snackbarWidth;
	min-width: fit-content;
}

// Define semi-transparent backgrounds for alerts
.infoAlert {
	@extend .snackbarBackground;
	background-color: utilities.$blueDark;
}

.successAlert {
	@extend .snackbarBackground;
	background-color: utilities.$success;
}

.warningAlert {
	@extend .snackbarBackground;
	background-color: utilities.$warning;
}

.errorAlert {
	@extend .snackbarBackground;
	background-color: utilities.$error;
}

.icon {
	position: absolute;
	top: -24px;
	left: 16px;
	font-size: 32px;
	color: utilities.$error;
	svg {
		color: utilities.$error;
	}
}
