@use "styles/utilities";

.chartStyles {
	width: 100%;
	position: relative;
	height: calc(utilities.$standardContentHeight - utilities.$standardContentHeight/1.5);
	@include utilities.md-down {
		height: 500px;
	}
}

.chartAndLegendContainer {
	border: 1px solid #dbdbdb;
	border-radius: 4px;
	padding: utilities.$spacing2x;
	background-color: utilities.$global-card-background-primary;
	@include utilities.md-down {
		flex-direction: column-reverse;
		flex-wrap: nowrap;
	}
}

.legend {
	cursor: pointer;
	width: auto;

	&:hover {
		border-radius: 4px;
		background-color: utilities.$global-card-background-secondary;
		transition-duration: 300ms;
	}
}

.strikethrough {
	text-decoration: line-through;
}

.label {
	width: auto;
}

.icon {
	transform: scaleX(1.5);
}

.total {
	border-radius: 4px;
	background-color: utilities.$global-card-background-secondary;
}

.legendContainer {
	@include utilities.md-down {
		flex-direction: column;
		flex-wrap: nowrap;
	}
}

.divider {
	@include utilities.md-down {
		display: none;
	}
}
