@use "styles/utilities";

.container {
	cursor: pointer;
	object-fit: contain;
}

.image {
	object-fit: contain;
}

.x_small {
	max-width: 70px;
	min-width: 70px;
	max-height: 40px;
}

.small {
	max-width: 100px;
	min-width: 100px;
	max-height: 80px;
}

.large {
	max-width: 130px;
	min-width: 130px;
	max-height: 100px;
}
