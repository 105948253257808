@use "styles/utilities";

.inputRoot {
	padding: 2px 4px;
	display: flex;
	align-items: center;
	border: 1px solid utilities.$border-color;
	border-radius: 4px;
	background-color: utilities.$global-card-background-primary;
}

.iconButton {
	padding: 10px;
}

.input {
	flex: 1;
}

.statusSelector {
	width: 100%;
	height: 100%;
	background-color: utilities.$global-card-background-primary;
}

@mixin inputFieldSize($maxWidth) {
	max-width: $maxWidth;
	width: 100%;
}

.searchField,
.datePickerField {
	@include inputFieldSize(400px);
}

.statusSelectorField {
	@include inputFieldSize(150px);
}

.container {
	margin-bottom: utilities.$contentViewMarginBottom;
	@include utilities.centralizedWithMaxWidth();
}
