@use "styles/utilities";

.totalSavings {
	font-weight: 500;
	background-color: utilities.$yellowTint4;
	border-radius: 4px;
	padding: 2px 12px;
	display: flex;
	justify-content: center;
	min-width: 130px;
}

.tableFooter {
	padding: utilities.$spacing3x utilities.$spacing2x;
	display: flex;
	flex-direction: column;
	gap: utilities.$spacing2x;
	border-bottom: 1px solid utilities.$border-color;
	border-top: 1px solid utilities.$border-color;
	height: 140px;
}

.totalSavingsLoading {
	width: 100px;
}
