@use "styles/utilities";

.container {
	border: 1px solid utilities.$border-color;
	border-radius: 8px;
	background-color: utilities.$global-card-background-primary;
}

.rightDataFieldText {
	margin-left: 10px;
}

.accordionSummary {
	flex-direction: row-reverse;
	height: 100px;
}

.accordionHeading {
	justify-content: start;
	margin: 0 utilities.$spacing3x;
}

.accordionContainer {
	align-items: center;
}

.selectedCost {
	background-color: utilities.$global-card-background-primary;
	max-width: 100%;
	min-width: 150px;
}

.costChart {
	@include utilities.sm-down {
		display: none;
	}
}

.dataUpdateInterval {
	@include utilities.sm-down {
		display: none;
	}
}

.dowloadableLinkForSmallerScreens {
	display: none;
	@include utilities.sm-down {
		display: block;
	}
}
