@use "styles/utilities";

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.border {
	border-bottom: 1px solid utilities.$border-color;
	&:last-child {
		border-bottom: 1px solid utilities.$border-color-heavy;
	}
	&:only-child {
		border-bottom: none;
	}
}

.cartItem {
	padding: utilities.$spacing3x;
	position: relative;
	display: flex;
	flex-direction: row;
	background-color: utilities.$global-card-background-primary;
	-webkit-transition: background-color 2s; /* For Safari 3.1 to 6.0 */
	transition: background-color 2s;
	flex-wrap: nowrap;
}

.removeIconContainer {
	position: absolute;
	top: utilities.$spacing2x;
	right: utilities.$spacing2x;
	svg {
		width: 20px;
		height: 20px;
	}
}

.transition {
	background-color: rgba(69, 186, 120, 0.12);
}

.imageAndPriceContainer {
	padding: 0 utilities.$spacing1x;
	display: flex;
	flex-direction: column;
	gap: utilities.$spacing2x;
}

.price {
	font-weight: 500;
	margin-top: 16px;
}

.descriptionAndCounterContainer {
	flex-direction: column;
	align-items: center;
}

.truncate {
	padding-bottom: 4px;
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 260px;
	@include utilities.md-down {
		max-width: 80%;
	}
}

.skeleton {
	width: 460px;
}

.skeletonContainer {
	display: flex;
	flex-direction: column;
	padding-top: utilities.$spacing3x;
	height: 200px;
}

.stockAndItemSwapContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-top: utilities.$spacing2x;
}

.productLink {
	margin-top: utilities.$spacing1x;
	p,
	svg {
		color: utilities.$text-accent;
	}
}
