@use "styles/utilities";

.toolbar {
	min-height: 56px;
	@include utilities.sm-up {
		min-height: 64px;
	}
}

.drawerStandard {
	@include utilities.lg-up {
		min-height: 64px;
		width: utilities.$drawerWidth;
		flex-shrink: 0;
	}

	@include utilities.lg-down {
		display: none;
	}
}

.hideForLargeScreens {
	@include utilities.lg-up {
		display: none !important;
		& > * {
			display: none !important;
		}
	}
}

.drawerSmallScreen {
	@include utilities.lg-up {
		display: none !important;
		& > * {
			display: none !important;
		}
	}
	@include utilities.lg-down {
		display: block;
	}
}

.drawerPaper {
	width: 100%;
	@include utilities.lg-up {
		width: utilities.$drawerWidth;
	}
}

.logo {
	width: auto;
	height: 50px;
	display: block;
	margin: 14px auto;
	@include utilities.md-up {
		height: 60px;
	}
}
