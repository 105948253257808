@use "styles/utilities";

.inputRoot {
	padding: 2px 4px;
	display: flex;
	align-items: center;
	border: 1px solid utilities.$border-color;
	border-radius: 4px;
	background-color: utilities.$global-card-background-primary;
}

.iconButton {
	padding: 10px;
}

.input {
	flex: 1;
	color: utilities.$text-accent;
}

.dateRangePicker {
	position: absolute;
	z-index: 100;
	font-family: utilities.$global-font-family;
	border: 1px solid utilities.$border-color;
}

.dateRangePickerContainer {
	position: relative;
}

.hidden {
	visibility: hidden;
}
