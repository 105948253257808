@use "styles/utilities";

.tableHeader {
	z-index: 100; // Ensure ironstone logo is below the table header
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: nowrap;
	@include utilities.md-down {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		display: none;
	}
	position: sticky;
	top: calc(var(--top-search-bar-height) + utilities.$spacing3x * 2);
	background-color: #fff;
	padding: utilities.$spacing3x;
	min-height: fit-content;
	background-color: utilities.$table-head-primary;
	border: 1px solid utilities.$border-color;
	border-radius: 4px 4px 0 0;
	margin-top: utilities.$spacing1x;
}

.tableHeaderShadow {
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
	transition: box-shadow 0.2s ease-in-out;
	border: 0;
	border-radius: 0;
	margin-top: 0;
}

.hidden {
	visibility: hidden;
}
