@use "styles/utilities";

.globalLoadingScreen {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: utilities.$spacing2x;
	z-index: 2000;
	background-color: rgba(0, 0, 0, 0.8);
	backdrop-filter: blur(10px);
	p,
	h2 {
		color: #fff;
	}
}
