@use "styles/utilities";

.iconAndDottedLineContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: flex-start;
	height: 90px;
}

.dottedLine {
	width: 2px;
	height: 80%;
	border: 1px dashed utilities.$border-color;
	margin-top: utilities.$spacing1x;
}

.entryContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 4px;
	position: relative;
}

.textContainer {
	margin-left: utilities.$spacing3x;
	align-self: start;
	display: flex;
	flex-direction: column;
	gap: utilities.$spacing1x;
}

.noHistoryContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 50px;
}

.actionText {
	margin-top: 4px;
}

.label {
	top: 8px;
	right: 0;
	position: absolute;
	border-radius: 8px;
	padding: 4px 8px;
	background-color: #fbfbfb;
	border: 1px solid utilities.$border-color;
	display: flex;
	align-items: center;
	gap: 4px;
	max-width: fit-content;
}

.error {
	@extend .label;
	color: utilities.$error;
}

.inProgress {
	@extend .label;
	color: utilities.$blueDark;
}

.actionCancelled {
	@extend .label;
	color: utilities.$text-primary;
}

.inProgressOpacity {
	opacity: 0.5;
}

.cancelledOpacity {
	opacity: 0.6;
}

.hidden {
	display: none;
}

.infoIcon {
	font-size: 12px;
}

.divider {
	margin: 2px -22px;
	border: 1px solid utilities.$border-color;
}
