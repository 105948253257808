@use "styles/utilities";

.description {
	display: inline;
	padding-left: 6px;
	color: utilities.$text-accent;
}

.tableHeadRow {
	th {
		font-size: 16px;
	}
}

.potentialSavings {
	background-color: utilities.$yellowTint4;
	border-radius: 4px;
	padding: 2px 12px;
	font-weight: 500;
	max-height: 34px;
}

.tableHead {
	@include utilities.responsiveTableHeader();
	background-color: utilities.$table-head-primary !important;
	position: sticky;
	top: 0;
	z-index: 1;
}

.tableContainer {
	border: transparent;
	max-height: 400px;
	overflow: auto;
	@include utilities.responsiveTableContainer();
}

.table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0 utilities.$spacing2x;
}

.tableRow {
	height: 7vh;
	border: none;
}

.noBorderTableRow {
	td {
		border: none !important;
	}
}

.minWidthRemovalDate {
	min-width: 120px;
}

.removalDate {
	display: flex;
	align-items: center;
	min-height: 36px;
	justify-content: center;
	text-align: center;
}

.hide {
	visibility: hidden;
}
