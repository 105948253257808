@use "styles/utilities";

.radio {
	border: 1px solid utilities.$border-color;
	width: 100px;
	background-color: #ffffff;
	margin-top: 10px;
	margin-right: 0;
	&:last-child {
		border-radius: 0 8px 8px 0;
	}
	&:first-child {
		border-radius: 8px 0 0 8px;
	}
}

.selected {
	background-color: utilities.$yellowTint4;
	color: utilities.$text-secondary;
}

.radioGroupContainer {
	height: 60px;
}
