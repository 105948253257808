@use "styles/utilities";

.doughnut {
	width: 200px;
	@include utilities.md-down {
		padding-left: 0;
	}
	@include utilities.sm-down {
		padding: 0;
	}
}

.linearProgress {
	@include utilities.sm-down {
		padding: 0;
	}
	width: 100%;
	height: 20px;
	background-color: #3575a4b2;
	border-radius: 2px;
	margin-top: utilities.$spacing4x;
}

.labelsContainer {
	display: flex;
	justify-content: flex-start;
}

.labelContainer {
	display: flex;
	gap: utilities.$spacing1x;
}

.greenIcon {
	color: #47ae8187;
}

.blueIcon {
	color: #3575a4b2;
}
