@use "styles/utilities";

.tabsContainer {
	margin-bottom: utilities.$spacing2x;
	width: 90%;
	@include utilities.sm-down {
		width: 100%;
		position: sticky;
		top: 0;
		left: 0;
		background-color: utilities.$global-card-background-primary;
		z-index: 1;
		button {
			padding-left: 0;
			padding-right: 0;
			font-size: 14px;
		}
	}
}

.tab {
	flex-grow: 1;
}
