@use "styles/utilities";

.row {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-top: utilities.$spacing2x;
}

.cell {
	height: 50px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding-left: utilities.$spacing3x;
}

.approverTypeCell {
	@extend .cell;
	width: 20%;
}

.autocompleteCell {
	@extend .cell;
	width: 40%;
	margin-top: 16px;
}

.approverForCell {
	@extend .cell;
	width: 30%;
}

.divider {
	background-color: utilities.$greyTint4;
	margin: 0 2px;
}

.autocompleteSkeleton {
	width: 400px;
	height: 40px;
	border-radius: 4px;
	margin-bottom: 26px;
}

.userNameContainer {
	display: flex;
	flex-direction: column;
	gap: 4px;
	flex-grow: 1;
}

.tagContainer {
	display: flex;
	flex-direction: row;
	gap: 6px;
}

.icon {
	color: utilities.$text-accent;
	@include utilities.md-down {
		display: none;
	}
}

.unchecked {
	color: #909090;
	margin-right: 8px;
}

.checked {
	margin-right: 8px;
	color: utilities.$yellowTint1;
}

.option {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	cursor: pointer;
	&:hover {
		background: utilities.$global-card-background-secondary;
	}
	padding: utilities.$spacing1x 0 utilities.$spacing1x 0;
}

.disabledOption {
	opacity: 0.5;
	cursor: default;
	&:hover {
		background: none;
	}
}

.dropdownStyle {
	min-width: 350px;
}

.disabled {
	opacity: 0.5;
	filter: grayscale(1);
}
