@use "styles/utilities";

.icon {
	border-radius: 8px;
	padding: 4px;
	font-size: 28px;
}

.calendarIcon {
	@extend .icon;
	color: utilities.$green;
}

.calendarCancelledIcon {
	@extend .icon;
	color: black;
	opacity: 0.7;
}

.cartIcon {
	@extend .icon;
	color: utilities.$blueDark;
}

.updateIcon {
	@extend .icon;
	color: utilities.$error;
}

.updateIconGreen {
	@extend .icon;
	color: utilities.$green;
}

.pauseIcon {
	@extend .icon;
	color: utilities.$yellowPrimary;
}

.cancelIcon {
	@extend .icon;
	color: utilities.$error;
}

.highlightOffIcon {
	@extend .icon;
	color: utilities.$text-primary;
}
