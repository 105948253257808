@use "styles/utilities";
@use "../common.module";

.greenStatus {
	border-color: utilities.$success !important;
}

.redStatus {
	border-color: rgba(226, 45, 85, 0.56) !important;
}
.orangeStatus {
	border-color: "#FFA500" !important;
}

.container {
	height: 100%;
	min-height: 700px;
	border-top: 11px solid transparent;
	border-radius: 0;
	overflow: hidden;
	box-shadow: none;
	@include utilities.md-down {
		min-height: 0;
		max-height: fit-content;
	}
}

.gridContainer {
	height: common.$updatesCardHeight;
	@include utilities.md-down {
		min-height: 0;
		height: fit-content;
	}
}

.linkFooterContainer {
	background-color: utilities.$global-card-background-primary;
	display: flex;
	align-items: center;
}

.linkFooter {
	display: block;
	margin: 16px auto;
}

.linkIcon {
	font-size: inherit;
}

.linkText {
	vertical-align: text-bottom;
	margin-left: 4px;
}

.title {
	margin-top: 16px;
}
