@use "styles/utilities";

.paper {
	background-color: utilities.$global-card-background-primary;
	border-radius: 8px;
	border: 1px solid utilities.$border-color-light;
	min-width: 250px;
}

.clickable {
	&:hover {
		cursor: pointer;
		box-shadow: 0px 4px 10px utilities.$border-color-heavy;
		transition-duration: 300ms;
	}
}

.container {
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.chip {
	color: utilities.$text-secondary;
	font-size: 10px;
}

.ofTotalText {
	font-size: 16px;
	font-weight: 400;
	padding-left: 8px;
	color: utilities.$text-accent;
}

.topSectionContainer {
	padding: utilities.$spacing2x 0 utilities.$spacing2x utilities.$spacing2x;
}

.title {
	font-size: 16px;
	font-weight: 400;
}

.redBorder {
	border-top: 4px solid utilities.$error;
	margin-right: utilities.$spacing4x;
}

.yellowBorder {
	border-top: 4px solid utilities.$warning-background;
	margin-right: utilities.$spacing4x;
}

.grayBorder {
	border-top: 4px solid utilities.$global-card-background-secondary;
	margin-right: utilities.$spacing4x;
}
