@use "styles/utilities";

@keyframes growShrink {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.3);
	}
	100% {
		transform: scale(1);
	}
}

.animateBadge {
	animation: growShrink 0.5s ease-in-out;
}

.customBadge {
	position: absolute;
	top: -10px;
	right: -16px;
	color: utilities.$text-primary;
	border-radius: 50%;
	min-width: 1.5rem;
	text-align: center;
	line-height: 1.5rem;
	@include utilities.sm-down {
		top: 0px;
		right: -8px;
	}
}

.customBadgeCart {
	@extend .customBadge;
	background-color: utilities.$primary-button-background-color;
}

.customBadgeBundle {
	@extend .customBadge;
	background-color: utilities.$blueDark;
	color: white;
	font-weight: 500;
}

.text {
	margin-left: utilities.$spacing1x;
	white-space: nowrap;
	@include utilities.sm-down {
		display: none;
	}
}

.icon {
	margin-top: 4px;
	margin-left: 4px;
	@include utilities.sm-down {
		margin-top: 0px;
	}
}

.buttonWrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: fit-content;
	max-height: fit-content;
	cursor: pointer;
	padding: 0;
	&:hover {
		background-color: #d9d9d938;
		border-radius: 8px;
	}
	@include utilities.sm-down {
		flex-direction: row;
	}
}

.bundleCost {
	margin-left: 1px;
	white-space: nowrap;
}

.cartCost {
	margin-left: 34px;
	white-space: nowrap;
	@include utilities.sm-down {
		margin-left: 4px;
	}
}
