@use "styles/utilities";

$stepperActionsContainerHeight: 140px;

.tableWrapper {
	position: relative;
	margin-bottom: 60px;
}

.configurationPageContainer {
	padding: utilities.$spacing3x;
	margin-bottom: $stepperActionsContainerHeight;
}

.licenseRemovalContainer {
	padding: utilities.$spacing3x;
	margin-bottom: $stepperActionsContainerHeight;
}

.checkoutPageContainer {
	justify-content: center;
	align-items: space-around;
	padding: utilities.$spacing3x;
	margin-bottom: $stepperActionsContainerHeight;
}

.contentContainer {
	position: relative;
}
