@use "styles/utilities";

.logo {
	width: 18px;
	height: 18px;
	z-index: 1;
	margin-top: 6px;
	margin-right: 4px;
}

.enrollmentStatusContainer {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
}
