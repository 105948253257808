@use "styles/utilities";

.goBackContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
}

.goBackStyle {
	display: inline;
}

.goBackIcon {
	font-size: large;
}

.breadcrumb {
	&:not(:first-child) {
		&:before {
			padding: 0 5px;
		}
	}
	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
}

.lastChild {
	font-weight: 500;
	color: utilities.$yellowDark;
}

.headerContainer {
	padding: utilities.$spacing2x 0 utilities.$spacing1x 0;
	max-width: utilities.$xl;
	display: flex;
	flex-direction: column;
	max-width: 50%;
}

.wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 0 auto;
	max-width: 85vw;
	@include utilities.xl-down {
		max-width: 75vw;
	}
	@include utilities.lg-down {
		max-width: 90vw;
	}
	@include utilities.sm-down {
		max-width: 100vw;
	}
}
