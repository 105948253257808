@use "styles/utilities";
@use "sass:color";

.riskLevel {
	padding: 8px;
	border-radius: 8px;
	width: 86px;
	display: inline-block;
	text-transform: capitalize;
	text-align: center;
}

.high {
	background: utilities.$errorChip;
}

.medium {
	background: utilities.$yellowTint4;
}

.low {
	background-color: rgba(39, 174, 96, 0.12);
	color: #27ae60;
	font-weight: 500;
}
