@use "styles/utilities";

.formTextInput {
	font-size: 16px;
	font-weight: 400;
}

.selectTextInput {
	font-size: 16px;
	font-weight: 400;
}

.selectInput {
	& > :global(.MuiInputBase-root) {
		font-size: 16px;
	}
}

.atSign {
	height: 80px;
}

.errorText {
	:global(.MuiFormHelperText-root) {
		margin-left: 0px;
		width: max-content;
		height: 100%;
	}
}

@mixin setForBreakpointWidth($width: 100%) {
	@include utilities.xl-down {
		width: $width !important;
		max-width: $width !important;
		flex-basis: $width !important;
		flex-wrap: nowrap;
		white-space: nowrap;
	}
}

.input {
	height: 75px;
	@include setForBreakpointWidth(100%);
}

.inputField {
	@include setForBreakpointWidth(100%);
}

.inputEmailField {
	@include setForBreakpointWidth(50%);
}

.wrapForsmallScreen {
	@include utilities.sm-down {
		flex-wrap: wrap !important;
	}
}

.select {
	padding-top: 10px;
	padding-left: 10px;
}

.generatePassword:hover {
	background-color: transparent;
}

.generatePasswordContainer {
	display: "flex";
	flex-direction: "row";
	padding-top: 23px;
	@include utilities.sm-down {
		margin-left: 0px;
		padding-top: 0px;
	}
}

.passwordInputField {
	@include utilities.sm-down {
		min-height: 0 !important;
		height: 100%;
	}
}

.generatePasswordText {
	margin-left: 5px;
	color: utilities.$text-primary;
}

.tooltip {
	padding: 3px;

	:global(.MuiSvgIcon-root) {
		font-size: 16px;
	}
}

.skeleton {
	transform: none;
}
