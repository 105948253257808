.dialogRoot {
	:global(.MuiTypography-root) {
		font-size: 18px;
	}
}

.dialogActionBtn {
	color: white;
	background-color: rgb(35, 72, 99);
	font-size: 14px;
	width: 110px;
}

.dialogActionBtn:hover {
	background-color: rgb(180, 199, 231);
	box-shadow: none;
}
