@use "styles/utilities";

.textField {
	background: utilities.$global-card-background-primary;
	font-size: 15px;
	min-height: 50px;
}

.dropDown {
	cursor: pointer;
	font-size: 12px;
}

.checkboxDropdown {
	margin-right: 8px;
	color: utilities.$accent;
}
