@use "styles/utilities";

.licenseListContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.licenseItem {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid utilities.$border-color;
	padding: utilities.$spacing2x 0;
	max-height: 64px;
}

.itemAndDisplayName {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: utilities.$spacing1x;
	width: 40%;
	white-space: nowrap;
}

.groupLabelContainer {
	max-width: 25%;
	@include utilities.md-down {
		display: none;
	}
}

.groupLabel {
	background-color: utilities.$blueLicenseGroup;
	padding: 6px 12px;
	border-radius: 6px;
	white-space: nowrap;
}

.removeLicenseButtonContainer {
	max-width: 25%;
	cursor: pointer;
	gap: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 6px;
	border-radius: 8px;
	svg {
		font-size: 14px;
		color: utilities.$grey700;
		@include utilities.md-down {
			display: none;
		}
	}
	white-space: nowrap;
	&:hover {
		background-color: #b9b9b9;
		svg {
			color: #fff;
		}
		.removeLabel {
			color: #fff;
			font-weight: 500;
		}
	}
}

.removeLabel {
	color: utilities.$grey700;
}

.removedLabel {
	color: utilities.$grey700;
	cursor: pointer;
	border-bottom: 1px solid utilities.$grey700;
	padding-bottom: 1px;
	font-weight: 500;
}

.removedLicensesContainer {
	margin-top: utilities.$spacing2x;
}

.removedLicenseItem {
	background-color: utilities.$brownTint4;
	border-bottom: none;
	margin-bottom: utilities.$spacing1x;
	padding: utilities.$spacing2x;
	border-radius: 8px;
}

.hidden {
	display: none;
}

.removedLicensesLabel {
	margin-top: utilities.$spacing4x;
	font-weight: 400;
}

.noRemovalsSelected {
	opacity: 0.5;
}

.noLicensesLabel {
	margin-top: utilities.$spacing2x;
	display: flex;
	justify-content: center;
}

.disabled {
	visibility: hidden;
}
