@use "styles/utilities";

.drawer {
	@include utilities.md-down {
		width: 100vw;
	}

	// Medium screens: Overlap with faded background
	@include utilities.md-up {
		width: 450px;
		.MuiBackdrop-root {
			background-color: rgba(0, 0, 0, 0.5);
		}
	}

	@include utilities.lg-up {
		width: 450px;
	}
}

.pushContent {
	padding-left: 450px;
}

.hardwareDrawerWrapper {
	display: flex;
	justify-content: flex-end;
	z-index: 1302;
	position: fixed;
}

.headerContainer {
	min-height: 100px;
	width: 100%;
	border-bottom: 1px solid utilities.$border-color;
	display: flex;
	flex-direction: row;
	padding: utilities.$spacing3x;
	justify-content: space-between;
	align-items: center;
}

.cartHeader {
	background-color: utilities.$yellowTint4;
}

.bundleHeader {
	background-color: utilities.$blueTint6;
}

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: calc(100vw - 400px);
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1200;
}

.closeText {
	cursor: pointer;
}

.closeButton {
	margin-bottom: 2px;
}

.drawerHeaderTextDecoration {
	text-decoration: underline;
	text-decoration-style: dotted;
}

.cartContainer {
	overflow-y: auto;
	overflow-x: hidden;
}
