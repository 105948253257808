@use "styles/utilities";

.tableHead {
	background-color: utilities.$table-header-background;
	@include utilities.responsiveTableHeader();
}

.tableRow {
	background-color: utilities.$global-card-background-primary;
	@include utilities.responsiveTableContainer();
}

.cell {
	vertical-align: baseline;
	max-width: 450px;
	overflow: hidden;
}

.scrollableTableContainer {
	flex-grow: 1;
	max-width: 100%;
	overflow-x: auto;
}

.tableSortLabel {
	@include utilities.lg_up() {
		white-space: nowrap;
	}
}
