@use "styles/utilities";

.tableRow {
	min-height: 86px;
	background-color: utilities.$global-card-background-primary;
	border-bottom: 1px solid utilities.$border-color-light;
	padding-bottom: utilities.$spacing2x;
	@include utilities.md-down {
		height: fit-content;
		margin-bottom: utilities.$spacing2x;
		padding-bottom: utilities.$spacing3x;
		border-radius: 4px;
	}
}

.lastActiveCell {
	border-radius: 8px;
	padding: 6px 12px;
	width: 120px;
	text-align: center;
}

.active {
	@extend .lastActiveCell;
	background-color: utilities.$greyTint2;
}

.inactive {
	@extend .lastActiveCell;
	background-color: #df786a33;
}

.inactiveFont {
	color: #e25a48;
}
