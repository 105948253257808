@use "styles/utilities";

.container {
	border: 1px solid utilities.$border-color;
	border-radius: 8px;
	background-color: utilities.$global-card-background-primary;
	width: 220px;
	padding: utilities.$spacing2x;
	margin-bottom: utilities.$spacing1x;
	margin-top: utilities.$spacing1x;
}
