@use "styles/utilities";

.carousel {
	position: relative;
	width: 100%;
	max-width: 100%;
	min-width: none;
	height: fit-content;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	.scrollList {
		display: flex;
		align-items: center;
		max-width: 100%;
		min-width: none;
		overflow-x: scroll;
		scroll-behavior: smooth;
		// Hide firefox scrollbar
		scrollbar-width: none;
		// Hide edge scrollbar
		&::-webkit-scrollbar {
			display: none;
		}
		// Hide chrome scrollbar
		&::-webkit-scrollbar {
			background: transparent; /* make scrollbar transparent */
			-webkit-appearance: none;
			width: 0;
			height: 0;
		}
	}

	.titleAndButtonContainer {
		justify-content: flex-end;
		flex-wrap: nowrap;
		width: 100%;
		max-width: 100%;
		.buttonContainer {
			padding-right: utilities.$spacing3x;
			align-self: flex-end;
			width: fit-content;
		}
	}
}

.leftArrowContainer,
.rightArrowContainer {
	opacity: 0;
	transition: opacity 0.5s ease;
}

.leftArrowContainerShadow,
.rightArrowContainerShadow {
	opacity: 1; /* set opacity to 1 when the active class is added */
}

.leftArrowContainerShadow {
	@include utilities.md-down {
		width: 0px;
	}
	width: 100px;
	background: linear-gradient(
		270deg,
		rgba(7, 7, 7, 0) 1.63%,
		rgba(111, 111, 111, 0.31) 87.31%,
		rgba(217, 217, 217, 0.63) 104.12%
	);
}

.rightArrowContainerShadow {
	@include utilities.md-down {
		background: transparent;
	}
	width: 100px;
	background: linear-gradient(
		90deg,
		rgba(7, 7, 7, 0) 1.63%,
		rgba(111, 111, 111, 0.31) 87.31%,
		rgba(217, 217, 217, 0.63) 104.12%
	);
}

.leftArrowContainer {
	@include utilities.md-down {
		// Move arrow all the way to the left on small screens
		left: -40px;
	}
	position: absolute;
	height: 100%;
	z-index: 5;
	display: flex;
	align-items: center;
}

.rightArrowContainer {
	@include utilities.md-down {
		// Move arrow all the way to the right on small screens
		right: -20px;
	}
	right: 0;
	position: absolute;
	height: 100%;
	z-index: 5;
	display: flex;
	align-items: center;
}

.leftArrowIcon {
	margin-left: 25px;
	font-size: 50px;
	color: rgba(95, 118, 147, 1);
}

.rightArrowIcon {
	margin-right: 34px;
	font-size: 50px;
	color: rgba(95, 118, 147, 1);
}
