@use "styles/utilities";

.container {
	min-height: 500px;
	display: flex;
	flex-direction: row;
}

.locationListContainer {
	width: 300px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: utilities.$greyTint2;
	padding-bottom: utilities.$spacing3x;
}

.locationList {
	max-height: 400px;
	overflow-y: auto;
}

.locationItem {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	cursor: pointer;
	padding: utilities.$spacing2x;

	p {
		color: utilities.$text-primary;
		font-weight: 500;
	}

	&:hover {
		color: utilities.$text-secondary;
		background-color: utilities.$grey600;
		p {
			color: utilities.$text-secondary;
		}
		.address {
			color: utilities.$text-secondary;
			font-weight: 500;
		}
	}
	&:last-child {
		margin-bottom: utilities.$spacing3x;
	}
}

.selected {
	background-color: utilities.$greyTint3;
	p {
		color: utilities.$text-secondary;
	}
	.address {
		color: utilities.$text-secondary;
		font-weight: 500;
	}
}

.address {
	color: utilities.$text-primary;
}

.form {
	padding: utilities.$spacing3x utilities.$spacing2x utilities.$spacing3x utilities.$spacing2x;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	max-width: 400px;
	margin: 0 auto;
}

.formRow {
	display: flex;
	flex-direction: row;
}

.formItemSingle {
	width: 100%;
	padding-right: utilities.$spacing1x;
}

.formItem {
	width: 50%;
	padding-right: utilities.$spacing1x;
}

.buttonRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: utilities.$spacing1x;
	padding-top: utilities.$spacing4x;
}

.saveButton {
	border: none;
	padding: utilities.$spacing1x utilities.$spacing2x;
	cursor: pointer;
	border-radius: 4px;
}

.addLocationButton {
	margin-left: utilities.$spacing2x;
	box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.01);
	&:hover {
		color: utilities.$text-primary;
		box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.01);
	}
	&:hover .addIcon {
		color: utilities.$text-primary;
	}
}

.addIcon {
	color: utilities.$greyTint3;
}

.formTitle {
	margin-bottom: utilities.$spacing2x;
}

.deleteButton {
	color: utilities.$error;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-start;
	gap: 4px;
	margin-bottom: 12px;

	&:hover {
		text-decoration: underline;
	}
}

.deleteButtonLoader {
	margin-right: utilities.$spacing3x;
	color: utilities.$error;
}
