@use "styles/utilities";

.drawerWrapper {
	z-index: 1300;
}

.drawer {
	@include utilities.md-down {
		width: 100vw;
	}
	// Medium screens: Overlap with faded background
	@include utilities.md-up {
		width: 708px;
		.MuiBackdrop-root {
			background-color: rgba(0, 0, 0, 0.5);
		}
	}

	@include utilities.lg-up {
		width: 708px;
	}
}

.drawerContent {
	padding: utilities.$spacing3x;
}

.drawerHeader {
	margin-bottom: utilities.$spacing2x;
	position: relative;
	width: 100%;
}

.drawerContentContainer {
	width: 90%;
	@include utilities.md-down {
		width: 100vw;
	}
}

.closeIcon {
	font-size: 14px;
	color: utilities.$text-accent;
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
}
