@use "styles/utilities";

.filterField {
	width: 180px;
	max-width: 400px;
	@include utilities.md-down {
		width: 100%;
	}
}

.filterSelector {
	width: 100%;
	height: 48px;
	background-color: utilities.$global-card-background-primary;
}

.filterActive {
	background-color: utilities.$yellowTint4;
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
}
