@use "styles/utilities";

.ingressContainer {
	background-color: #ffebba;
	padding-left: utilities.$spacing3x;
}

.backdrop {
	background-color: #fff;
	z-index: 1000;
}

.permissionList {
	li {
		margin-bottom: 1px;
	}

	list-style: none;
}

.link {
	color: utilities.$link;
	display: inline-flex;
	align-items: center;
}

.checkCircle {
	font-size: 13px;
	color: #2eb267;
	margin-right: 5px;
}

.openInNew {
	font-size: 15px;
	margin-right: 10px;
}

.linkText {
	vertical-align: text-bottom;
}
