@use "styles/utilities";

.deliveryOptionsSelectorContainer {
	display: flex;
	flex-direction: row;
	gap: utilities.$spacing3x;
	padding: utilities.$spacing1x 0;
}

.deliveryOption {
	display: flex;
	flex-direction: row;
	gap: utilities.$spacing1x;
	align-items: center;
	width: 200px;
	height: 95px;
	border: 1px solid utilities.$border-color-heavy;
	border-radius: 4px;
	svg {
		margin: auto;
		color: utilities.$text-accent;
	}
	&:hover {
		cursor: pointer;
		border: 1px solid utilities.$yellowPrimary;
	}
}

.deliveryOptionActive {
	background-color: #efefef;
	border: 1px solid utilities.$yellowPrimary;
	svg {
		color: utilities.$yellowPrimary;
	}
}

.deliveryOptionText {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.secondaryText {
	color: utilities.$text-accent;
}
