@use "styles/utilities";

.banner {
	padding: utilities.$spacing1x;
	align-items: center;
}

.infoBanner {
	background-color: utilities.$blueDark;
	@extend .banner;
	display: flex;
	justify-content: center;
}

.alignTextAndIcon {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.infoText {
	color: #ffffff;
	font-size: 16px;
	font-weight: 500;
	@include utilities.centralizedWithMaxWidth();
	display: flex;
	justify-content: flex-start !important;
}

.icon {
	margin-right: utilities.$spacing2x;
	color: utilities.$yellowPrimary;
}
