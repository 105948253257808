@use "styles/utilities";

.legendContainer {
	padding: utilities.$spacing2x utilities.$spacing2x 0 utilities.$spacing4x;
}

.strikethrough {
	text-decoration: line-through;
}

.indicatorBox {
	width: utilities.$spacing3x;
	height: 4px;
	display: inline-block;
	margin-right: 4px;
	vertical-align: middle;
}

.indicatorContainer {
	margin: 0 utilities.$spacing2x;
	cursor: pointer;

	&:hover {
		border-radius: 4px;
		background-color: utilities.$global-card-background-secondary;
		transition-duration: 300ms;
	}
}
