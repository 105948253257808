@use "styles/utilities";

.tableContainer {
	border: transparent;
	@include utilities.responsiveTableContainer();
	margin-bottom: utilities.$spacing3x;
}

.table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 utilities.$spacing2x;
}

.cell {
	background-color: utilities.$global-background-primary;
}

.tableHead th {
	background-color: utilities.$table-head-primary;
	@include utilities.responsiveTableHeader();
}

.expandedRowHead {
	border-left: 1px solid utilities.$border-color-heavy;
	border-right: 1px solid utilities.$border-color-heavy;
}

.expandedRow {
	background-color: #fcfcfc;
	td {
		// Add left border for the first cell of every row
		&:first-child {
			border-left: 1px solid utilities.$border-color-heavy;
		}

		// Add right border for the last cell of every row
		&:last-child {
			border-right: 1px solid utilities.$border-color-heavy;
		}
	}

	// Only add the bottom border for the last row
	&:last-of-type {
		td {
			border-bottom: 1px solid utilities.$border-color-heavy;
		}
	}
}

.noBorderBottom {
	border-bottom: none;
}

.expandableRowGridItem {
	display: flex;
	align-items: center;
}

.expandableRow {
	cursor: pointer;
	height: 80px;
	justify-content: space-between;
	padding: utilities.$spacing2x;
	border: 1px solid utilities.$border-color-heavy;
	border-radius: 4px;
}

.borderRadiusOpen {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.noItemsCell {
	text-align: center;
	border-bottom: none;
}

.label {
	max-height: 40px;
	padding: 6px 11px 6px 11px;
	border-radius: 8px;
	font-weight: 500;
	white-space: nowrap;
	text-align: center;
}

.costSaved {
	border-bottom: 2px solid utilities.$yellowTint4;
}

.strikethrough {
	text-decoration: line-through;
}

.finishedLabel {
	@extend .label;
	background-color: #edf7ed;
	color: #244e26;
}

.errorLabel {
	@extend .label;
	background-color: #fdecea;
	color: #6c2620;
}

.scheduledLabel {
	@extend .label;
	background-color: #fff4e4;
	color: #704709;
}

.cancelledLabel {
	@extend .label;
	background-color: rgba(174, 174, 174, 0.16);
	color: utilities.$text-primary;
}

.cancelled {
	opacity: 0;
}

.icon {
	font-size: 18px;
}

.usersIconContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px;
	width: 30px;
	height: 25px;
	border-radius: 4px;
	color: #797979;
	background-color: #bfd2db;
	margin-right: utilities.$spacing1x;
}

.licenseIconContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 25px;
	border-radius: 4px;
	background-color: #fff7e0;
	margin-right: utilities.$spacing1x;
}

.cancelHeaderContainer {
	@extend .expandableRowGridItem;
	display: flex;
	justify-content: flex-end;
	padding-right: utilities.$spacing4x;
}
