@use "styles/utilities";
@use "sass:math";

.chipContainer {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	list-style: none;
	padding: 0 math.div(utilities.$spacing1x, 2) 0 math.div(utilities.$spacing1x, 2);
	margin: 0;
}

.chip {
	margin: math.div(utilities.$spacing1x, 2);
}

.topSectionContainer {
	margin-bottom: 90px;
}

.autocompleteContainer {
	width: 100%;
	max-width: 550px;
}
