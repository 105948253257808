@use "styles/utilities";

.textFieldWrapper {
	width: 50%;
	max-width: 50%;
	@include utilities.sm-down {
		width: 100%;
		max-width: 100%;
	}
}
