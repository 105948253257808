@use "styles/utilities";

.container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: utilities.$spacing2x;
}

.successIcon {
	font-size: 60px;
}

.overview {
	background-color: #fff;
	border-radius: 4px;
	width: 400px;
	height: fit-content;
	margin-right: 8px;
}

.finishButton {
	width: 300px;
	height: 75px;
	background-color: utilities.$primary-button-background-color;
	color: utilities.$text-primary;
}

.finishButton:hover {
	background-color: utilities.$primary-button-background-color;
	color: utilities.$text-primary;
}

.cart {
	min-height: 600px;
	max-width: 800px;
	background-color: #fff;
}

.breadcrumb {
	cursor: pointer;
}

.breadcrumb:hover {
	text-decoration: underline;
}

.email {
	// Make sure long emails don't overflow the table
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.labelFixedWidth {
	width: 130px;
	word-wrap: break-word;
}
