@use "styles/utilities";

$contentWidth: 600px;

.licenseAssignmentReceiptPage {
	margin: utilities.$spacing3x 0;
	padding: utilities.$spacing3x;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
	width: 960px;
	gap: utilities.$spacing3x;
}

.infoContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: utilities.$spacing1x;
	border: 1px solid utilities.$border-color;
	border-radius: 4px;
	width: $contentWidth;
	padding: utilities.$spacing2x utilities.$spacing3x;
	.infoIcon {
		color: utilities.$yellowDark;
	}
}

.contentContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: $contentWidth;
	gap: utilities.$spacing3x;
}

.assignmentAndPurchaseInfoContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: #fbfbfb;
	padding: utilities.$spacing3x;
}

.headerContainer {
	display: flex;
	align-items: center;
	width: 600px;
}
