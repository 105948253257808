@use "styles/utilities";

.cardContent {
	background-color: #fafafafa;
	border-right: 1px solid utilities.$border-color;
	border-bottom: 1px solid utilities.$border-color;
	border-radius: 0 0 8px 0;
	padding: utilities.$spacing3x;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: utilities.$insight-card-height;
	@include utilities.md-down {
		border-left: 1px solid utilities.$border-color;
		border-radius: 0 0 8px 8px;
	}
}

.midSectionContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	min-height: utilities.$insight-card-content-height;
}

.greyText {
	color: #797979;
}

.potentialSavings {
	border-bottom: 2px solid utilities.$yellowTint4;
	width: 200px;
	@extend .greyText;
}

.description {
	display: inline;
	padding-left: 6px;
	color: utilities.$text-accent;
}

.licenseNotInUseDescription {
	@extend .description;
	font-weight: 400;
}

.optimized {
	background-color: #17a956;
	padding: 5px;
	border-radius: 8px;
	width: 120px;
	height: 32px;
	text-align: center;
	vertical-align: center;
	color: white;
	font-weight: 600;
}

.optimizedText {
	color: #17a956;
	font-weight: 500;
}

// Really sorry about these negative margins, but it seems like the Skelton component is adding some extra padding
.skeletonButton {
	margin-top: -18px;
	margin-bottom: -12px;
}
