@use "styles/utilities";

.workspaceTitle {
	display: flex;
	justify-content: center;
	align-items: center;
}

.titleContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.captionWrapper {
	margin-top: -7px;
}

.checkbox {
	color: utilities.$yellowTint1;
}

.checkboxChecked {
	color: #909090;
}
