$small-button-padding-x: 18px;
$small-button-padding-y: 14px;
$medium-button-padding-x: 22px;
$medium-button-padding-y: 16px;
$large-button-padding-y: 20px;

$small-button-font-style: normal;
$small-button-font-weight: 400;
$small-button-font-size: 14px;
$small-button-line-height: 20px;

$medium-button-font-style: normal;
$medium-button-font-weight: 400;
$medium-button-font-size: 16px;
$medium-button-line-height: 20px;

$large-button-font-style: normal;
$large-button-font-weight: 400;
$large-button-font-size: 16px;
$large-button-line-height: 20px;
