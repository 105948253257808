@use "styles/utilities";

.licenseAdjustmentDrawerWrapper {
	z-index: utilities.$rightDrawerZIndex;
	position: fixed;
}

.drawer {
	padding: utilities.$spacing3x;
	display: flex;
	flex-direction: column;
	gap: utilities.$spacing2x;
	width: 800px;
	@include utilities.md-down {
		width: 100%;
	}
}

.closeButtonContainer {
	position: absolute;
	top: 8px;
	right: 8px;
}

.buttonContainer {
	width: 100%;
}

.buttonDiv {
	width: fit-content;
}

.termDurationPicker {
	max-width: 300px;
	margin-bottom: utilities.$spacing3x;
}

.skybertContainer {
	position: absolute;
}

.skybert {
	width: 25%;
	height: 25%;
	position: absolute;
	right: 60px;
	top: 15px;
	@include utilities.sm_down {
		display: none;
	}
}

.infoIcon {
	font-size: 20px;
	color: utilities.$yellowDark;
}

.confirmationPage {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.confirmationPageText {
	padding: utilities.$spacing3x utilities.$spacing1x;
	border-radius: 4px;
	max-width: 600px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: utilities.$spacing3x;
	gap: utilities.$spacing1x;
	border: 1px solid utilities.$border-color;
}

.titleContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: utilities.$spacing1x;
}
