@use "styles/utilities";

.totalsIcon {
	color: utilities.$grey800;
	font-size: 18px;
}

.costCardsWrapper {
	display: flex;
	@include utilities.xl-down {
		flex-direction: column;
		flex-basis: calc(100% + utilities.$spacing2x) !important;
		max-width: calc(100% + utilities.$spacing2x) !important;
	}
}

.largeCostTypeCardWrapper {
	flex-direction: row;
	flex-wrap: nowrap;
	flex-basis: calc(70% + utilities.$spacing2x) !important;
	max-width: calc(70% + utilities.$spacing2x) !important;
	justify-content: space-between;
	flex-direction: column;
	@include utilities.xl-up {
		padding-right: utilities.$spacing3x;
	}
	@include utilities.xl-down {
		flex-direction: row;
		padding-bottom: utilities.$spacing3x;
		max-width: calc(100% + utilities.$spacing2x) !important;
	}
	@include utilities.md-down {
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: space-between;
	}
}

.smallCostTypeCardWrapper {
	flex-basis: calc(100% + utilities.$spacing2x) !important;
	max-width: calc(100% + utilities.$spacing2x) !important;

	@include utilities.sm-down {
		flex-wrap: wrap;
	}
}

.largeCard {
	max-width: 100% !important;
}
