@use "styles/utilities";

.itemSwapText {
	color: utilities.$blueDark;
	font-weight: 400;
}

.itemSwapIcon {
	padding-top: 3px;
	color: utilities.$blueTint1;
}

.itemSwapContainer {
	cursor: pointer;
	padding-right: 4px;
	padding-bottom: 1px;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.hidden {
	visibility: hidden;
}
