@use "styles/utilities";

$stepperActionsContainerHeight: 140px;

.stepperActions {
	box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.05);
	background-color: #fff;
	z-index: 1000;
}

.stepperActions {
	position: absolute;
	bottom: 0;
	min-height: $stepperActionsContainerHeight;
	width: 100%;
	box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.05);
	background-color: #fff;
	z-index: 1000;
}

.firstStepStyle {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: utilities.$spacing3x;
}

.unassignedFirstStepStyle {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	padding: utilities.$spacing3x;
	gap: utilities.$spacing2x;
}
