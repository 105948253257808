@use "styles/utilities";

.totalSavingsCardContent {
	padding: utilities.$spacing1x;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	min-height: 100%;
	max-height: 120px; // When moving from identityreport to this view, the doughnuts get f'ed up.
	// WHen this issue has been solved, this maxHeight is no longer necessary.
}

.doughnut {
	width: 200px;
	@include utilities.md-down {
		padding-left: 0;
	}
	@include utilities.sm-down {
		padding: 0;
	}
}

.chartContainer {
	min-width: 75px;
	width: 60%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.costContainer {
	display: flex;
	align-items: center;
	gap: utilities.$spacing1x;
}

.savingsDescriptionText {
	color: white;
}

.labels {
	width: 40%;
	gap: utilities.$spacing2x;
	@include utilities.xl-down {
		width: 50%;
	}
}

.label {
	padding-left: utilities.$spacing2x;
	flex-direction: row;
	flex-wrap: nowrap;
	gap: utilities.$spacing2x;
	align-items: center;
}

.greenIcon {
	color: #47ae81;
}

.blueIcon {
	color: #3575a4;
}
