.tableContainer {
	max-height: 540px; // max height for old corporate business laptop
}

.dialogTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.downloadIcon {
	font-size: 20px;
}

.role {
	min-width: 225px;
}
