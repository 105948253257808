@use "styles/utilities";

.paymentStatusLabel {
	padding: 6px 11px 6px 11px;
	border-radius: 8px;
	font-weight: 500;
	font-size: 16px;
	white-space: nowrap;
}

.paidLabel {
	@extend .paymentStatusLabel;
	background-color: utilities.$greenLabelBackground;
	color: utilities.$greenLabelTextColor;
}

.openLabel {
	@extend .paymentStatusLabel;
	background-color: rgba(254, 245, 230, 1);
	color: utilities.$yellowDark;
}
