@use "styles/utilities";

.productActivityStatusLabel {
	padding: 6px 11px 6px 11px;
	border-radius: 8px;
	font-weight: 500;
	white-space: nowrap;
}

.active {
	@extend .productActivityStatusLabel;
	background-color: rgba(39, 174, 96, 0.12);
	color: #27ae60;
}

.inactive {
	@extend .productActivityStatusLabel;
	background-color: #ced0d2;
	color: utilities.$text-primary;
}
