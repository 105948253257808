@use "styles/utilities";

.wrapper {
	@include utilities.centralizedWithMaxWidth();
}

.total {
	border-radius: 4px;
	background-color: utilities.$global-card-background-secondary;
}

.period {
	background-color: utilities.$global-card-background-secondary;
	border-radius: 8px;
}

.cardsContainer {
	@include utilities.md-down {
		flex-direction: column;
	}
}

.costTypeCard {
	max-width: utilities.$maxWidthMediumCostTypeCard;
	@include utilities.md-down {
		max-width: 100%;
	}
}

.costTypeCardContainer {
	@include utilities.md-down {
		max-width: 100%;
	}
	flex: 2 1 0;
}

.infoBadge {
	max-width: 450px;
	flex: 1 3 0;
	@include utilities.md-down {
		max-width: 100%;
	}
}

.azureCostTable {
	@include utilities.xl-down {
		max-width: 100%;
	}
	@include utilities.sm-down {
		height: 300px;
	}
}
