@use "styles/utilities";

.removeCircleIcon .addCircleIcon {
	color: utilities.$text-accent;
}

.iconContainer {
	border: 0.4px solid utilities.$border-color;
	border-radius: 6px;
	width: 40px;
	height: 30px;
}

.skuQuantityNumber {
	height: 30px;
	width: 40px;
	font-size: 16px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.container {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: fit-content;
}

.quantityAdjustmentInputField {
	width: 75px;
	border-radius: 6px;
	font-size: 16px;
	margin: 0 8px;
}

.text {
	height: 8px;
	text-align: center;
}
