@use "styles/utilities";

.confirmationMailInputField {
	width: 50%;
}

.noLicensesAvailableContainer {
	display: flex;
	flex-direction: column;
	gap: utilities.$spacing2x;
	padding: utilities.$spacing3x;
}
