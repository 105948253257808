@use "styles/utilities";

$topSectionContainerHeight: 100px;

.secondaryText {
	text-overflow: "ellipsis";
	overflow: "hidden";
	white-space: "nowrap";
	max-width: "40ch";
	display: "block";
}

.tableHead {
	@include utilities.responsiveTableHeader();
	background-color: utilities.$table-head-primary !important;
	position: sticky;
	top: 0;
	z-index: 1;
}

.tableContainer {
	border: transparent;
	overflow: auto;
	@include utilities.responsiveTableContainer();
}

.table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0 utilities.$spacing2x;
}

.warningTextContainer {
	background-color: utilities.$warning-background;
	padding: utilities.$spacing3x;
	border-radius: 4px;
}

.summaryHeader {
	padding: utilities.$spacing1x;
	background-color: utilities.$greyTint2;
}

.container {
	gap: utilities.$spacing3x;
	display: flex;
	flex-direction: row;
}

.bottomSectionContainer {
	top: $topSectionContainerHeight * 3;
	left: utilities.$spacing3x;
	right: utilities.$spacing3x;
}

.label {
	padding: 6px 11px 6px 11px;
	border-radius: 8px;
	font-weight: 500;
	width: 180px;
	text-align: center;
}

.lastSignInLabel {
	@extend .label;
	background-color: rgba(39, 174, 96, 0.12);
	color: #27ae60;
}

.disabledLabel {
	@extend .label;
	background-color: utilities.$greyTint1;
	color: utilities.$text-primary;
	display: flex;
	flex-direction: row;
	gap: 4px;
	align-items: center;
	justify-content: center;
	.infoIcon {
		color: utilities.$text-primary;
	}
}

.deletedLabel {
	@extend .label;
	background-color: rgba(174, 174, 174, 0.16);
	color: utilities.$text-primary;
}

.relativeCell {
	position: relative;
}

.warningCell {
	opacity: 0.4;
	position: relative;
}

.warningText {
	color: rgb(102, 60, 0);
	font-weight: 500;
}

.warningLabel {
	padding: 4px 6px;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	gap: 4px;
	background-color: utilities.$yellowTint4;
	position: absolute;
	top: utilities.$spacing2x;
	right: 30px;
	@include utilities.md-down {
		right: 0;
		top: utilities.$spacing3x;
	}
}

.infoIcon {
	color: rgb(102, 60, 0);
	margin-top: 1px;
	font-size: 14px;
}

.deletedRow {
	opacity: 0.5;
}

.warningIcon {
	color: utilities.$yellowDark;
}

.warningHeader {
	display: flex;
	flex-direction: row;
	gap: utilities.$spacing1x;
}

.warningList {
	padding-left: 23px;
}

.noInactiveUsersCell {
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
	border: none;
}

.privelegedUserTextStyle {
	font-size: 12px;
	color: utilities.$text-secondary;
	font-weight: 500;
	display: inline !important;
}

.linkStyle {
	font-size: 12px;
	color: utilities.$link;
	cursor: pointer;
	display: inline !important;
}
