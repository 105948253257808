@use "styles/utilities";

.userInfoContainer {
	cursor: pointer;
	width: fit-content;
	&:hover {
		.link {
			text-decoration: underline;
		}
	}
}

.link {
	color: utilities.$link;
	display: block;
}
