@use "styles/utilities";

.tableContainer {
	border: transparent;
	@include utilities.responsiveTableContainer();
}

.tableHead {
	background-color: utilities.$table-header-background;
	@include utilities.responsiveTableHeader();
}

.table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 utilities.$spacing2x;
	background-color: utilities.$global-background-primary;
}

.cell {
	background-color: utilities.$global-card-background-primary;
}

.costCell {
	text-align: end;
}

.firstCellStyle {
	padding-left: utilities.$spacing3x;
	border-radius: 8px 0px 0px 8px;
	background-color: utilities.$global-card-background-primary;
}

.lastCellStyle {
	@extend .costCell;
	padding-right: utilities.$spacing3x;
	border-radius: 0px 8px 8px 0px;
	background-color: utilities.$global-card-background-primary;
}

.inactiveCell {
	background-color: #f5f5f5;
}

.inactiveFirstCell {
	@extend .inactiveCell;
}

.inactiveLastCell {
	@extend .inactiveCell;
}

.rowStyle {
	border-radius: 8px 0px 0px 8px;
	height: 7vh;
}

.loadingRowStyle {
	@extend .rowStyle;
	background-color: utilities.$global-card-background-primary;
}

.noItemsCell {
	background-color: utilities.$global-card-background-primary;
	padding: 30px;
	border-radius: 0 0 4px 4px;
}

.noItemsTable {
	border-spacing: 0 !important;
}
