@use "styles/utilities";

.hourRateLabel {
	padding: 6px 11px 6px 11px;
	border-radius: 8px;
	font-weight: 500;
	white-space: nowrap;
}

.normalRateLabel {
	@extend .hourRateLabel;
	background-color: rgba(39, 174, 96, 0.12);
	color: #27ae60;
}

.afterHours100Label {
	@extend .hourRateLabel;
	background-color: rgba(2, 122, 182, 0.16);
	color: #027ab6;
}

.afterHours150Label {
	@extend .hourRateLabel;
	background-color: rgba(254, 245, 230, 1);
	color: #faa61a;
}

.unknownRateLabel {
	@extend .hourRateLabel;
	background-color: rgba(174, 174, 174, 0.16);
	color: utilities.$text-primary;
}
