@use "styles/utilities";

.emailIcon {
	color: utilities.$text-accent;
}

.tableRow {
	// Align the text to the top of the cell
	td {
		vertical-align: top;
		padding: utilities.$spacing3x utilities.$spacing2x;
	}
}

.mutexLicense {
	color: utilities.$error;
	font-weight: 500;
}

.refreshIcon {
	color: utilities.$blueTint1;
}

.userDisplayName {
	cursor: pointer;
	color: utilities.$link;
	&:hover {
		text-decoration: underline;
	}
}

.checkIcon {
	color: utilities.$green;
}
