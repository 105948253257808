@use "styles/utilities";

.tableCell {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: utilities.$spacing3x utilities.$spacing3x 0 0;
	overflow-x: truncate;
	overflow: hidden;
	@include utilities.md-down {
		width: 50%;
		height: auto;
		padding: utilities.$spacing3x;
	}
}
