@use "styles/utilities";

.main {
	background-color: utilities.$global-card-background-primary;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.loginButton {
	@include utilities.md-down {
		width: fit-content;
	}
	@include utilities.md-up {
		width: 454px;
	}
	background-color: utilities.$primary-button-background-color;
	padding: 40px;
	text-transform: none;
	white-space: nowrap;
	border-radius: 8px;
	box-shadow: 0 4px 5px rgba(utilities.$accent, 0.09);
	margin-bottom: 50px;
	&:hover {
		background-color: utilities.$primary-button-background-color; //TODO: shade the color
	}
}

.loginButtonText {
	color: utilities.$text-primary;
}

.logoContainer {
	text-align: center;
}

.logo {
	@include utilities.md-up {
		width: auto;
	}
	height: 100px;
}

.buttonContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 150px;
}

.requestDemo {
	color: utilities.$link;
	font-size: 20px;
	text-decoration: underline;
	font-weight: 500;
	cursor: pointer;
}
