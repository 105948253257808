@use "styles/utilities";

.totalsCards {
	background-color: utilities.$global-card-background-secondary;
	border-radius: 4px;
}

.container {
	@include utilities.centralizedWithMaxWidth();
}
