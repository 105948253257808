@use "styles/utilities";

.addToBundleButton {
	min-width: 160px;
	max-height: 50px;
	white-space: nowrap;
	background-color: utilities.$blueDark !important;
	color: #fff;
	&:hover {
		color: #fff;
	}

	@include utilities.sm-down {
		padding: utilities.$spacing1x utilities.$spacing2x;
		min-width: fit-content;
		max-width: fit-content;
		width: fit-content;
		min-height: fit-content;
		max-height: fit-content;
	}
}
