@use "styles/utilities";

.clickable {
	cursor: pointer;
}

.cartContainer {
	margin-bottom: 160px;
	background-color: utilities.$global-card-background-primary;
	height: 100%;
	flex-wrap: nowrap;
}

.textInputContainer {
	padding: utilities.$spacing3x;
	flex-direction: column;

	margin-bottom: utilities.$spacing3x;
	.textInputs {
		border-radius: 6px;
		background-color: utilities.$global-card-background-primary;
		flex-direction: column;
		gap: utilities.$spacing3x;
	}
}

.bundleItemsContainer {
	direction: column;
	padding-bottom: 160px;
}

.footer {
	display: flex;
	padding: utilities.$spacing3x;
	justify-content: space-between;
	position: absolute;
	bottom: 0;
	right: 0;
	align-self: flex-end;
	background-color: rgba(234, 234, 234, 0.7);
	backdrop-filter: blur(5px);
	width: 100%;
	z-index: 100;
	height: 160px;

	@include utilities.sm-down {
		flex-direction: column;
		align-items: center;
		gap: utilities.$spacing1x;
	}
}

.quantityAndPriceContainer {
	justify-content: space-between;
}

.errorInput {
	color: utilities.$error;
	border: 1px solid utilities.$error;
}

.cartTotalText {
	font-weight: 500;
	color: white;
}

.deleteButton {
	background-color: transparent;
	&:hover {
		background-color: transparent;
	}
}

.deleteButtonContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
	flex-wrap: nowrap;
	svg {
		font-size: 16px;
		color: utilities.$error;
	}
	p {
		font-size: 14px;
		font-weight: 500;
		color: utilities.$error;
	}
	&:hover {
		cursor: pointer;
		text-decoration: underline;
		text-decoration-color: utilities.$error;
	}
}

.noItemsContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	margin-top: 80px;
	@include utilities.sm-down {
		margin-top: utilities.$spacing1x;
	}
}
