@use "sass:color";

@function change-alpha($level-color, $value) {
	@return color.change($level-color, $alpha: $value);
}

// Universal
$global-background-primary: #f5f7fb;
$global-background-secondary: $global-background-primary;

$global-card-background-primary: #fff;
$global-card-background-secondary: #f5f5f5;

// Black
$black: #000000;
$gray: #939393;

// Discount red
$discountRed: #c93b1f;
// Greys from design system
$greyTint1: #d5d5d5;
$greyTint2: #eaeaea;
$greyTint3: #727272;
$greyTint4: #fafafa;
$grey200: #bbbbbb;
$grey300: #b1b1b1;
$grey400: #a8a8a8;
$grey500: #9e9e9e;
$grey600: #8e8e8e;
$grey700: #7e7e7e;
$grey800: #6f6f6f;

// Browns from design system
$brownDark: #83796f;
$brownPrimary: #9b928b;
$brownTint1: #b6afa9;
$brownTint2: #dcd7d4;
$brownTint3: #e5e4e2;
$brownTint4: #f5f5f5;

// Blues from design system
$blueDark: #00608a;
$blueTint1: #027ab6;
$blueTint2: #268abb;
$blueTint3: #4d9bc3;
$blueTint4: #36ace4;
$bluePrimary: #41c2fa;
$blueTint6: #afe1fa;
$blueLicenseGroup: #cee2eb;

// Yellows from design system
$yellowDark: #faa61a;
$yellowPrimary: #febe10;
$yellowTint1: #fecc44;
$yellowTint2: #feca53;
$yellowTint3: #ffdd7c;
$yellowTint4: #ffebba;

$greenTint1: #45ba7885;
$green: #45ba78;

// Greens for labels
$greenLabelBackground: rgba(39, 174, 96, 0.12);
$greenLabelTextColor: #27ae60;

$primary: #243847;
$secondary: #b4c7e7;
$accent: #234863;

$text-primary: #333333;
$text-secondary: #ffffff;
$text-accent: #737373;

$link: #2d88f2;

$error: #d9534f;

$errorChip: #ffb7a5;
$error-background: change-alpha($error, 0.2);
$warning: #ffba38;
$warning-background: change-alpha($warning, 0.2);
$success: #17a956;
$success-background: change-alpha($success, 0.2);
$info: #427ab3;
$info-background: change-alpha($info, 0.2);

$awaitingReplyColor: change-alpha($error, 0.5);
$beingProcessedColor: change-alpha($info, 0.5);
$ticketResolvedColor: change-alpha($success, 0.5);

// Border colors
$border-color: #dfdfdf;
$border-color-heavy: #c7c7c7;
$border-color-light: #f0f0f0;

// Table css
$table-header-background: #e8e8e8;
$table-head-primary: #f3f3f3;

// Button colors
$primary-button-background-color: $yellowPrimary;
$primary-button-hover-background-color: #febe10;
$secondary-button-background-color: #ffffff;
$secondary-button-hover-background-color: #ffffff;
$delete-button-background-color: #df1642;
$delete-button-hover-background-color: #df1642;
$delete-button-text-color: #ffffff;

// Chart colors
$gridLinesColor: #ebebeb;

// Allows to export variables to be used in TS/JS files
:export {
	primary: $global-card-background-primary;
	secondary: $secondary;
	accent: $accent;
	error: $error;
	warning: $warning;
	warningLight: #faecc0;
	success: $success;
	info: $info;
	gray: $gray;
	borderColor: $border-color;
	borderColorHeavy: $border-color-heavy;
	borderColorLight: $border-color-light;
	tableHeadPrimary: $table-head-primary;
	tableHeadBackground: $table-header-background;
	textPrimary: $text-primary;
	textSecondary: $text-secondary;
	textAccent: $text-accent;
	azure: $blueDark;
	license: $bluePrimary;
	yourEmployees: $yellowTint1;
	consultancy: $yellowTint4;
	hardware: #808080;
	yourItSystems: $yellowDark;
	loading: $brownTint2;
	link: $link;
	yellowDark: $yellowDark;
	yellowPrimary: $yellowPrimary;
	yellowTint1: $yellowTint1;
	yellowTint2: $yellowTint2;
	yellowTint3: $yellowTint3;
	yellowTint4: $yellowTint4;
	brownTint1: $brownTint1;
	brownTint2: $brownTint2;
	brownTint3: $brownTint3;
	brownTint4: $brownTint4;
	blueDark: $blueDark;
	blueTint1: $blueTint1;
	blueTint2: $blueTint2;
	blueTint3: $blueTint3;
	blueTint4: $blueTint4;
	bluePrimary: $bluePrimary;
	blueTint6: $blueTint6;
	grey200: $grey200;
	grey300: $grey300;
	grey400: $grey400;
	grey500: $grey500;
	grey600: $grey600;
	grey700: $grey700;
	grey800: $grey800;
	discountRed: $discountRed;
}
