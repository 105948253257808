@use "styles/utilities";

.paper {
	background-color: utilities.$global-card-background-primary;
	border-radius: 8px;
	border: 1px solid utilities.$border-color-light;
}

.clickable {
	cursor: pointer;
	&:hover {
		box-shadow: 0px 4px 10px utilities.$border-color-heavy;
		transition-duration: 300ms;
	}
}

.topSectionContainer {
	padding: utilities.$spacing2x utilities.$spacing3x 0 utilities.$spacing3x;
}

.riskContainerWrapper {
	.riskContainer:not(:first-child):not(:last-child) {
		border-left: 1px solid #e2e2e2;
		border-right: 1px solid #e2e2e2;
	}
}

.riskContainer {
	position: relative;
	text-align: center;
}

.highRisk {
	border-top: 4px solid utilities.$error;
	color: utilities.$error;
	padding-top: utilities.$spacing3x;
}

.mediumRisk {
	border-top: 4px solid utilities.$warning;
	color: utilities.$warning;
	padding-top: utilities.$spacing3x;
}

.lowRisk {
	border-top: 4px solid utilities.$success;
	color: utilities.$success;
	padding-top: utilities.$spacing3x;
}

.notEvaluated {
	border-top: 4px solid utilities.$greyTint3;
	padding-top: utilities.$spacing3x;
}

.notEvaluatedBackground {
	background-color: utilities.$greyTint2;
}

.infoIcon {
	position: absolute;
	top: 10px;
	right: 11px;
	color: #0375a7;
	width: 19px;
	height: 19px;
}

.link {
	padding-bottom: utilities.$spacing2x;
}

.linkText {
	color: utilities.$link;
	font-weight: 500;
	text-decoration: none;
}

.tooltipText {
	font-family: "Barlow";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	/* or 125% */

	display: flex;
	align-items: center;
	letter-spacing: 0.0015em;

	color: utilities.$text-secondary;
}
