@use "variables";
@use "breakpoints";
@use "colors";

@mixin responsiveTableHeader() {
	@include breakpoints.lg-down {
		position: sticky;
		top: 0;
		width: 100%;
		z-index: 1;
	}
}

@mixin responsiveTableContainer(
	$maxHeightmdDown: variables.$sm-table-height,
	$maxHeightLgDown: variables.$lg-table-height,
	$overflow: auto
) {
	@include breakpoints.md-down {
		max-height: $maxHeightmdDown !important;
		max-width: calc(95vw);
	}

	@include breakpoints.lg-down {
		max-height: $maxHeightLgDown;
		overflow-y: $overflow;
		overflow-x: $overflow;
		& p {
			white-space: nowrap;
		}
	}

	@include breakpoints.sm-down {
		table {
			table-layout: auto;
		}
		& td,
		th {
			min-width: fit-content;
			width: 100%;
			word-wrap: normal;
		}
	}
}

@mixin fullScreenWidthStandardContenviewPadding($padding: variables.$spacing3x) {
	max-width: calc(100vw - 2 * $padding);
}

@mixin fullScreenFlexbasis($padding: variables.$spacing3x) {
	flex-basis: calc(100vw - 2 * $padding);
}

@mixin fullScreenFlex($padding: variables.$spacing3x) {
	@include fullScreenFlexbasis($padding);
	@include fullScreenWidthStandardContenviewPadding($padding);
}

@mixin standardMaxWidth($maxWidth: 1600px) {
	max-width: $maxWidth;
}

@mixin centralizedWithMaxWidth($maxWidth: 1600px) {
	@include standardMaxWidth($maxWidth);
	display: flex;
	justify-content: center;
}
