$spacing1x: 8px;
$spacing2x: 2 * $spacing1x;
$spacing3x: 3 * $spacing1x;
$spacing4x: 4 * $spacing1x;

$drawerWidth: 250px;
$contentMaxWidth: 1600px;
$maxWidthWithPadding: $contentMaxWidth - 2 * $spacing3x;

// Standard margins
$contentViewMarginBottom: 100px; // Typically used to lift page content above the absolute end of the page, to show the user that there is more content below

// cost dashboard colors
$azureColor: #a0e1fd;
$licenseColor: #ffeebe;
$yourEmployeesColor: #dcd7d4;

// The standard height of all our sections
$standardContentHeight: 92vh;

$lg-table-height: 60vh;
$sm-table-height: 500px;

// Insight card container height
$insight-card-height: 275px;
$insight-card-content-height: 60%;

$maxWidthMediumCostTypeCard: 552px;

$rightDrawerZIndex: 1202;
