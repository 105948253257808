@use "styles/utilities";

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.hardwarePageContainer {
	display: flex;
	max-width: utilities.$xl;
	transition: margin-right 0.15s ease-in-out;
}

.pushContentLeft {
	margin-right: 400px;
}
