@use "styles/utilities";

.inputRoot {
	padding: 2px 4px;
	display: flex;
	align-items: center;
	border: 1px solid utilities.$border-color;
	border-radius: 4px;
	background-color: utilities.$global-card-background-primary;
	width: 400px;
}

.iconButton {
	padding: 10px;
}

.input {
	flex: 1;
}

.loading {
	margin-right: utilities.$spacing2x;
}
